// Global site tag (gtag.js) - Google Analytics
export const loadGoogleAnalyticsScript = () => {
    if (process.env.REACT_APP_PRODUCTION === "true") {
        let script = document.createElement('script');
        script.src = "https://www.googletagmanager.com/gtag/js?id=UA-35415928-1";
        script.type = "text/javascript";
        script.async = true;
        document.getElementsByTagName("body")[0].appendChild(script);
      
        script = document.createElement('script');
        script.text = `window.dataLayer = window.dataLayer || []; 
                  function gtag(){dataLayer.push(arguments);} 
                  gtag('js', new Date()); 
                  gtag('config', 'UA-35415928-1');`
        script.type = "text/javascript";
        script.async = false;
        document.getElementsByTagName("body")[0].appendChild(script);
    }
}

export const loadGoogleCaptchaScript = () => {
    let script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_CAPTCHA_CLIENT_ACCESS_KEY}`;
    document.getElementsByTagName("body")[0].appendChild(script);
}