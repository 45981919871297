import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { getDeviceSettingsAsync, getEmergencyNumberAsync } from '../../../api/requestService';
import { getCurrentPositionAsync } from '../../../helpers/geoHelper';
import { storageHelper } from '../../../helpers/storageHelper';
import { routes } from '../../../routing/routeConsts';
import Image from '../../common/Image';

export default function TabBar() {
    const [emergencyNumber, setEmergencyNumber] = useState(storageHelper.getEmergencyNumber());
    const [favoriteLocation, setFavoriteLocation] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!emergencyNumber) {
            getCurrentPositionAsync(async (position) => {
                await getEmergencyNumberAsync(position);
                setEmergencyNumber(storageHelper.getEmergencyNumber());
            });
        }
        if (location.pathname === routes.home && !favoriteLocation) {
            const data = getDeviceSettingsAsync();
            if (data) {
                setFavoriteLocation({
                    code: data.FavoriteLocationCode,
                    icon: data.FavoriteLocationIcon
                })
            }
        }
    }, [])

    return (
        <div className="tab-bar__container">
            {location.pathname === routes.home && favoriteLocation?.icon && favoriteLocation?.code
                ? <Image
                    className="tab-bar__icon tab-bar__icon_favorite-location"
                    src={favoriteLocation.icon}
                    onClick={() => navigate(`${routes.report}/${favoriteLocation.code}`)}
                />
                : <NavLink
                    exact
                    to={routes.home}
                    activeClassName="tab-bar__icon_home_active"
                    className="tab-bar__icon tab-bar__icon_home"
                />}
            <NavLink
                to={routes.messages}
                activeClassName="tab-bar__icon_messages_active"
                className="tab-bar__icon tab-bar__icon_messages"
            />
            <NavLink
                to={routes.search}
                activeClassName="tab-bar__icon_search_active"
                className="tab-bar__icon tab-bar__icon_search"
            />
            {emergencyNumber &&
                <NavLink
                    to={routes.callEmergency}
                    className="tab-bar__icon_call_emergency"
                >
                    <span className="tab-bar__icon_call_emergency__first">Call</span>
                    <span className="tab-bar__icon_call_emergency__second">{emergencyNumber}</span>
                </NavLink>
            }
            <NavLink
                to={routes.options}
                activeClassName="tab-bar__icon_dots_active"
                className="tab-bar__icon tab-bar__icon_dots"
            />
        </div>
    )
}