import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import { storageHelper } from '../../../helpers/storageHelper'
import { routes } from '../../../routing/routeConsts';
import Header from '../../common/Header'
import TabBar from '../../common/TabBar'

export default function CallEmergencyPage() {
    const emergencyNumber = storageHelper.getEmergencyNumber();
    const navigate = useNavigate();

    useEffect(() => {
        if (!emergencyNumber) {
            navigate(routes.home);
        }
    }, [])

    return (emergencyNumber
        ? <>
            <Header showLanguage={true} useLogo={true} />

            <a className="call_emergency__container" href={`tel:${emergencyNumber}`}>
                <div className="image call_emergency__icon"></div>
                <div className=" call_emergency__text">{`Tap to call ${emergencyNumber}`}</div>
            </a>

            <TabBar />
        </>
        : null
    )
}