import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reportAnswers as reportAnswersSelector, updateReportAnswers } from '../../../../../reducers/reportSlice';
import { formatFileSize, getGpsDataAsync, getMediaContentType } from "../../../../../helpers/fileHelper";
import { v4 as uuidv4 } from 'uuid';
import { mediaContentTypes, maxVideoDuration, maxDocsFileSize, maxMediaFileSize } from '../../../../../consts';
import Modal from '../../../../common/modals/Modal';
import { storageHelper } from '../../../../../helpers/storageHelper';
import MediaWarningModal from '../../../../common/modals/MediaWarningModal';

export default function MediaControl() {
    const reportAnswers = useSelector(reportAnswersSelector);
    const files = reportAnswers?.files || [];
    const dispatch = useDispatch();
    const [uploadError, setUploadError] = useState();
    const [showWarning, setShowWarning] = useState();

    const toggleMediaInput = enable => {
        let mediaInput = document.getElementById("media-control__input");
        let mediaPlaceholder = document.getElementsByClassName("media-control__placeholder")[0];

        if (enable) {
            mediaInput.disabled = false;
            mediaPlaceholder.classList.remove("media-control__placeholder_disabled");
            return;
        }
        mediaInput.disabled = true;
        mediaPlaceholder.classList.add("media-control__placeholder_disabled");
    }

    const validateUpload = (uploadedArray) => {
        const filesCount = files.length + uploadedArray.length;

        const isSameTypeUploaded = uploadedArray.reduce(
            (result, current, index) => {
                current.mediaContentType = getMediaContentType(current.type)?.id;
                return result
                    && (index === 0 || current.mediaContentType === uploadedArray[index - 1].mediaContentType)
            }, true);
        if (!isSameTypeUploaded) {
            return false;
        }

        if (files.length > 0 && files[0].type !== uploadedArray[0].mediaContentType) {
            return false;
        }

        const uploadedType = getMediaContentType(uploadedArray[0].type);

        if (filesCount > uploadedType.maxCount) {
            return false;
        }

        return uploadedType;
    }

    const validateAttachmentsSize = (event, uploadedArray) => {
        let uploadedFilesSize = uploadedArray[0]?.size || 0;
        reportAnswers?.files?.forEach(element => {
            uploadedFilesSize += element.fileSize;
        });

        if ((uploadedArray[0]?.type?.includes("image") || uploadedArray[0]?.type?.includes("video") || uploadedArray[0]?.type?.includes("audio")) && uploadedFilesSize > maxMediaFileSize) {
            setUploadError(`File size cannot exceed ${formatFileSize(maxMediaFileSize)}`);
            event.target.value = "";
            return false;
        }
        else if (!(uploadedArray[0]?.type?.includes("image") || uploadedArray[0]?.type?.includes("video") || uploadedArray[0]?.type?.includes("audio")) && uploadedFilesSize > maxDocsFileSize) {
            setUploadError(`File size cannot exceed ${formatFileSize(maxDocsFileSize)}`);
            event.target.value = "";
            return false;
        }

        return true;
    }

    const onFileUploadAsync = async (event) => {
        storageHelper.setMediaUploadStatus();
        if (!event.target.files?.length) {
            return;
        }
        const uploadedArray = [...event.target.files];
        const uploadedType = validateUpload(uploadedArray);
        if (!uploadedType) {
            setUploadError("To the report can be attached: up to 4 photos or 1 video or 1 audio or 1 file(pdf, txt)");
            event.target.value = "";
            return;
        }

        if (validateAttachmentsSize(event, uploadedArray)) {
            const id = `file-${uuidv4()}`;
            const uploadedFiles = await Promise.all(uploadedArray.map(async (item) => ({
                id: id,
                type: getMediaContentType(item.type)?.id,
                mimeType: item.type,
                extension: item.name.split('.').pop(),
                formattedFileSize: formatFileSize(item.size),
                fileSize: item.size,
                src: URL.createObjectURL(item),
                gps: await getGpsDataAsync(item)
            })));
    
            dispatch(updateReportAnswers({ ...reportAnswers, files: [...(reportAnswers?.files || []), ...uploadedFiles] }))
            event.target.value = "";
    
            if (files?.length + uploadedFiles?.length >= uploadedType.maxCount) {
                toggleMediaInput(false);
            }
        }
    }

    const onRemoveFileClick = index => {
        dispatch(updateReportAnswers({ ...reportAnswers, files: [...(reportAnswers?.files || []).filter((item, i) => i !== index)] }));
        toggleMediaInput(true);
    }

    const simulateInputClick = () => {
        let mediaInput = document.getElementById("media-control__input");
        mediaInput.click();
        storageHelper.setMediaUploadStatus();
    }

    return (
        <div className="media-control">
            {showWarning &&
                <Modal
                    component={MediaWarningModal}
                    onNextClick={() => {
                        setShowWarning(false);
                        simulateInputClick();
                    }}
                    nextText="Continue"
                    onCancelClick={() => {
                        setShowWarning(false);
                    }}
                    cancelText="Cancel"
                />
            }
            {uploadError &&
                <Modal
                    text={uploadError}
                    onCancelClick={() => setUploadError(null)}
                />
            }
            <div className="preview">
                {files.map((file, index) => (
                    <div className="preview__item" key={file.id}>
                        {file.type === mediaContentTypes.photo.id
                            ? <img className="preview__thumbnail" src={file.src} alt="attachment" />
                            : (file.type === mediaContentTypes.video.id
                                ? <video
                                    id={file.id}
                                    className="preview__thumbnail"
                                    src={file.src}
                                    onLoadedMetadata={(e) => {
                                        if (e.target.duration !== Infinity && Math.floor(e.target.duration) > maxVideoDuration) {
                                            setUploadError(`Maximum video duration is ${maxVideoDuration} seconds. Please trim the video before upload`);
                                            onRemoveFileClick(index);
                                        }
                                    }}
                                />
                                : <div className="preview__thumbnail preview__thumbnail_default" />)
                        }
                        <div className="preview__info text_default_gray">{file.extension}, {file.formattedFileSize}</div>
                        <div className="preview__icon_remove" onClick={() => onRemoveFileClick(index)} />
                    </div>
                ))}
            </div>
            <input
                id="media-control__input"
                type="file"
                onChange={event => onFileUploadAsync(event)}
                accept={`${mediaContentTypes.photo.accept},${mediaContentTypes.video.accept},${mediaContentTypes.audio.accept},${mediaContentTypes.docs.accept}`}
            />
            <div
                className="media-control__placeholder text_default"
                onClick={() => setShowWarning(true)}
            >
                <div className="media-control__placeholder__icon" />
                <div className="media-control__placeholder__text">
                    <span className="text_default">
                        {(files?.length > 0 && files[0].type === mediaContentTypes.photo.id)
                            ? "Add up to 4 pictures "
                            : "Add photo / video or file "}
                    </span>
                    <span className="text_default_gray">(optional)</span>
                </div>
            </div>
        </div >
    )
}