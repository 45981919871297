import React, { useEffect, useState } from 'react'
import { hoursArray, minutesArray } from '../../../../../consts';
import Picker from '../Picker';

export default function TimePicker({ dateTime, onClose, currentDateTime, isCurrentDateSelected }) {
    const maxVisibleCount = 5;
    const [isBeforeNoon, setIsBeforeNoon] = useState(dateTime.time.isBeforeNoon);
    const [selectedHour, setSelectedHour] = useState(hoursArray.find(x => x.value === (currentDateTime.time.hour === 0 ? 12 : currentDateTime.time.hour)));
    const [selectedMinute, setSelectedMinute] = useState(minutesArray.find(x => x.value === dateTime.time.minute));

    const getValidHours = () => {
        let currentTimeHour = currentDateTime.time.hour === 0 ? 12 : currentDateTime.time.hour;
        let currentTimeHourIndex = hoursArray.find(x => x.value === currentTimeHour).index;
        return hoursArray.map(item => ({ ...item, isDisabled: !(!currentDateTime.time.isBeforeNoon && isBeforeNoon) && item.index > currentTimeHourIndex }))
    }
    const getValidMinutes = () => {
        let currentTimeHour = currentDateTime.time.hour === 0 ? 12 : currentDateTime.time.hour;
        return minutesArray.map(item => ({
            ...item,
            isDisabled: !(!currentDateTime.time.isBeforeNoon && isBeforeNoon) && selectedHour.value === currentTimeHour && item.value > currentDateTime.time.minute
        }))
    }
    const [hours, setHours] = useState(isCurrentDateSelected() ? getValidHours() : hoursArray);
    const [minutes, setMinutes] = useState(isCurrentDateSelected() ? getValidMinutes() : minutesArray);

    const getClassName = isBeforeTruthful => {
        return isCurrentDateSelected()
            && currentDateTime.time.isBeforeNoon
            && currentDateTime.time.isBeforeNoon !== isBeforeTruthful
            ? "hour-format item_disabled"
            : (isBeforeTruthful === isBeforeNoon ? "hour-format_selected" : "hour-format")
    }

    useEffect(() => {
        setMinutes(isCurrentDateSelected() ? getValidMinutes() : minutesArray);
        setHours(isCurrentDateSelected() ? getValidHours() : hoursArray);
    }, [isBeforeNoon])

    return (
        <div className="backdrop">
            <div className="time-picker__container">
                <div className="time__container">
                    <Picker
                        getId={index => `hour${index}`}
                        selectedAnswer={selectedHour}
                        setSelectedAnswer={answer => {
                            setSelectedHour(answer);
                            if (isCurrentDateSelected()) {
                                setMinutes(answer.value === currentDateTime.time.hour ? getValidMinutes() : minutesArray)
                            }
                        }}
                        data={hours}
                        maxVisibleCount={maxVisibleCount}
                        isMiddleBased={true}
                    />
                    <div className="semicolon">:</div>
                    <Picker
                        getId={index => `minute${index}`}
                        selectedAnswer={selectedMinute}
                        setSelectedAnswer={value => setSelectedMinute(value)}
                        data={minutes}
                        maxVisibleCount={maxVisibleCount}
                        isMiddleBased={true}
                    />
                    <div className="list format" >
                        <div
                            className={getClassName(true)}
                            onClick={() => setIsBeforeNoon(true)}
                        >AM</div>
                        <div className="divider"></div>
                        <div
                            className={getClassName(false)}
                            onClick={() => !isCurrentDateSelected() || !currentDateTime.time.isBeforeNoon ? setIsBeforeNoon(false) : null}
                        >PM</div>
                    </div>
                </div >
                <div className="picker__button__container time-picker__button__container">
                    <div
                        onClick={() =>  onClose && onClose() }
                        className="picker__button text_default_gray">Cancel</div>
                    <div
                        onClick={() => {
                            onClose && onClose({
                                hour: selectedHour.value,
                                minute: selectedMinute.value,
                                isBeforeNoon
                            })
                        }}
                        className="picker__button text_default_gray">Okay</div>
                </div>
            </div >
        </div >
    );
}
