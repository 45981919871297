import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export default function PhoneInputWithCountryCode({key, className, country, enableSearch, placeholder, value, onChange, isValid}) {

  return (
    <PhoneInput
      key={key}
      className={className + " phone-input-with-country-code"}
      country={country}
      enableSearch={enableSearch}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      isValid={isValid}
    />
  );
}
