import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { reportAnswers as reportAnswersSelector, updateReportAnswers } from '../../../../../reducers/reportSlice';
import ToggleSwitch from '../../../../common/ToggleSwitch';
import TimePicker from '../../pickers/TimePicker';
import DatePicker from '../../pickers/DatePicker';
import { getFormatedDate, getFormatedTime } from '../../../../../helpers/dateTimeHelper';

export default function DateTimeControl({ shouldStateBeUpdated, isShortFormSelected }) {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showTimePicker, setShowTimePicker] = useState(false);
    const reportAnswers = useSelector(reportAnswersSelector);
    const today = new Date();
    const currentDateTime = {
        date: {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate()
        },
        time: {
            hour: today.getHours() > 12 ? today.getHours() - 12 : today.getHours(),
            minute: today.getMinutes(),
            isBeforeNoon: today.getHours() < 12
        },

    };
    const [dateTime, setDateTime] = useState(reportAnswers?.dateTime || {
        ...currentDateTime,
        isIncluded: true
    });
    const dispatch = useDispatch();

    const isCurrentDateSelected = () => dateTime.date.year === currentDateTime.date.year
        && dateTime.date.month === currentDateTime.date.month
        && dateTime.date.day === currentDateTime.date.day;

    useEffect(() => {
        if (shouldStateBeUpdated) {
            dispatch(updateReportAnswers({ ...reportAnswers, dateTime }));
        }
    }, [shouldStateBeUpdated])

    return (
        <div className="date-time-control" style={isShortFormSelected ? {display: 'none'} : {}}>
            <div className="section__header">Date & time of incident (optional)</div>
            <div className="toggle-switch__section">
                <ToggleSwitch
                    isChecked={dateTime?.isIncluded}
                    onChange={value => setDateTime({ ...dateTime, isIncluded: value })}
                />
                <div className="text_primary">Date and Time of Incident</div>
            </div>
            {dateTime?.isIncluded &&
                <>
                    <div className="input__container" onClick={() => setShowDatePicker(!showDatePicker)}>
                        <div className="text_default">{getFormatedDate(dateTime.date.year, dateTime.date.month, dateTime.date.day)}</div>
                        <div className="date-time-control__icon_date" />
                    </div>
                    <div className="input__container" onClick={() => setShowTimePicker(!showTimePicker)}>
                        <div className="text_default">{getFormatedTime(dateTime.time.hour, dateTime.time.minute, dateTime.time.isBeforeNoon)}</div>
                        <div className="date-time-control__icon_time" />
                    </div>

                    {showDatePicker &&
                        <DatePicker
                            date={dateTime.date}
                            currentDateTime={currentDateTime}
                            onClose={value => {
                                if (value != null) {
                                    setDateTime({ ...dateTime, date: value });
                                }
                                setShowDatePicker(false)
                            }}
                        />}
                    {showTimePicker &&
                        <TimePicker
                            dateTime={dateTime}
                            currentDateTime={currentDateTime}
                            isCurrentDateSelected={isCurrentDateSelected}
                            onClose={value => {
                                if (value != null) {
                                    setDateTime({ ...dateTime, time: value });
                                }
                                setShowTimePicker(false)
                            }}
                        />}
                </>}
        </div>
    )
}