import React from "react";
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../routing/routeConsts';

export default function StepsNavigation({ step, max, onStepChange, isPanicAlarmEnabled, isShortFormSelected, isValid }) {
    const navigate = useNavigate();
    return (
        <div className="report-navigation">
            {isPanicAlarmEnabled &&
                <button
                    className="text_default button panic-alarm background_red"
                    onClick={() => onStepChange(true, true)}>
                    Send panic alarm
                </button>
            }
            <div className="report-navigation__menu" id="report-navigation__menu">
                {isShortFormSelected 
                        ? (<button
                            className={`text_default button_bordered notranslate short-form_cancel-button`}
                            onClick={() => navigate(routes.home)}>
                            Cancel
                        </button>)
                        : (<button
                            className={`text_default button_bordered notranslate ${step.number > 1 ? "" : "button_disabled"}`}
                            onClick={() => onStepChange(false)}>
                            Back
                        </button>)
                }

                {isShortFormSelected 
                    ? (<button
                        className={`text_default button notranslate short-form_submit-button ${isValid ? "" :"short-form_submit-button-disabled"}`}
                        onClick={() => onStepChange(true, false)}>
                        Submit Report
                    </button>)
                    : (<button
                        className={`text_default button notranslate ${step.number === max && isPanicAlarmEnabled ? "button_disabled" : ""}`}
                        onClick={() => onStepChange(true)}
                        disabled={step.number === max && isPanicAlarmEnabled}>
                        {step.number === max ? "Submit Report" : "Next"}
                    </button>)
                }
            </div>
        </div>
    )
}