import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { report as reportSelector, fetchReport, clearState, fetchGooglePlaceDetails } from "../../../reducers/reportSlice"
import Header from '../../common/Header';
import LocationInfo from './LocationInfo';
import MapComponent from '../../common/MapComponent';
import NonReportingLocation from './NonReportingLocation';
import Report from './Report';
import ShortReport from './ShortReport';
import blueMarker from "../../../static/images/markers/small-blue.svg"
import greenMarker from "../../../static/images/markers/small-green.svg"
import { getCurrentPositionAsync, getFormatedAddress } from "../../../helpers/geoHelper"
import { formTypes, languages } from '../../../consts';
import { changeLanguage } from '../../../helpers/languageHelper';
import { changeLoadingStatus, setError } from '../../../reducers/loadingSlice';
import { requestStatus } from '../../../api/apiConsts';
import Modal from '../../common/modals/Modal';
import { goToPreviousLocation, routes } from '../../../routing/routeConsts';
import Include911Popup from '../../common/modals/Include911Popup';
import TabBar from '../../common/TabBar';
import { resetRecognition } from '../../../helpers/voiceInputHelper';
import { storageHelper } from '../../../helpers/storageHelper';

export default function ReportPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { locationCode, language, googlePlaceId } = useParams();
    const report = useSelector(state => state.report.report);
    const fetchStatus = useSelector(state => state.report.status);
    const [steps, setSteps] = useState([]);
    const [currentUserPosition, setCurrentUserPosition] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [showLocationOnMap, setShowLocationOnMap] = useState(false);
    const [locationAddress, setLocationAddress] = useState(null);
    const [wasPopupModalClosed, setWasPopupModalClosed] = useState(false);
    const [was911ModalClosed, setWas911ModalClosed] = useState(false);
    const [mapOffsetTop, setMapOffsetTop] = useState();

    const goBackFromReport = () => {
        goToPreviousLocation(navigate, location, location?.state?.referrer?.includes(routes.category), routes.search);
    }

    const applyRedirectLogic = () => {
        if (was911ModalClosed && wasPopupModalClosed) {
            dispatch(changeLoadingStatus(true));
            window.location = report.RedirectUrl;
        }
    }

    useEffect(() => {
        if (language) {
            const reportLanguage = languages.find(x => x.name.toLowerCase() === language.toLowerCase());
            changeLanguage(reportLanguage?.code);
        }

        return () => {
            dispatch(clearState());
            resetRecognition();

            if (storageHelper.getTranslation()) {
                changeLanguage(languages[0]?.code);
                storageHelper.removeTranslation();
            }
        }
    }, [])

    useEffect(() => {
        if (locationCode) {
            getCurrentPositionAsync(position => {
                setCurrentUserPosition(position.coords);
                dispatch(fetchReport({
                    coords: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    },
                    code: locationCode
                }))
            }, () => dispatch(fetchReport({
                code: locationCode
            }))
            )
        }
    }, [locationCode])

    useEffect(() => {
        if (googlePlaceId) {
            dispatch(fetchGooglePlaceDetails(googlePlaceId));
        }
    }, [googlePlaceId])

    useEffect(() => {
        dispatch(changeLoadingStatus(fetchStatus === requestStatus.loading));
    }, [report, fetchStatus])

    useEffect(() => {
        if (report) {
            if (report.IsFenceLockEnabled && !report.UserInGeoFence) {
                dispatch(setError("You must be at this location to create a new report."));
                setWas911ModalClosed(true);
                setWasPopupModalClosed(true);
            } else {
                getFormatedAddress(report.Latitude, report.Longitude, address => setLocationAddress(address));
                setWas911ModalClosed(!report.Include911InPopup);
                setWasPopupModalClosed(!!!report.PopupMessageText);
            }
        }
    }, [report])

    useEffect(() => {
        if (showLocationOnMap) {
            setMapOffsetTop(document.getElementById("location-info")?.clientHeight);
        }
    }, [showLocationOnMap])

    useEffect(() => {
        if (!currentUserPosition?.name) {
            getFormatedAddress(currentUserPosition?.latitude, currentUserPosition?.longitude, address => setCurrentUserPosition(address));
        }
    }, [currentUserPosition])

    return report && (
        <>
            {!was911ModalClosed &&
                <Modal
                    component={Include911Popup}
                    onCancelClick={() => goBackFromReport()}
                    onStartClick={() => setWas911ModalClosed(true)}
                />
            }

            {was911ModalClosed && !wasPopupModalClosed &&
                <Modal
                    htmlMarkup={report.PopupMessageText}
                    onCancelClick={() => goBackFromReport()}
                    onNextClick={() => setWasPopupModalClosed(true)}
                />
            }

            <div className="report__container">
                <Header
                    showCancelText={!showLocationOnMap}
                    showBackArrow={showLocationOnMap}
                    currentStep={!showLocationOnMap && !report.WebContactInfo ? currentStep : null}
                    max={!showLocationOnMap ? steps.length : null}
                    onCancel={showLocationOnMap ? () => setShowLocationOnMap(false) : () => goBackFromReport()}
                    showLanguage={true}
                    isLogoCentered={true}
                    useLogo={true}
                    useLightTheme={true}
                    isShortFormSelected={report?.Form?.FormType === formTypes.Short}
                />
                <div className="container">
                    <LocationInfo
                        location={report}
                        showMapLink={!showLocationOnMap}
                        onMapLinkClick={() => setShowLocationOnMap(true)}
                        locationAddress={locationAddress}
                    />

                    {showLocationOnMap
                        ? <MapComponent
                            mapContainerClassName="report__map"
                            markersList={[{
                                position: {
                                    lat: report.Latitude,
                                    lng: report.Longitude
                                },
                                icon: report.IsNonReportingLocation ? greenMarker : blueMarker,
                                key: report.Code
                            }]}
                            mapOffsetTop={mapOffsetTop}
                        />
                        : (report.WebContactInfo
                            ?
                            <>
                                <NonReportingLocation location={report} />
                                <TabBar />
                            </>
                            : (!report.Form && report.RedirectUrl
                                ? applyRedirectLogic()
                                : report?.Form?.FormType === formTypes.Short 
                                    ? <ShortReport
                                        report={report}
                                        currentStep={currentStep}
                                        steps={steps}
                                        currentUserPosition={currentUserPosition}
                                        onStepsChange={value => setSteps(value)}
                                        onCurrentStepChange={value => setCurrentStep(value)}
                                    />
                                    : <Report
                                        report={report}
                                        currentStep={currentStep}
                                        steps={steps}
                                        currentUserPosition={currentUserPosition}
                                        onStepsChange={value => setSteps(value)}
                                        onCurrentStepChange={value => setCurrentStep(value)}
                                    />)
                        )}
                </div >
            </div>
        </>)
}