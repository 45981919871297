import React, { useState } from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api';
import currentPositionMarker from "../../../static/images/markers/current.svg"
import { mapOptions } from '../../../consts';
import { v4 } from 'uuid';
import { getCurrentPositionAsync } from '../../../helpers/geoHelper';

export default function MapComponent({
    markersList,
    mapContainerClassName,
    currentLocation,
    nextSiblingTop,
    mapOffsetTop
}) {
    const getCurrentLocationMarker = position => ({
        position: { lat: position.coords.latitude, lng: position.coords.longitude },
        icon: currentPositionMarker,
        key: v4()
    });
    const [googleMap, setGoogleMap] = useState(null);
    const [markers, setMarkers] = useState(currentLocation ? [getCurrentLocationMarker(currentLocation), ...markersList] : markersList);

    const fitBounds = (map, coords) => {
        if (!coords || !coords.length) {
            return;
        }

        if (coords.length === 1
            || (coords.length === 2 && coords[0].position.lat === coords[1].position.lat && coords[0].position.lng === coords[1].position.lng)) {
            map.setCenter(new window.google.maps.LatLng(coords[0].position.lat, coords[0].position.lng));
            return;
        }

        const bounds = new window.google.maps.LatLngBounds();

        coords.forEach(marker => {
            bounds.extend(new window.google.maps.LatLng(marker.position.lat, marker.position.lng));
        });

        map.fitBounds(bounds);
        map.panToBounds(bounds);
    };

    const onMapLoad = map => {
        setGoogleMap(map);
        fitBounds(map, markers);
    }

    const onCurrentLocationClickAsync = async () => {
        await getCurrentPositionAsync(position => {
            if (markers.some(x => x.position.lat === position.coords.latitude && x.position.lng === position.coords.longitude)) {
                fitBounds(googleMap, markers);
                return;
            }

            const newMarkers = [...markers.filter(x => x.icon !== currentPositionMarker), getCurrentLocationMarker(position)];

            setMarkers(newMarkers);
            fitBounds(googleMap, newMarkers);
        })
    }

    return (
        <GoogleMap
            onLoad={onMapLoad}
            mapContainerClassName={mapContainerClassName || "map__container"}
            options={mapOptions}
            zoom={10}
            mapContainerStyle={mapOffsetTop ? { top: mapOffsetTop + 100 } : null}
        >
            <>
                {markers && markers.map(item => (
                    <Marker
                        key={item.key}
                        position={item.position}
                        icon={item.icon}
                        onClick={() => item.onClick && item.onClick()}
                    />
                ))}
                <div
                    className="current-location__button"
                    onClick={() => onCurrentLocationClickAsync()}
                    style={nextSiblingTop ? { top: nextSiblingTop - 50 } : null}
                />
            </>
        </GoogleMap >
    )
}