import React, { useEffect, useState } from 'react';

export default function Picker({
    maxVisibleCount,
    getId,
    selectedAnswer,
    setSelectedAnswer,
    data,
    isMiddleBased
}) {
    const uniqueItemsCount = data.length;
    const isScrollable = uniqueItemsCount > maxVisibleCount;
    const itemHeight = 30;
    const items = isScrollable
        ? [
            ...data,
            { value: data[0].value, text: "", index: uniqueItemsCount + 1 }
        ]
        : data;
    const [wasScrolled, setWasScrolled] = useState(false);

    const getClassName = (key, isDisabled) => {
        if (isDisabled) {
            return "picker__item item_disabled"
        }
        const className = key === selectedAnswer.index
            ? "picker__item_selected"
            : (key + 1 === selectedAnswer.index || key - 1 === selectedAnswer.index || (key % uniqueItemsCount) + 1 === selectedAnswer.index
                ? "picker__item_neighbour"
                : "picker__item");
        if (isMiddleBased) {
            return className + " theme_dark";
        }
        return className;
    }

    useEffect(() => {
        setWasScrolled(false);
        if (!isScrollable) {
            return;
        }

        const element = document.getElementById(getId(isMiddleBased ? selectedAnswer.index - 2 : selectedAnswer.index - 1));
        if (element) {
            element.scrollIntoView();
        }
    }, [selectedAnswer, wasScrolled])

    useEffect(() => {
        const parentWidth = document.getElementById("picker").clientWidth;
        items.forEach(item => {
            const domElement = document.getElementById(getId(item.index));
            const difference = domElement.clientWidth / parentWidth;
            if (difference > 2) {
                domElement.style.fontSize = '12px';
                domElement.style.width = parentWidth + 'px';
                domElement.style.overflow = 'hidden';
                domElement.style.textOverflow = 'ellipsis';
                return;
            }
            if (difference > 1) {
                domElement.style.fontSize = (24 / difference) + 'px';
            }
        });
    }, [])

    return (
        <div
            style={{ height: `${itemHeight * maxVisibleCount}px` }}
            className={!isScrollable ? "picker__items_no-scroll" : "picker__items"}
            id="picker"
        >
            {items.map(item =>
            (
                <div
                    key={getId(item.index)}
                    id={getId(item.index)}
                    className={getClassName(item.index, item.isDisabled)}
                    onClick={() => item.isDisabled ? null : setSelectedAnswer(data.find(x => x.value === item.value))}
                >
                    {item.text}
                </div>))
            }
        </div >
    )
}