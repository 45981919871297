import React from 'react'

export default function TermsOfUseText() {

    return (
        <div className="container terms__container">
            <div className="text_primary">
                By using the <span className="text_bold">report it®</span> mobile application you understand and agree to our <a className='link-inline' href="https://reportit.com/terms" target="_blank" rel='noreferrer'>Terms of Use</a>.
                </div>
            <div className="text_primary">
                <span className="text_bold">report it®</span> is completely anonymous and we do not collect any information about your identity or device. You may choose to provide contact information when you submit a report.
                </div>
            <div className="text_primary">
                The information in this transmittal is being forwarded in the strictest confidence, meaning that <span className="text_bold">report it®</span> will not voluntarily disclose any information under any circumstances without your consent. <span className="text_bold">report it®</span> is forwarding the information solely as a service for the receiving party.
                </div>
            <div className="text_primary">
                You agree that <span className="text_bold">report it®</span> will not be liable for any act or failure to act by the receiving party. You also agree and understand that report it has no responsibility or authority to take any action based upon the information provided by you.
                </div>
        </div>
    )
}