import axios, { CancelToken } from 'axios';
import { authHelper } from "./authHelper";
import { 
    httpMethod, 
    getTokenUrl, 
    createUserUrl, 
    getEmergencyNumberUrl, 
    getSettingsUrl, 
    handleUserGoogleCaptchaTokenUrl, 
    getVideoChatTokenUrl,
    changeRoomRecordingStatusUrl,
    completeRoomUrl,
    updateCurrentUserPositionUrl
} from "./apiConsts";
import { setError } from "../reducers/loadingSlice";
import store from '../store';
import { storageHelper } from '../helpers/storageHelper';

axios.interceptors.request.use(
    async config => {
        let source = CancelToken.source();
        setTimeout(() => {
            source.cancel();
        }, 180000); //180 sec
        config.cancelToken = source.token;
        if (config.withoutToken) {
            return config;
        }

        let token = authHelper.getUserToken();
        let now = Date.now();
        if (!token || now - token.received > token.expires_in * 1000) {
            await updateTokenAsync();
            token = authHelper.getUserToken()
        }
        config.headers = { 'AppVersion': 1.7, 'Authorization': `Bearer ${token.access_token}` };

        if (config.data instanceof FormData) {
            config.headers = { ...config.headers, "Content-Type": "multipart/form-data" }
        }
        return config;
    },
    error => error
);

axios.interceptors.response.use(
    response => response,
    async error => {
        if (axios.isCancel(error)) {
            store.dispatch(setError("The server takes a long time to respond. Please try again later"));
            return;
        }
        if (error.response?.status === 401) {
            await updateTokenAsync();
            return await axios(error.config);
        }

        const errorMessage = error?.response?.data?.ExceptionMessage
            ? "Something went wrong. Please try again later"
            : error?.response?.data?.Message;
        
        error?.config?.onError ? error?.config.onError(errorMessage) : store.dispatch(setError(errorMessage));
    }
);

export const updateTokenAsync = async (position) => {
    let deviceId = authHelper.getDeviceId();
    if (!deviceId) {
        deviceId = await createUserAsync(position);
    }

    const params = new URLSearchParams();
    params.append('grant_type', 'device_id');
    params.append('deviceId', deviceId);

    const tokenResponse = await axios({
        method: httpMethod.post,
        url: getTokenUrl,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: params,
        withoutToken: true
    });

    authHelper.setUserToken({ ...tokenResponse.data, received: Date.now() });
}

export const updateVideoAccessTokenAsync = async (invitationToken) => {
    let deviceId = authHelper.getDeviceId();
    if (!deviceId) {
        deviceId = await createUserAsync();
    }

    await axios({
        method: httpMethod.get,
        url: `${getVideoChatTokenUrl}?invitationToken=${invitationToken}&deviceId=${deviceId}`
    }).then(tokenResponse => {
        authHelper.setUserVideoChatToken({ token: tokenResponse?.data, received: Date.now() });
    });
}

export const changeRoomRecordingStatusAsync = async (roomSid, enableRecording, enableRecordingByLocationSettings = false) => {
    await axios({
        method: httpMethod.get,
        url: `${changeRoomRecordingStatusUrl}?roomSid=${roomSid}&enableRecording=${enableRecording}&enableRecordingByLocationSettings=${enableRecordingByLocationSettings}`
    });
}

export const completeRoomAsync = async (roomSid) => {
    await axios({
        method: httpMethod.get,
        url: `${completeRoomUrl}?roomSid=${roomSid}`
    });
}

export const updateCurrentUserPositionAsync = async (roomSid, currentUserPosition) => {
    await axios({
        method: httpMethod.get,
        url: `${updateCurrentUserPositionUrl}?roomSid=${roomSid}&latitude=${currentUserPosition?.latitude}&longitude=${currentUserPosition?.longitude}`
    });
}

export const createUserAsync = async (position) => {
    if (storageHelper.getEmergencyNumber()) {
        position = null;
    }

    const response = await axios({
        method: httpMethod.post,
        url: createUserUrl,
        data: {
            deviceType: 2,
            deviceId: authHelper.getDeviceId(),
            latitude: position?.coords?.latitude,
            longitude: position?.coords?.longitude
        },
        withoutToken: true
    });
    authHelper.setDeviceId(response.data.deviceId);

    if (response.data.emergencyNumber) {
        storageHelper.setEmergencyNumber(response.data.emergencyNumber);
    }

    return response.data.deviceId;
}

export const handleUserCaptchaTokenAsync = async (token, locationCode) => {
    const response = await axios({
        method: httpMethod.get,
        url: `${handleUserGoogleCaptchaTokenUrl}?token=${token}&locationCode=${locationCode}`
    });
    
    return response?.data;
}

export const getEmergencyNumberAsync = async (position) => {
    if (!position?.coords || storageHelper.getEmergencyNumber()) {
        return;
    }

    const response = await axios({
        method: httpMethod.get,
        url: `${getEmergencyNumberUrl}?latitude=${position?.coords?.latitude}&longitude=${position?.coords?.longitude}`,
        withoutToken: true
    });

    if (response.data) {
        storageHelper.setEmergencyNumber(response.data);
    }
}

export const getDeviceSettingsAsync = async (rapidSOSUserId = null) => {
    const deviceId = authHelper.getDeviceId();
    if (!deviceId) {
        return null;
    }
    const response = await sendRequestAsync(httpMethod.get, `${getSettingsUrl}?deviceId=${authHelper.getDeviceId()}&rapidSOSUserId=${rapidSOSUserId}`);
    return response?.data;
}

export const sendRequestAsync = async (method, url, data, onError) => await axios({ method, url, data, onError });