import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendRequestAsync } from "../api/requestService";
import { httpMethod, requestStatus, getMessageListUrl, submitNewAnswerUrl, getAllUserReportsByHumanIdUrl, markAnswerAsReadUrl, changeReportStatusUrl } from "../api/apiConsts";
import { getFormatedDateTimeString } from '../helpers/dateTimeHelper';
import { setErrorWithoutRedirect } from "../reducers/loadingSlice";
import store from '../store';
import { authHelper } from '../api/authHelper';
import { answerSource } from '../consts';

export const changeReportStatus = createAsyncThunk("message/changeReportStatus", async (reportId) => {
    const response = await sendRequestAsync(httpMethod.post, changeReportStatusUrl, { 
        reportIds: [reportId],
        shouldBeDeleted: false
     });
    return response.data;
})

export const fetchUserReportsByHumanId = createAsyncThunk("message/fetchUserReportsByHumanId", async (humanId) => {
    const response = await sendRequestAsync(httpMethod.get, `${getAllUserReportsByHumanIdUrl}?humanId=${humanId}&orderDesc=true`);
    return response.data;
})

export const fetchMessagesList = createAsyncThunk("message/fetchMessagesList", async ({ pin, number }) => {
    let url = `${getMessageListUrl}?id=${number}&pin=${pin}&orderAsc=true`;
    if (!pin && (authHelper.getUserHumanId() || authHelper.getUserVideoChatToken())) {
        url = `${getMessageListUrl}?id=${number}&pin=${pin}&orderAsc=true&withoutPin=true`;
    }
    const response = await sendRequestAsync(httpMethod.get, url);
    await sendRequestAsync(httpMethod.post, markAnswerAsReadUrl, { reportId: response?.data?.ReportId });

    return response.data;
})

export const submitAnswer = createAsyncThunk("message/submitAnswer", async ({ answerText }, { getState }) => {
    const { message } = getState();
    const data = {
        allowAnswerFromClient: message.chat.AllowAnswerFromClient,
        answerText: answerText,
        reportId: message?.chat?.ReportId,
        answerSource: answerSource.client,
        orderAsc: true
    }
    const response = await sendRequestAsync(httpMethod.post, submitNewAnswerUrl, data, (errorMessage) => {
        store.dispatch(setErrorWithoutRedirect(errorMessage))
    });
    return response.data;
},
    {
        condition: (args, { getState }) => {
            const { message } = getState();
            return message?.chat?.ReportId;
        }
    })

export const messageSlice = createSlice({
    name: "message",
    initialState: {
        chat: null,
        credentials: null,
        status: requestStatus.idle
    },
    reducers: {
        setCredentials: (state, action) => {
            state.credentials = action.payload;
        },
        clearState: (state, action) => {
            state.chat = null;
            state.credentials = null;
            state.status = requestStatus.idle;
        },
        addMessage: (state, action) => {
            state.chat.Answers.push({ ...action.payload, AnsweredAt: getFormatedDateTimeString(new Date(action.payload.AnsweredAt)) })
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchUserReportsByHumanId.fulfilled, (state, action) => {
            const reports = action.payload;
            state.chat = { ...action.payload, Reports: reports.map(r => ({...r, ReportSubmittedAt: getFormatedDateTimeString(new Date(r.ReportSubmittedAt)), Answers: r.Answers.map(a => ({...a, AnsweredAt: getFormatedDateTimeString(new Date(a.AnsweredAt))}))})) };
            state.status = requestStatus.succeeded;
        })
        builder.addCase(fetchUserReportsByHumanId.pending, (state, action) => {
            state.status = requestStatus.loading
        })
        builder.addCase(fetchUserReportsByHumanId.rejected, (state, action) => {
            state.status = requestStatus.failed;
        })
        builder.addCase(fetchMessagesList.fulfilled, (state, action) => {
            const answers = action.payload.Answers.map(x => ({ ...x, AnsweredAt: getFormatedDateTimeString(new Date(x.AnsweredAt)) }));
            state.chat = { ...action.payload, Answers: answers };
            state.status = requestStatus.succeeded;
        })
        builder.addCase(fetchMessagesList.pending, (state, action) => {
            state.status = requestStatus.loading
        })
        builder.addCase(fetchMessagesList.rejected, (state, action) => {
            state.status = requestStatus.failed;
        })
        builder.addCase(submitAnswer.fulfilled, (state, action) => {
            const answers = action.payload.Answers.map(x => ({ ...x, AnsweredAt: getFormatedDateTimeString(new Date(x.AnsweredAt)) }));
            state.chat = { ...action.payload, Answers: answers };
            state.status = requestStatus.succeeded;
        })
        builder.addCase(submitAnswer.pending, (state, action) => {
            state.status = requestStatus.loading
        })
        builder.addCase(submitAnswer.rejected, (state, action) => {
            state.status = requestStatus.failed;
        })
    }
})

export const { setCredentials, addMessage, clearState } = messageSlice.actions;

export default messageSlice.reducer;