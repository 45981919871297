import { React, useState, useEffect } from 'react'
import TabBar from '../../common/TabBar'
import Header from '../../common/Header'
import ToggleSwitch from '../../common/ToggleSwitch';
import { sendRequestAsync, getDeviceSettingsAsync } from '../../../api/requestService';
import { httpMethod, saveSettingsUrl } from '../../../api/apiConsts';
import { authHelper } from '../../../api/authHelper';
import { useDispatch } from 'react-redux';
import { changeLoadingStatus } from '../../../reducers/loadingSlice';
import { storageHelper } from '../../../helpers/storageHelper';
import { useNavigate, useLocation } from 'react-router';
import { routes } from '../../../routing/routeConsts';
import { setErrorWithoutRedirect } from "../../../reducers/loadingSlice";
import { addLocationToFavoritesAsync } from "../../../reducers/locationSlice";
import store from '../../../store';
import PhoneInputWithCountryCode from '../../common/PhoneInputWithCountryCode';

export default function SettingsPage() {
    const navigate = useNavigate();
    const contactInfo = storageHelper.getStoredContactInfo();
    const [settings, setSettings] = useState(null);
    const [allowPushNotifications, setAllowPushNotifications] = useState();
    const [allowLocationTracking, setAllowLocationTracking] = useState();
    const [firstName, setFirstName] = useState(contactInfo?.firstName || "");
    const [lastName, setLastName] = useState(contactInfo?.lastName || "");
    const [email, setEmail] = useState(contactInfo?.email || "");
    const [phoneNumber, setPhoneNumber] = useState(contactInfo?.phoneNumber || "");
    const [favoriteLocationCode, setFavoriteLocationCode] = useState("");
    const [isRedirectedUsingDeepLink, setIsRedirectedUsingDeepLink] = useState(false);
    const [deepLinkLocationCode, setDeepLinkLocationCode] = useState(null);
    const dispatch = useDispatch();
    const query = new URLSearchParams(useLocation().search);
    const userHumanId = authHelper.getUserHumanId();

    const getUserSettingsAsync = async (rapidSOSUserId = null) => {
        const response = await getDeviceSettingsAsync(rapidSOSUserId);
        setSettings(response || { allowPushNotifications, allowLocationTracking });
        setAllowLocationTracking(response?.AllowLocationTracking);
        setAllowPushNotifications(response?.AllowPushNotifications);
        setFavoriteLocationCode(response?.FavoriteLocationCode);

        if (!contactInfo?.firstName && response?.RapidSOSUserInformation?.FirstName != null) {
            setFirstName(response?.RapidSOSUserInformation?.FirstName);
        }

        if (!contactInfo?.lastName && response?.RapidSOSUserInformation?.LastName != null) {
            setLastName(response?.RapidSOSUserInformation?.LastName);
        }

        if (!contactInfo?.phoneNumber && response?.RapidSOSUserInformation?.Phone != null) {
            setPhoneNumber(response?.RapidSOSUserInformation?.Phone);
        }

        if (!contactInfo?.email && response?.RapidSOSUserInformation?.Email != null) {
            setEmail(response?.RapidSOSUserInformation?.Email);
        }
    }

    const checkIsSendAllowed = () => allowLocationTracking !== settings.AllowLocationTracking
        || allowPushNotifications !== settings.AllowPushNotifications
        || firstName
        || lastName
        || email
        || phoneNumber
        || favoriteLocationCode !== settings.FavoriteLocationCode;

    const onSendClick = async () => {
        if (allowLocationTracking !== settings.AllowLocationTracking
            || allowPushNotifications !== settings.AllowPushNotifications
            || favoriteLocationCode !== settings.FavoriteLocationCode) {
            await sendRequestAsync(
                httpMethod.post, 
                saveSettingsUrl,  
                {
                    deviceId: authHelper.getDeviceId(),
                    allowLocationTracking,
                    allowPushNotifications,
                    favoriteLocationCode,
                },
                (errorMessage) => {
                    store.dispatch(setErrorWithoutRedirect(errorMessage))
                }
            )
        }

        storageHelper.setStoredContactInfo({ firstName, lastName, email, phoneNumber });

        //much faster than window.location.reload()
        if (isRedirectedUsingDeepLink && deepLinkLocationCode != null) {
            if (userHumanId) {
                await addLocationToFavoritesAsync([`${deepLinkLocationCode}`]);
                navigate(`${routes.home}`);
            } else {
                navigate(`${routes.report}/${deepLinkLocationCode}`);
            }

        } else {
            navigate(routes.options)
            navigate(routes.settings)
        }
    }

    useEffect(() => {
        let rapidSOSUserId = query?.get("rapidSOSUserId");

        if (!settings || rapidSOSUserId != null) {
            getUserSettingsAsync(rapidSOSUserId);
        }

        setIsRedirectedUsingDeepLink(query?.get("isRedirectedUsingDeepLink"));
        setDeepLinkLocationCode(query?.get("deepLinkLocationCode"));
    }, [])

    useEffect(() => {
        if (deepLinkLocationCode != null && userHumanId == null) {
            setFavoriteLocationCode(deepLinkLocationCode);
        }
    }, [deepLinkLocationCode, userHumanId])

    useEffect(() => {
        dispatch(changeLoadingStatus(!settings));
    }, [settings])

    return settings && (
        <>
            <Header title="Settings" showBackArrow={true} />
            <div className="container tab-bar-predecessor settings__container">
                <div className="container_bordered">
                    <div className="text_primary">Favorite Location Code</div>
                    <input
                        className="input__container input text_default favorite-location-code"
                        placeholder="Code"
                        onInput={e => setFavoriteLocationCode(e.target.value)}
                        value={favoriteLocationCode}
                    />
                </div>
                <div className="container_bordered">
                    <div className="text_primary">Allow Notifications</div>
                    <ToggleSwitch isChecked={allowPushNotifications} onChange={value => setAllowPushNotifications(value)} />
                </div>
                <div className="container_bordered">
                    <div className="text_primary">Allow Location Access</div>
                    <ToggleSwitch isChecked={allowLocationTracking} onChange={value => setAllowLocationTracking(value)} />
                </div>

                <div className="section__header">My contact info</div>
                <input
                    className="input__container input text_default"
                    placeholder="First Name (optional)"
                    onInput={e => setFirstName(e.target.value)}
                    value={firstName}
                />
                <input
                    className="input__container input text_default"
                    placeholder="Last Name (optional)"
                    onInput={e => setLastName(e.target.value)}
                    value={lastName}
                />
                <input
                    className="input__container input text_default"
                    placeholder="Email (optional)"
                    onInput={e => setEmail(e.target.value)}
                    value={email}
                />
                <PhoneInputWithCountryCode
                    placeholder="Phone Number (optional)"
                    enableSearch={true}
                    country={"us"}
                    onChange={value => setPhoneNumber(`+${value}`)}
                    value={phoneNumber}
                />

                <button onClick={() => onSendClick()}
                        className={`text_default ${checkIsSendAllowed() ? "button" : "button_bordered_small button_disabled"}`}>
                    {((isRedirectedUsingDeepLink && deepLinkLocationCode != null) ? 'Save & Continue' : 'Save')}
                </button>
            </div>
            <TabBar />
        </>
    )
}