import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {  useLocation, useNavigate } from 'react-router-dom';
import TabBar from '../../common/TabBar';
import Header from '../../common/Header';
import AboutHumanIdText from '../../common/AboutHumanIdText';
import { httpMethod, humanIdLoginCallbackUrl } from "../../../api/apiConsts";
import { sendRequestAsync } from "../../../api/requestService";
import { changeLoadingStatus } from '../../../reducers/loadingSlice';
import { routes } from '../../../routing/routeConsts';
import { authHelper } from '../../../api/authHelper';
import { setError } from "../../../reducers/loadingSlice";

export default function HumanIdLoginPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);

    const setUserHumanIdAsync = async (exchangeToken) => {
        const response = await sendRequestAsync(httpMethod.get, `${humanIdLoginCallbackUrl}?et=${exchangeToken}`);
        authHelper.setUserHumanId(response.data);
        navigate(routes.messages)
        dispatch(changeLoadingStatus(false));
    }

    useEffect(() => {
        dispatch(changeLoadingStatus(true));

        const exchangeToken = query?.get("et")
    
        if (exchangeToken) {
            setUserHumanIdAsync(exchangeToken);
        } else {
            const message = query?.get("message");
            navigate(routes.home)
            dispatch(setError(message));
        }
    }, []);

    return (
        <>
            <Header title="HumanID Login" showBackArrow={true} />
            <AboutHumanIdText/>
            <TabBar />
        </>
    )
}