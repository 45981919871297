import React from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { getLanguage } from '../../../helpers/languageHelper';
import { goToPreviousLocation, routes } from '../../../routing/routeConsts';

export default function Header({
    title,
    useLogo,
    showLanguage,
    showBackArrow,
    showCancelText,
    max,
    currentStep,
    onCancel,
    isLogoCentered,
    showHomeIcon,
    useLightTheme,
    isShortFormSelected
}) {
    const navigate = useNavigate();
    const location = useLocation();
    const onCancelClick = () => onCancel ? onCancel() : navigate(routes.home);
    const language = getLanguage();

    return (
        <div className={title || useLightTheme ? "header" : "header__theme_light"}>
            {showCancelText &&
                <div className="header__title_cancel" onClick={() => onCancelClick()}>Cancel</div>
            }
            {showBackArrow &&
                <div className={useLogo ? "header__icon_back__theme_dark" : "header__icon_back"} onClick={() => onCancel ? onCancel() : goToPreviousLocation(navigate, location)} />
            }
            {useLogo &&
                <Link className={`${(isLogoCentered && "header__logo_centered")} ${(useLightTheme ? "header__logo__theme_light" : "header__logo")}`}
                    to={{ pathname: routes.home, state: { referrer: location.pathname } }}>
                </Link>
            }
            {title &&
                <div className="header__title">{title}</div>
            }
            {currentStep && max && !isShortFormSelected
                && <div className="header__steps">
                    <span className="header__steps_current">{currentStep}</span>
                    <span className="header__steps_max">/{max}</span>
                </div>
            }
            {showLanguage && language &&
                <Link to={{ pathname: routes.languages, state: { referrer: location.pathname } }}>
                    <img className="header__icon_language" src={language.b64String} alt="language" />
                </Link>
            }
            {showHomeIcon &&
                <div className="header__icon_home" onClick={() => navigate(routes.home)} />
            }
        </div>
    )
}