const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
let recognition = null;
let recognitionResult = null;

export const getRecongnition = (onChange, id) => {
    if (!SpeechRecognition) {
        return null;
    }

    if (!recognition) {
        recognition = new SpeechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;
    }
    recognition.onresult = event => {
        recognitionResult = [...event.results]
            .map((x) => [...x][0].transcript)
            .join("");
        onChange(`${recognitionResult}`);
    };

    return recognition;
}

export const resetRecognition = () => {
    recognition = null;
    recognitionResult = null;
};