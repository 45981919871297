import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { reportAnswers as reportAnswersSelector, updateReportAnswers } from '../../../../../reducers/reportSlice';

export default function RadioGroupControl({ control, goToNextStep, stepNumber, isShortFormSelected }) {
    const reportAnswers = useSelector(reportAnswersSelector);
    const dispatch = useDispatch();

    const onOptionClick = item => {
        dispatch(updateReportAnswers({ ...reportAnswers, [control.Id]: item.Id }))
        if (control.IsRequired || isShortFormSelected) {
            goToNextStep();
        }
    }

    return (
        <div className="radio-group-control" >
            <div className={isShortFormSelected ? "section__header short-form" : "section__header"}>{control.Name}</div>
            {isShortFormSelected 
                ? <div className='radio-group-control__container short-form'>
                    {control.Options.map(item => (
                        <div
                            className={reportAnswers && item.Id === reportAnswers[control.Id]
                                ? "radio-group-control__answer_selected short-form text_default"
                                : "radio-group-control__answer short-form text_default"}
                            onClick={() => onOptionClick(item)}
                            key={item.Id}>
                            {item.Name}<span className="radio-group-control__answer_icon"></span>
                        </div>
                    ))}
                </div>
                : control.Options.map(item => (
                    <div
                        className={reportAnswers && item.Id === reportAnswers[control.Id]
                            ? "radio-group-control__answer_selected text_default"
                            : "radio-group-control__answer text_default"}
                        onClick={() => onOptionClick(item)}
                        key={item.Id}>
                        {item.Name}<span className="radio-group-control__answer_icon"></span>
                    </div>
                ))
            }
        </div >
    )
}