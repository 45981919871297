import React from 'react'
import TabBar from '../../common/TabBar'
import Header from '../../common/Header'

export default function AboutUsPage() {
    return (
        <>
            <Header title="About Us" showBackArrow={true} />

            <div className="container text_primary">
                <div>
                    Founded in 1999 <span className="text_bold">report it®</span> has become the most reputable and trusted leader of anonymous hotline and tip line services. Today <span className="text_bold">report it®</span> services extend to public and private companies, government agencies and municipalities, the educational community from K-12 plus colleges and universities, medical and financial institutions and more.
                </div>
                <br />
                <div >
                    <span className="text_bold">report it®</span> is widely recognized and well respected as a community engagement platform. Throughout the country users can “see it, say it, report it” instantly with the confidence that their concern is immediately and efficiently sent to the responsible person at the organization they wish to alert. Communication is always anonymous, secure and delivered in real time.
                </div>
            </div>

            <TabBar />
        </>
    )
}