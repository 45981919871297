import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useLocation
} from "react-router-dom";
import AboutUsPage from '../components/pages/AboutUsPage';
import AboutHumanIdPage from '../components/pages/AboutHumanIdPage';
import HomePage from '../components/pages/HomePage';
import LanguagesPage from '../components/pages/LanguagesPage';
import OptionsPage from '../components/pages/OptionsPage';
import SettingsPage from '../components/pages/SettingsPage';
import TermsOfUsePage from '../components/pages/TermsOfUsePage';
import CallEmergencyPage from '../components/pages/CallEmergencyPage';
import CategoriesPage from '../components/pages/CategoriesPage';
import ChangeAreaPage from '../components/pages/ChangeAreaPage';
import LocationsPage from '../components/pages/LocationsPage';
import SuccessSubmissionPage from '../components/pages/ReportPage/SuccessSubmissionPage';
import MessagesAlertPage from '../components/pages/ReportPage/MessagesAlertPage';
import ReportPage from '../components/pages/ReportPage';
import AcceptTermsPage from '../components/pages/AcceptTermsPage';
import MessagesPage from '../components/pages/MessagesPage';
import ChatPage from '../components/pages/ChatPage';
import HumanIdLoginPage from '../components/pages/HumanIdLoginPage';
import ProtectedRoute from './ProtectedRoute';
import ErrorModal from '../components/common/modals/ErrorModal';
import { routes } from './routeConsts';
import FavoriteLocationsPage from '../components/pages/FavoriteLocationsPage';
import VideoChatPage from '../components/pages/VideoChatPage';

const SmartRoute = ({ component: Component, ...rest }) => {
    let path = null;
    const location = useLocation();
    if (location.hash === "#/choose-language") {
        path = routes.languages;
    }

    if (location.hash.includes("#/create-report")) {
        path = `${routes.report}/${location.hash.split("/").pop()}`;
    }

    return (
        path
            ? <Navigate to={path} />
            : <HomePage />
    )
};

export default function AppRouter() {
    return (
        <Router>
            <ErrorModal />

            <Routes>
                <Route path={routes.accept} element={<AcceptTermsPage />} />
                <Route path={routes.languages} element={<LanguagesPage />}/>
                <Route path={routes.aboutUs} element={<AboutUsPage />}/>
                <Route path={routes.aboutHumanId} element={<AboutHumanIdPage />}/>
                <Route path={routes.termsOfUse} element={<TermsOfUsePage />}/>
                <Route path={routes.callEmergency} element={<CallEmergencyPage />}/>
                <Route path={routes.login} element={<HumanIdLoginPage />}/>
                <Route path={routes.goLiveChat} element={<VideoChatPage />}/>

                <Route 
                    path={routes.settings}
                    element={
                        <ProtectedRoute component={<SettingsPage />} path={routes.settings} />
                    } 
                />
                <Route 
                    path={routes.options}
                    element={
                        <ProtectedRoute component={<OptionsPage />} path={routes.options} />
                    } 
                /> 
                <Route 
                    path={routes.search}
                    element={
                        <ProtectedRoute component={<CategoriesPage />} path={routes.search} />
                    } 
                />
                <Route 
                    path={routes.changeArea}
                    element={
                        <ProtectedRoute component={<ChangeAreaPage />} path={routes.changeArea} />
                    } 
                />
                <Route 
                    path={`${routes.report}/googlePlace/:googlePlaceId`}
                    element={
                        <ProtectedRoute component={<ReportPage />} path={`${routes.report}/googlePlace/:googlePlaceId`} />
                    } 
                />
                <Route 
                    path={`${routes.report}/:locationCode/:language?`}
                    element={
                        <ProtectedRoute component={<ReportPage />} path={`${routes.report}/:locationCode/:language?`} />
                    } 
                />
                <Route 
                    path={routes.reportSucceeded}
                    element={
                        <ProtectedRoute component={<SuccessSubmissionPage />} path={routes.reportSucceeded} />
                    } 
                />
                <Route 
                    path={`${routes.category}/:categoryId`}
                    element={
                        <ProtectedRoute component={<LocationsPage />} path={`${routes.category}/:categoryId`} />
                    } 
                />
                <Route 
                    path={`${routes.locations}/:findInGooglePlaces?`}
                    element={
                        <ProtectedRoute component={<LocationsPage />} path={`${routes.locations}/:findInGooglePlaces?`} />
                    } 
                />
                <Route 
                    path={routes.messages}
                    element={
                        <ProtectedRoute component={<MessagesPage />} path={routes.messages} />
                    } 
                />
                <Route 
                    path={routes.messagesAlert}
                    element={
                        <ProtectedRoute component={<MessagesAlertPage />} path={routes.messagesAlert} />
                    } 
                />
                <Route 
                    path={routes.chat}
                    element={
                        <ProtectedRoute component={<ChatPage />} path={routes.chat} />
                    } 
                />
                <Route 
                    path={routes.favoriteLocations}
                    element={
                        <ProtectedRoute component={<FavoriteLocationsPage />} path={routes.favoriteLocations} />
                    } 
                />
                <Route 
                    path={routes.home} 
                    element={
                        <SmartRoute path={routes.home} />
                    } 
                />

                <Route to={routes.home} />
            </Routes>
        </Router >)
}