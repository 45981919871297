import React from 'react'
import { maxVideoDuration, maxDocsFileSize, maxPicturesAttached, maxDocsAttached, maxVideosAttached } from '../../../../consts';
import { formatFileSize } from "../../../../helpers/fileHelper"

export default function MediaWarningModal() {
    return (
        <>
            <div className="media-choice__title text_bold">Media options</div>
            <div className="media-choice__item"><span>up to {maxPicturesAttached}</span> images</div>
            <div className="media-choice__item"><span>{maxVideosAttached}</span> video <span>({maxVideoDuration} seconds max)</span></div>
            <div className="media-choice__item_last"><span>{maxDocsAttached}</span> .pdf <span>or</span> .txt <span>({formatFileSize(maxDocsFileSize)} max)</span></div>
        </>
    )
}