import React from 'react'

export default function AboutHumanIdText() {

    return (
        <div className="container about-human-id">
            <div className="text_primary about-human-id__text">
                Report It has partnered with humanID to provide anonymous report management. 
                humanID has developed one-click, anonymous authentication solutions that provide a safer online experience 
                through the guarantee of user privacy and the prevention of bot, duplicate, and 
                spam accounts. <a className="about-human-id__text__link" href="https://human-id.org/#how-we-protect/" target="_blank" rel="noopener noreferrer">Learn more</a> about humanID.
            </div>
        </div>
    )
}