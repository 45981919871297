import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { reportAnswers as reportAnswersSelector, updateReportAnswers } from '../../../../../reducers/reportSlice';
import Picker from '../../pickers/Picker';

export default function SubLocationControl({ control, isShortFormSelected }) {
    const [isPickerShown, setIsPickerShown] = useState(false);
    const maxVisibleCount = 3;
    const reportAnswers = useSelector(reportAnswersSelector);
    const subLocation = reportAnswers?.subLocation;
    const [selectedAnswer, setSelectedAnswer] = useState(subLocation
        ? { value: subLocation.Id, text: subLocation.Text, index: control.Answers.findIndex(x => x.Id === subLocation.Id) }
        : { value: control.Answers[0].Id, text: control.Answers[0].Text, index: 0 });
    const dispatch = useDispatch();

    return (
        <>
            {isPickerShown &&
                <div className="backdrop">
                    <div className="picker__container sublocation-picker__container">
                        <div className="picker__title sublocation-picker__title">{control.Question}</div>
                        <div className="sublocation-picker__items">
                            <Picker
                                getId={index => `sub${index}`}
                                selectedAnswer={selectedAnswer}
                                setSelectedAnswer={value => setSelectedAnswer(value)}
                                data={control.Answers.map((x, i) => ({ value: x.Id, text: x.Text, index: i }))}
                                maxVisibleCount={maxVisibleCount}
                                isMiddleBased={false}
                            />
                        </div>
                        <div className="picker__button__container sublocation-picker__button__container">
                            <div
                                onClick={() =>  setIsPickerShown(false) }
                                className="picker__button text_default_gray">Cancel</div>
                            <div
                                onClick={() => {
                                    setIsPickerShown(false);
                                    dispatch(updateReportAnswers({
                                        ...reportAnswers,
                                        subLocation: control.Answers.find(x => x.Id === selectedAnswer.value)
                                    }))
                                }}
                                className="picker__button text_default_gray">Okay</div>
                        </div>
                    </div>
                </div>
            }

            <div className="sub-location-control" >
                <div className={isShortFormSelected ? "section__header short-form" : "section__header"}>{control.Question} {control.IsRequired ? "(required)" : "(optional)"}</div>
                <div
                    className="input__container_static"
                    onClick={() => setIsPickerShown(true)}>
                    {subLocation ? subLocation.Text : "Select"}
                </div>
            </div>
        </>
    )
}