import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clearSucceededResponse } from '../../../../reducers/reportSlice';
import { routes } from '../../../../routing/routeConsts';
import { languages } from '../../../../consts';
import { automaticRedirectionTime } from '../../../../consts'
import { changeLanguage, getLanguage } from '../../../../helpers/languageHelper';
import AndroidAppLink from '../../../common/AndroidAppLink';
import IosAppLink from '../../../common/IosAppLink';
import Modal from '../../../common/modals/Modal';
import ScreenshotWarningModal from '../../../common/modals/ScreenshotWarningModal';
import HumanIdAuthorizationButton from '../../../common/HumanIdAuthorizationButton';

export default function MessagesAlertPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const shouldBeTranslated = languages[0].code !== getLanguage().code;
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        let userInactivityTimer = setTimeout(function () {
            navigate(routes.home);
        }, automaticRedirectionTime);

        let navigationButtons = document.querySelectorAll('.button_bordered, .human-id__button');
        for (let i = 0; i < navigationButtons.length; i++) {
            navigationButtons[i].onclick = function() {
                clearTimeout(userInactivityTimer);
            };
        }

        return () => {
            dispatch(clearSucceededResponse());

            if (shouldBeTranslated) {
                changeLanguage(getLanguage().code)
            }
        }
    }, [])

    return (
        <>
            {showWarning &&
                <Modal
                    onNextClick={() => {
                        setShowWarning(false);
                        navigate(routes.messages);
                    }}
                    onCancelClick={() => {
                        setShowWarning(false);
                    }}
                    component={ScreenshotWarningModal}
                    nextText='Okay'
                    cancelText='Cancel'
                />}

            <div className='report-messages-alert__container tab-bar-predecessor'>
                <div className="report-messages-alert__logo" />
                <div className='main'>
                    <div className='text_default'>
                        Create an anonymous account to view messages by choosing <span className='text_bold'>"Anonymous Login with humanID"</span>. <span className='warning'>Your phone number will be deleted after verification.</span>
                    </div>
                    <HumanIdAuthorizationButton />
                    <div className='text_default'>
                        OR
                    </div>
                    <div className='text_default'>
                        Choose <span className='text_bold'>"Continue"</span> to view your messages by receiving a Report Number and PIN 
                    </div>
                    <Link onClick={() => setShowWarning(true)} className='button_bordered'>Continue</Link>
                </div>
                <div className='report-messages-alert__download-apps-icons__container'>
                    <AndroidAppLink />
                    <IosAppLink />
                </div>
            </div>
        </>
    )
}