import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../routing/routeConsts';
import Image from "../../../common/Image";
import reportItLogo from "../../../../static/images/Logo.svg";
import cameraIcon from "../../../../static/images/camera-icon.svg";
import disabledCameraIcon from "../../../../static/images/disabled-camera-icon.svg";
import microphoneIcon from "../../../../static/images/microphone-icon.svg";
import mutedMicrophoneIcon from "../../../../static/images/muted-microphone-icon.svg";
import switchCameraIcon from "../../../../static/images/switch-camera-icon.svg";

export default function VideoChatPage({ connectToVideoChatCallback, 
                                        isPreviewPassed, 
                                        locationName, 
                                        isUserCameraDisabled,
                                        isCameraActivated, 
                                        isMicrophoneActivated, 
                                        isUsingRearCamera }) {
    const Video = window.Twilio.Video;
    const { createLocalTracks } = Video;
    const navigate = useNavigate();
    let userMediaTracks = null;
    let cameras = null;

    const createUserTracks = () => {
        createLocalTracks({
            audio: true,
            video: { facingMode: (isUsingRearCamera ? 'environment' : 'user') }
          }).then(track => {
            userMediaTracks = { audio: track[0], video: track[1] };

            const mediaPreviewElement = document.querySelector('.media-preview__container');

            if (userMediaTracks?.video || userMediaTracks?.audio) {
                mediaPreviewElement?.appendChild(userMediaTracks?.audio?.attach());
                mediaPreviewElement?.appendChild(userMediaTracks?.video?.attach());
            }

            updateCameraState();
            updateMicrophoneState();
            updateControlsIcons();
        });
    }

    const onCameraClick = () => {
        if (!isUserCameraDisabled) {
            isCameraActivated = !isCameraActivated;
            updateCameraState();
            updateControlsIcons();
        } else {
            isCameraActivated = false;
            updateCameraState();
            updateControlsIcons();
        }
    }

    const onMicrophoneClick = () => {
        isMicrophoneActivated = !isMicrophoneActivated;
        updateMicrophoneState();
        updateControlsIcons();
    }

    const onSwitchCameraClick = async () => {   
        if (!isUserCameraDisabled) {
            await getMediaDevices();
        
            if(cameras && cameras.length > 1 && isCameraActivated) {
                isUsingRearCamera = !isUsingRearCamera;
    
                userMediaTracks?.video?.stop();
                const videoElements = userMediaTracks?.video?.detach();
                videoElements.forEach(videoElement => videoElement?.remove());
    
                Video.createLocalVideoTrack({
                    facingMode: (isUsingRearCamera ? 'environment' : 'user')
                }).then((newVideoTrack) => {
                    userMediaTracks.video = newVideoTrack;
                    const mediaPreviewElement = document.querySelector('.media-preview__container');
                    mediaPreviewElement?.appendChild(userMediaTracks?.video?.attach());
                });
            }  
        }    
    }

    const onJoinClick = async () => {
        userMediaTracks?.audio?.stop();
        const audioElements = userMediaTracks?.audio?.detach();
        audioElements?.forEach(audioElement => audioElement?.remove());

        userMediaTracks?.video?.stop();
        const videoElements = userMediaTracks?.video?.detach();
        videoElements?.forEach(videoElement => videoElement?.remove());
        
        await connectToVideoChatCallback(true, isCameraActivated, isMicrophoneActivated, isUsingRearCamera);
    }

    const onCancelClick = () => {
        userMediaTracks?.audio?.stop();
        const audioElements = userMediaTracks?.audio?.detach();
        audioElements?.forEach(audioElement => audioElement?.remove());

        userMediaTracks?.video?.stop();
        const videoElements = userMediaTracks?.video?.detach();
        videoElements?.forEach(videoElement => videoElement?.remove());

        navigate(routes.home);
    }

    const updateMicrophoneState = () => {
        isMicrophoneActivated ? userMediaTracks?.audio?.enable() : userMediaTracks?.audio?.disable();
    }

    const updateCameraState = () => {
        isCameraActivated ? userMediaTracks?.video?.enable() : userMediaTracks?.video?.disable();
    }

    const updateControlsIcons = () => {
        const audioControl = document.querySelector('.local-media-preview__audio-control__icon');
        const videoControl = document.querySelector('.local-media-preview__video-control__icon');
        
        isCameraActivated ? videoControl.src = cameraIcon : videoControl.src = disabledCameraIcon;
        isMicrophoneActivated ? audioControl.src = microphoneIcon : audioControl.src = mutedMicrophoneIcon;
    }

    const getMediaDevices = async () => {
        if(!cameras) {
            await navigator.mediaDevices.enumerateDevices().then((devices) => {
                cameras = devices?.filter((d) => d?.kind === 'videoinput');
            });
        }
    }

    useEffect(() => {
        createUserTracks();
    }, []);

    return (
        <>
            <div className={isPreviewPassed ? 'display-none' : 'local-media-preview__container'}>
                <Image className='report-it-logo' src={reportItLogo}></Image>
                <h1 className='section__header'>Join “{locationName}” GoLive Session</h1>
                <div className='media-preview__container'></div>
                <div className='local-media-preview__controls'>
                    <div className={'video-control local-media-preview__control'+ (isUserCameraDisabled ? ' disabled' : '')} onClick={onCameraClick}>
                        <Image className={'local-media-preview__controls_icon local-media-preview__video-control__icon'} 
                               src={isUserCameraDisabled ? disabledCameraIcon : (isCameraActivated ? cameraIcon : disabledCameraIcon)}/>
                    </div>
                    <div className='audio-control local-media-preview__control' onClick={onMicrophoneClick}>
                        <Image className={'local-media-preview__controls_icon local-media-preview__audio-control__icon'} 
                               src={isMicrophoneActivated ? microphoneIcon : mutedMicrophoneIcon}/>
                    </div>
                    <div className={'switch-camera-control local-media-preview__control' + (isUserCameraDisabled ? ' disabled' : '')} onClick={onSwitchCameraClick}>
                        <Image className={'local-media-preview__controls_icon'} src={switchCameraIcon} />
                    </div>
                </div>
                <div className='local-media-preview__navigation'>
                    <div className='cancel-button local-media-preview__navigation_button' onClick={onCancelClick}>Cancel</div>
                    <div className='join-button local-media-preview__navigation_button' onClick={onJoinClick}>Join GoLive Session</div>
                </div>
            </div>
        </>
    )
}

