export const httpMethod = {
    get: "GET",
    post: "POST"
};

export const requestStatus = {
    idle: 'idle',
    loading: 'loading',
    succeeded: 'succeeded',
    failed: 'failed'
};

export const baseUrl = process.env.REACT_APP_API_ROOT_URL;

const createUrl = path => baseUrl + path;

export const getCategoryListUrl = createUrl("/Locations/GetCategoriesList");
export const getLocationListUrl = createUrl("/Locations/GetLocationsBySearchQuery");
export const getFavoriteLocationsListUrl = createUrl("/Locations/GetFavoriteLocationsBySearchQuery");
export const getNearbyLocationsListUrl = createUrl("/Locations/GetNearbyLocations");
export const addLocationToFavoritesUrl = createUrl("/Locations/AddToFavorites");
export const removeLocationFromFavoritesUrl = createUrl("/Locations/RemoveFromFavorites");
export const getReportUrl = createUrl("/Locations/GetLocationByCodeWithForm");
export const getGooglePlaceDetailsUrl = createUrl("/Locations/GetGooglePlaceDetails");
export const submitReportUrl = createUrl("/Report/SubmitReportWithControls");
export const uploadMediaUrl = createUrl("/Report/UploadMedia");
export const humanIdLoginUrl = createUrl("/Report/HumanIdLogin");
export const humanIdLoginCallbackUrl = createUrl("/Report/HumanIdLoginCallback");
export const getAllUserReportsByHumanIdUrl = createUrl("/Report/GetAllUserReportsByHumanId");
export const getMessageListUrl = createUrl("/Report/GetReportByPin");
export const submitNewAnswerUrl = createUrl("/Report/SubmitNewAnswer");
export const markAnswerAsReadUrl = createUrl("/Report/MarkAnswerAsRead");
export const changeReportStatusUrl = createUrl("/Report/ChangeAnswerStatus");
export const checkIsPanicAlarmEnabledUrl = createUrl("/Report/CheckIsPanicAlarmEnabledOnLocation");
export const createUserUrl = createUrl("/Account/CreateUser");
export const contactUsUrl = createUrl("/Account/SendContactUs");
export const saveSettingsUrl = createUrl("/Account/SaveSettings");
export const handleUserGoogleCaptchaTokenUrl = createUrl("/Account/HandleUserGoogleCaptchaToken");
export const getSettingsUrl = createUrl("/Account/GetUserSettings");
export const getEmergencyNumberUrl = createUrl("/Account/GetEmergencyNumber");
export const getTokenUrl = createUrl("/token");
export const getVideoChatTokenUrl = createUrl("/Twilio/GetAccessTokenAsync");
export const getValidateInvitationTokenUrl = createUrl("/Twilio/ValidateInvitationTokenAsync");
export const updateCurrentUserPositionUrl = createUrl("/Twilio/UpdateCurrentUserPositionAsync");
export const changeRoomRecordingStatusUrl = createUrl("/Twilio/ChangeRoomRecordingStatus");
export const completeRoomUrl = createUrl("/Twilio/CompleteRoom");