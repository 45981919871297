import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { authHelper } from '../api/authHelper';
import { routes } from './routeConsts';

export default function ProtectedRoute({ component: Component }) {
    const location = useLocation();
    return authHelper.isTermsAccepted()
                ? Component
                : <Navigate to={{ pathname: routes.accept }} state={{ location }} replace />
    
}