import React, { useEffect, useState } from "react";
import TabBar from "../../common/TabBar";
import Header from "../../common/Header";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { routes } from "../../../routing/routeConsts";
import { fetchUserFavoriteLocationsListAsync } from "../../../reducers/locationSlice";
import { useDispatch, useSelector } from "react-redux";
import { requestStatus } from "../../../api/apiConsts";
import { fetchLocationList, setSearchAreaCoords, clearState } from "../../../reducers/locationSlice";
import LocationItem from "../LocationsPage/LocationItem";
import { changeLoadingStatus } from "../../../reducers/loadingSlice";
import { getFormatedAddress, getCurrentPositionAsync } from "../../../helpers/geoHelper";
import { updateGooglePlaceDetails } from "../../../reducers/reportSlice";
import Image from "../../common/Image";
import reportItLogo from "../../../static/images/Logo.svg";

export default function FavoriteLocationsPage() {
    const [userFavoriteLocations, setUserFavoriteLocations] = useState([]);
    const [nonReportingLocations, setNonReportingLocations] = useState();
    const [reportingLocations, setReportingLocations] = useState();
    const [isMapView, setIsMapView] = useState(false);
    const { categoryId, findInGooglePlaces } = useParams();
    const searchQuery = useSelector(state => state.location.searchQuery);
    const fetchStatus = useSelector(state => state.location.status);
    const searchAreaCoords = useSelector(state => state.location.searchAreaCoords);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const fetchUserFavoriteLocationsAsync = async () => {
        let favoriteLocations = await fetchUserFavoriteLocationsListAsync();
        setUserFavoriteLocations(favoriteLocations?.Locations);
    }

    const onLocationItemClick = (item) => {
        if (item.GooglePlaceId) {
            dispatch(updateGooglePlaceDetails({ Name: item.Name, Latitude: item.Latitude, Longitude: item.Longitude }));
        }
        navigate(`${routes.report}${item.GooglePlaceId ? `/googlePlace/${item.GooglePlaceId}` : `/${item.Code}`}`);
        // addReferrer ? { referrer: location.pathname } : null
    }

    useEffect(() => {
        fetchUserFavoriteLocationsAsync();
        
        return () =>
            dispatch(clearState());
    }, [])

    useEffect(() => {
        if (userFavoriteLocations) {
            setNonReportingLocations(userFavoriteLocations.filter(item => item.IsNonReportingLocation));
            setReportingLocations(userFavoriteLocations.filter(item => !item.IsNonReportingLocation));
        }
        dispatch(changeLoadingStatus(fetchStatus === requestStatus.loading));
    }, [userFavoriteLocations, fetchStatus])

    useEffect(() => {
        if (!searchAreaCoords) {
            getCurrentPositionAsync(position => {
                getFormatedAddress(position.coords.latitude, position.coords.longitude,
                    address => dispatch(setSearchAreaCoords(address)));
            })
        }
        dispatch(fetchLocationList({ searchAreaCoords, categoryId, searchQuery, findInGooglePlaces }));
    }, [categoryId, searchQuery, searchAreaCoords, findInGooglePlaces])

    return (
        <>
            <Header title="My Saved Locations" showBackArrow={true} />

            {(<div className="location-list__container favorite-locations-page__container">
                    {reportingLocations?.length > 0 && (
                        <>
                            <div className="location-list__header">
                                <div className="location-list__header__icon" />
                                <div className="text_default text_bold" >report it now to these organizations</div>
                            </div>

                            {reportingLocations.filter(item => userFavoriteLocations?.some(location => location.Code === item.Code))
                                .map(item => (
                                    <LocationItem
                                        key={item.Code || item.GooglePlaceId}
                                        location={item}
                                        isDistanceShown={!isMapView}
                                        onClick={() => onLocationItemClick(item)}
                                        onFavoriteLocationUpdate={() => fetchUserFavoriteLocationsAsync()}
                                        isFavoriteLocation={userFavoriteLocations?.some(location => location.Code === item.Code)}
                                    />
                            ))}
                        </>
                    )}

                    {nonReportingLocations?.length > 0 && (
                        <>
                            <div className="location-list__header">
                                <div className="location-list__header__icon_non-reporting" />
                                <div className="text_default text_bold" >Contact details only</div>
                            </div>
                            {nonReportingLocations.filter(item => userFavoriteLocations?.some(location => location.Code === item.Code))
                                .map(item => (
                                    <LocationItem
                                        key={item.Code}
                                        location={item}
                                        isDistanceShown={!isMapView}
                                        onClick={() => onLocationItemClick(item)}
                                        onFavoriteLocationUpdate={() => fetchUserFavoriteLocationsAsync()}
                                        isFavoriteLocation={userFavoriteLocations?.some(location => location.Code === item.Code)}
                                    />
                            ))}
                        </>
                    )}

                    {!((nonReportingLocations?.length > 0) || (reportingLocations?.length > 0)) &&
                        <div className="container">
                            <Image className="report-it-logo" src={reportItLogo}/>
                            <div className="text_default text_bold">You don't have any favorite locations yet</div>
                        </div>
                    }
                </div>)
            }

            <TabBar />
        </>
    )
}