import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authHelper } from '../../../api/authHelper';
import { httpMethod, 
         getValidateInvitationTokenUrl } from '../../../api/apiConsts';
import { changeRoomRecordingStatusAsync, 
         completeRoomAsync, 
         updateVideoAccessTokenAsync,
         updateCurrentUserPositionAsync } from '../../../api/requestService';
import { setCredentials } from '../../../reducers/messageSlice';
import { setError } from '../../../reducers/loadingSlice';
import { routes, goToPreviousLocation } from '../../../routing/routeConsts';
import { locationUpdateIntervalTime } from '../../../consts';
import { getCurrentPositionAsync } from '../../../helpers/geoHelper';
import VideoChatPreviewPage from './VideoChatPreviewPage/index';
import Chat from '../../common/Chat/index';
import Image from '../../common/Image';
import Modal from '../../common/modals/Modal';
import reportItLogo from '../../../static/images/Logo.svg';
import disabledCameraBackground from '../../../static/images/disabled-camera-background.svg';
import cameraIcon from '../../../static/images/camera-icon.svg';
import disabledCameraIcon from '../../../static/images/disabled-camera-icon.svg';
import microphoneIcon from '../../../static/images/microphone-icon.svg';
import mutedMicrophoneIcon from '../../../static/images/muted-microphone-icon.svg';
import switchCameraIcon from '../../../static/images/switch-camera-icon.svg';
import disconnectIcon from '../../../static/images/disconnect-icon.svg';
import axios from 'axios';

export default function VideoChatPage() {
    const Video = window.Twilio.Video;
    const { connect, createLocalVideoTrack } = Video;
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [isPreviewPassed, setIsPreviewPassed] = useState(false);
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [reportId, setReportId] = useState(null);
    const [locationName, setLocationName] = useState(null);
    const [isUserCameraDisabled, setIsUserCameraDisabled] = useState(false);
    const [isPreviewCameraActivated, setIsPreviewCameraActivated] = useState(false);
    const [isPreviewMicrophoneActivated, setIsPreviewMicrophoneActivated] = useState(false);
    const [isPreviewUsingRearCamera, setIsPreviewUsingRearCamera] = useState(false);
    const [locationLogoPath, setLocationLogoPath] = useState(null);
    const [isUsingLocationLogo, setIsUsingLocationLogo] = useState(false);
    const [wasRecordingWarningModalClosed, setWasRecordingWarningModalClosed] = useState(false);
    const [showGoLivePopUpMessage, setShowGoLivePopUpMessage] = useState(false);
    const [goLivePopUpMessageText, setGoLivePopUpMessageText] = useState(false);
    const [currentUserPosition, setCurrentUserPosition] = useState(null);
    const [roomSid, setRoomSid] = useState(null);
    const query = new URLSearchParams(useLocation().search);
    let userMediaTracks = { video: null, audio: null };
    let cameras = null;
    let isCameraActivated = false;
    let isMicrophoneActivated = false;
    let isUsingRearCamera = false;

    const connectToVideoChatCallback = async (isPreviewPassed, isCameraActivatedValue, isMicrophoneActivatedValue, isUsingRearCameraValue) => {
        setIsPreviewCameraActivated(isCameraActivatedValue)
        setIsPreviewMicrophoneActivated(isMicrophoneActivatedValue)
        setIsPreviewUsingRearCamera(isUsingRearCameraValue)
        setIsPreviewPassed(isPreviewPassed);

        if (!showGoLivePopUpMessage) {
            await connectToVideoChat(isCameraActivatedValue, isMicrophoneActivatedValue, isUsingRearCameraValue);
        }
    }

    const connectToVideoChat = async (isCameraActivatedValue, isMicrophoneActivatedValue, isUsingRearCameraValue) => {
        isCameraActivated = isCameraActivatedValue;
        isMicrophoneActivated = isMicrophoneActivatedValue;
        isUsingRearCamera = isUsingRearCameraValue;
        updateControlsIcons();

        await connect(authHelper.getUserVideoChatToken()?.token, { 
            name: authHelper.getUserVideoChatToken()?.token, 
            audio: true, 
            video: { facingMode: (isUsingRearCamera ? 'environment' : 'user') }, 
            preferredVideoCodecs: ['VP8'] 
        }).then(async (room) => {
            
        await getCurrentPositionAsync(position => {
            setRoomSid(room.sid)
            setCurrentUserPosition(position.coords);
        })
        
        const interval = setInterval(async () => {
            await getCurrentPositionAsync(position => {
                setCurrentUserPosition(position.coords);
            })
        }, locationUpdateIntervalTime);

        room?.localParticipant?.videoTracks?.forEach(publication => {
            userMediaTracks.video = publication.track;
            const mediaPreviewElement = document.querySelector('.video-chat__user-media');

            if (userMediaTracks?.video || userMediaTracks?.audio) {
                let videoTrack = userMediaTracks?.video?.attach();
                videoTrack.setAttribute('id', userMediaTracks?.video?.id);
                mediaPreviewElement?.appendChild(videoTrack);
            }

            isMicrophoneActivated ? userMediaTracks?.audio?.enable() : userMediaTracks?.audio?.disable();
        })

        room?.localParticipant?.audioTracks?.forEach(publication => {
            userMediaTracks.audio = publication.track;
            const mediaPreviewElement = document.querySelector('.video-chat__user-media');

            if (userMediaTracks?.audio) {
                let audioTrack = userMediaTracks?.audio?.attach();
                audioTrack?.setAttribute('id', userMediaTracks?.audio?.id);
                mediaPreviewElement?.appendChild(audioTrack);
            }

            isMicrophoneActivated ? userMediaTracks?.audio?.enable() : userMediaTracks?.audio?.disable();
        })

        if (room.participants.size > 0) {
            document.querySelector('.video-chat__background').style.display = 'none';
        }

        window.addEventListener('beforeunload', () => {
            room.disconnect();
        });
        
        room.participants.forEach(participant => {
                
            if (participant.tracks.size > 0) {
                let tracksContainer = document.createElement('div');
                tracksContainer.setAttribute('id', participant.sid);
                tracksContainer.style.backgroundImage = `url(${isUsingLocationLogo 
                                                               ? (locationLogoPath || disabledCameraBackground) 
                                                               : disabledCameraBackground})`;
                tracksContainer.style.backgroundPosition = 'center';
                tracksContainer.style.backgroundSize = 'cover';
                document.querySelector('.video-chat__participants-media').appendChild(tracksContainer);
            }

            const container = participant.tracks.size > 0 
                            ? document.querySelector(`#${participant.sid}`)
                            : document.querySelector('.video-chat__participants-media');

            participant.tracks.forEach(publication => {
                if (publication.track) {
                    const track = publication.track;
                    let attachedTrack = track.attach();
                    attachedTrack.setAttribute('id', track.sid);
                    container.appendChild(attachedTrack);

                    track.on('enabled', () => {
                        switchVideoVisibility(participant, track);
                        updateControlsIcons();
                    });

                    track.on('disabled', () => {
                        switchVideoVisibility(participant, track);
                        updateControlsIcons();
                    });
                }
            });
            
            participant.on('trackSubscribed', track => {
                let attachedTrack = track.attach();
                attachedTrack.setAttribute('id', track.sid);
                container.appendChild(attachedTrack);
                switchVideoVisibility(participant, track);

                track.on('enabled', () => {
                    switchVideoVisibility(participant, track);
                    updateControlsIcons();
                });

                track.on('disabled', () => {
                    switchVideoVisibility(participant, track);
                    updateControlsIcons();
                });
            });

            participant.on('trackUnsubscribed', track => {
                detachTracks([track]);
            });
        });
        
        room.on('participantConnected', async (participant) => {
            if (room.participants.size > 0) {
                await changeRoomRecordingStatusAsync(room.sid, true, true);
            }

            document.querySelector('.video-chat__background').style.display = 'none';

            if (participant.tracks.size > 0) {
                let tracksContainer = document.createElement('div');
                tracksContainer.setAttribute('id', participant.sid);
                tracksContainer.style.backgroundImage = `url(${isUsingLocationLogo 
                                                               ? (locationLogoPath || disabledCameraBackground) 
                                                               : disabledCameraBackground})`;
                tracksContainer.style.backgroundPosition = 'center';
                tracksContainer.style.backgroundSize = 'cover';
                document.querySelector('.video-chat__participants-media').appendChild(tracksContainer);
            } 

            const container = participant.tracks.size > 0 
                            ? document.querySelector(`#${participant.sid}`)
                            : document.querySelector('.video-chat__participants-media');

            participant.tracks.forEach(publication => {
                if (publication.isSubscribed) {
                    const track = publication.track;
                    let attachedTrack = track.attach();
                    attachedTrack.setAttribute('id', track.sid);
                    container.appendChild(attachedTrack);

                    track.on('enabled', () => {
                        switchVideoVisibility(participant, track);
                        updateControlsIcons();
                    });

                    track.on('disabled', () => {
                        switchVideoVisibility(participant, track);
                        updateControlsIcons();
                    });
                }
            });
            
            participant.on('trackSubscribed', track => {
                let attachedTrack = track.attach();
                attachedTrack.setAttribute('id', track.sid);
                container.appendChild(attachedTrack);
                switchVideoVisibility(participant, track);

                track.on('enabled', () => {
                    switchVideoVisibility(participant, track);
                    updateControlsIcons();
                });

                track.on('disabled', () => {
                    switchVideoVisibility(participant, track);
                    updateControlsIcons();
                });
            });

            participant.on('trackUnsubscribed', track => {
                detachTracks([track]);
            });

            await getCurrentPositionAsync(position => {
                setCurrentUserPosition(position.coords);
            })
        });

        room.on('participantDisconnected', participant => {
            if (room.participants.size === 0) {
                document.querySelector('.video-chat__background').style.display = 'block';
                document.querySelector('.video-chat__background-header').innerHTML = 'Your GoLive Chat session has ended';
            } 
        });

        room.on('disconnected', async (room) => {
            await completeRoomAsync(room.sid);

            room?.localParticipant?.tracks.forEach(publication => {
                publication?.track?.stop();
                const attachedElements = publication?.track?.detach();
                attachedElements?.forEach(element => element?.remove());
            });

            userMediaTracks.audio.stop();
            const audioElements = userMediaTracks?.audio?.detach();
            audioElements.forEach(audioElement => audioElement.remove());
    
            userMediaTracks.video.stop();
            const videoElements = userMediaTracks?.video?.detach();
            videoElements.forEach(videoElement => videoElement.remove());

            clearInterval(interval);
        });

        const audioControl = document.querySelector('.video-chat__audio-control');

        audioControl.onclick = () => {
            isMicrophoneActivated = !isMicrophoneActivated;

            updateMicrophoneState(room);
            updateControlsIcons();
        };

        const videoControl = document.querySelector('.video-chat__video-control');

        videoControl.onclick = () => {
            if (!isUserCameraDisabled) {
                isCameraActivated = !isCameraActivated;

                updateCameraState(room);
                updateControlsIcons();
            } else {
                isCameraActivated = false;

                updateCameraState(room);
                updateControlsIcons();
            }
        };

        updateMicrophoneState(room);
        updateCameraState(room);

        const switchCameraControl = document.querySelector('.video-chat__switch-camera-control');

        switchCameraControl.onclick = async () => {
            await getMediaDevices();
    
            if(cameras && cameras?.length > 1 && isCameraActivated) {
                isUsingRearCamera = !isUsingRearCamera;
    
                userMediaTracks?.video?.stop();
                const videoElements = userMediaTracks?.video?.detach();
                videoElements.forEach(videoElement => videoElement?.remove());

                room?.localParticipant?.videoTracks.forEach(publication => {
                    publication?.track?.stop();
                    const attachedElements = publication?.track?.detach();
                    attachedElements?.forEach(element => element?.remove());
                });
    
                await createLocalVideoTrack({
                    facingMode: (isUsingRearCamera ? 'environment' : 'user')
                }).then((newVideoTrack) => {
                    userMediaTracks.video = newVideoTrack;
                    const mediaPreviewElement = document.querySelector('.video-chat__user-media');

                    let attachedTrack = userMediaTracks?.video?.attach();
                    attachedTrack.setAttribute('id', userMediaTracks?.video?.id);
                    mediaPreviewElement?.appendChild(attachedTrack);

                    const localParticipant = room.localParticipant;

                    const currentVideoTracks = Array.from(
                        localParticipant.videoTracks.values()
                    ).map((trackPublication) => (
                        trackPublication.track
                    ));

                    localParticipant.unpublishTracks(currentVideoTracks);
                    localParticipant.tracks.forEach((trackPublication) => {
                        if (trackPublication.track.kind === 'videoinput') {
                            trackPublication.track.stop();
                            trackPublication.track.detach().forEach((detachedElement) => {
                                detachedElement.remove()
                            })
                        }
                    });

                    localParticipant.publishTrack(newVideoTrack);
                });
            }     
        };
        
        const disconnectControl = document.querySelector('.video-chat__disconnect-control');

        disconnectControl.onclick = () => {
            room.disconnect();
            navigate(routes.home);
        };

        }, error => {
            console.error(`Unable to connect to Room: ${error.message}`);
        });
    }

    const updateCameraState = (room) => {
        if (isCameraActivated) {
            userMediaTracks?.video?.enable();
            room?.localParticipant?.videoTracks?.forEach(
                publication => publication?.track?.enable()
            );
        } else {
            userMediaTracks?.video?.disable();
            room?.localParticipant?.videoTracks?.forEach(
                publication => publication?.track?.disable()
            );
        }
    }

    const updateMicrophoneState = (room) => {
        if (isMicrophoneActivated) {
            userMediaTracks?.audio?.enable();
            room?.localParticipant?.audioTracks?.forEach(
                publication => publication?.track?.enable()
            );
        } else {
            userMediaTracks?.audio?.disable();
            room?.localParticipant?.audioTracks?.forEach(
                publication => publication?.track?.disable()
            );
        }
    }

    const updateControlsIcons = () => {
        const audioControl = document.querySelector('.video-chat__audio-control__icon');
        const videoControl = document.querySelector('.video-chat__video-control__icon');
        
        videoControl.src = isCameraActivated ? cameraIcon : disabledCameraIcon;
        audioControl.src = isMicrophoneActivated ? microphoneIcon : mutedMicrophoneIcon;
    }

    const switchVideoVisibility = (participant, track) => {
        if (track?.kind === 'video') {
            const videoTrack = document.getElementById(track.sid);
            const participantTracksContainer = document.getElementById(participant.sid);
            videoTrack.style.visibility = track.isEnabled ? 'visible' : 'hidden';
            participantTracksContainer.style.backgroundImage = track.isEnabled 
                                                                ? 'none' 
                                                                : `url(${isUsingLocationLogo 
                                                                         ? (locationLogoPath || disabledCameraBackground) 
                                                                         : disabledCameraBackground})`;
        }
    }

    const getMediaDevices = async () => {
        if(!cameras) {
            await navigator?.mediaDevices?.enumerateDevices()?.then((devices) => {
                cameras = devices?.filter((d) => d?.kind === 'videoinput');
            });
        }
    }

    const detachTracks = (tracks) => {
        tracks?.forEach(track => {
            track?.detach()?.forEach(detachedElement => {
                detachedElement?.remove();
            });
        });
    }

    const goBackFromVideoChat = () => {
        goToPreviousLocation(navigate, location, location?.state?.referrer?.includes(routes.category), routes.search);
    }

    const closeWarningModal = async () => {
        setWasRecordingWarningModalClosed(true);
        await connectToVideoChat(isPreviewCameraActivated, isPreviewMicrophoneActivated, isPreviewUsingRearCamera);
    }

    useEffect(() => {
        const invitationToken = query?.get("invitationToken");
        if (!invitationToken) { 
            dispatch(setError('The link has expired'));
        } else {
            axios({
                method: httpMethod.get,
                url: `${getValidateInvitationTokenUrl}?invitationToken=${invitationToken}`
            }).then((response) => {
                if (response?.data?.isTokenValid) {
                    updateVideoAccessTokenAsync(invitationToken).then(() => {
                        dispatch(setCredentials({ number: `${response?.data?.reportId}`, pin: "" }));
                        setReportId(response?.data?.reportId);
                        setLocationName(response?.data?.locationName);
                        setLocationLogoPath(response?.data?.locationLogoPath);
                        setIsUsingLocationLogo(response?.data?.isUsingLocationLogo);
                        setIsUserCameraDisabled(response?.data?.isUserCameraDisabled);
                        setIsPreviewUsingRearCamera(response?.data?.isUsingRearCamera);
                        setIsPreviewCameraActivated(response?.data?.isCameraEnabled);
                        setIsPreviewMicrophoneActivated(response?.data?.isMicrophoneEnabled);
                        setShowGoLivePopUpMessage(response?.data?.showGoLivePopUpMessage);
                        setGoLivePopUpMessageText(response?.data?.goLivePopUpMessageText);
                        
                        setIsTokenValid(response?.data?.isTokenValid);
                    });
                } else {
                    dispatch(setError('The link has expired'));
                }
            }).catch((error) => {
                dispatch(setError('An error occurred while trying to validate the token'));
            });
        }
    }, []);

    useEffect(() => {
        if (roomSid && currentUserPosition) {
            updateCurrentUserPositionAsync(roomSid, currentUserPosition);
        }
    }, [roomSid, currentUserPosition]);

    return (
        <>
            {isTokenValid && reportId &&
                (<VideoChatPreviewPage connectToVideoChatCallback={connectToVideoChatCallback} 
                                       isPreviewPassed={isPreviewPassed}
                                       locationName={locationName}
                                       isUserCameraDisabled={isUserCameraDisabled}
                                       isCameraActivated={isPreviewCameraActivated}
                                       isMicrophoneActivated={isPreviewMicrophoneActivated}
                                       isUsingRearCamera={isPreviewUsingRearCamera} />)}

            {isPreviewPassed && showGoLivePopUpMessage && !wasRecordingWarningModalClosed &&
                <Modal
                    htmlMarkup={goLivePopUpMessageText}
                    onCancelClick={() => goBackFromVideoChat()}
                    onNextClick={async () => await closeWarningModal()}
                    nextText={"Continue"}
                />
            }

            <div id='video-chat__container' className={isPreviewPassed ? '' : 'display-none'}>
                <div className='video-chat__media'>
                    <div className='video-chat__participants-media'>
                        <div className='video-chat__background'>
                            <Image className='report-it-logo' src={reportItLogo}></Image>
                            <h1 className='video-chat__background-header section__header'>Waiting for GoLive Session to Start</h1>
                        </div>
                        <div className='video-chat__user-media'></div>
                    </div>
                    <div className='video-chat__controls'>
                        <div className={'video-chat__control video-chat__video-control' + (isUserCameraDisabled ? ' disabled' : '')}>
                            <Image className={'video-chat__control_icon video-chat__video-control__icon'} 
                                src={isUserCameraDisabled ? disabledCameraIcon : (isCameraActivated ? cameraIcon : disabledCameraIcon)} />
                        </div>
                        <div className='video-chat__control video-chat__audio-control'>
                            <Image className={'video-chat__control_icon video-chat__audio-control__icon'} 
                                src={isMicrophoneActivated ? microphoneIcon : mutedMicrophoneIcon} />
                        </div>
                        <div className={'video-chat__control video-chat__switch-camera-control' + (isUserCameraDisabled ? ' disabled' : '')}>
                            <Image className={'video-chat__control_icon'} src={switchCameraIcon} />
                        </div>
                        <div className='video-chat__control video-chat__disconnect-control'>
                            <Image className={'video-chat__control_icon'} src={disconnectIcon} />
                        </div>
                    </div>
                </div>
                <div className='video-chat__message-chat'>
                    {isTokenValid && reportId && locationName && <Chat />}
                </div>
            </div>
        </>
    )
}

