import React from "react";

export default function ProgressBar(props) {
    const { completed } = props;

    const fillerStyles = {
        width: `${completed}%`
    }

    return (
        <div className="progress-bar__container">
            <div style={fillerStyles} className="progress-bar__filters">
            <span className="progress-bar__label">{completed >= 25 ? `${Math.round(completed)}%` : ''}</span>
            </div>
        </div>
    );
};