import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { reportAnswers as reportAnswersSelector, updateReportAnswers } from '../../../../../reducers/reportSlice';
import MapComponent from '../../../../common/MapComponent';
import ToggleSwitch from '../../../../common/ToggleSwitch';
import locationOfIncidentMarker from "../../../../../static/images/markers/location-of-incident-big.svg"
import { v4 } from 'uuid';
import { Autocomplete } from '@react-google-maps/api';
import { getFormatedAddress } from '../../../../../helpers/geoHelper';

export default function LocationControl({ currentUserPosition, hideLocationOfIncident, isShortFormSelected }) {
    const [showMap, setShowMap] = useState(false);
    const [autocomplete, setAutocomplete] = useState(null);
    const [isAutocompleteShown, setIsAutocompleteShown] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [answerOffsetTop, setAnswerOffsetTop] = useState();
    const dispatch = useDispatch();
    const reportAnswers = useSelector(reportAnswersSelector);
    const [locationOfIncident, setLocationOfIncident] = useState(reportAnswers?.locationOfIncident || {
        latitude: currentUserPosition?.latitude,
        longitude: currentUserPosition?.longitude,
        isIncluded: !hideLocationOfIncident && !isShortFormSelected
    });

    const onAutocompleteFinished = () => {
        const result = autocomplete.getPlace();
        if (!result) {
            return;
        }

        setLocationOfIncident({
            name: result.formatted_address,
            latitude: result.geometry.location.lat(),
            longitude: result.geometry.location.lng()
        });
        setSearchQuery("");
        setIsAutocompleteShown(false);
    }

    const onAutocompleteInputed = e => {
        setSearchQuery(e.target.value);
        setIsAutocompleteShown(true);
    }

    useEffect(() => {
        if (locationOfIncident && !locationOfIncident.name) {
            getFormatedAddress(locationOfIncident.latitude, locationOfIncident.longitude, address => {
                setLocationOfIncident({
                    ...locationOfIncident,
                    ...address
                });
            });
            return;
        }

        if (!reportAnswers?.locationOfIncident || !reportAnswers?.locationOfIncident.name) {
            dispatch(updateReportAnswers({
                ...reportAnswers,
                locationOfIncident
            }));
        }
    }, [locationOfIncident]);

    useEffect(() => {
        if (isAutocompleteShown) {
            const array = document.getElementsByClassName("pac-container");
            [].forEach.call(array, element => element.classList.add("location-of-incident"));
        }
    }, [isAutocompleteShown])

    useEffect(() => {
        if (!isAutocompleteShown && locationOfIncident?.name) {
            setAnswerOffsetTop(document.getElementById("selected-location__container")?.offsetTop);
        }
    })

    return (
        <div className="location-control" style={isShortFormSelected ? {display: 'none'} : {}}>
            <div className="section__header">location info</div>
            <div className="toggle-switch__section">
                <ToggleSwitch
                    isChecked={reportAnswers?.locationOfIncident?.isIncluded}
                    onChange={value => dispatch(updateReportAnswers({
                        ...reportAnswers,
                        locationOfIncident: { ...reportAnswers?.locationOfIncident, isIncluded: value }
                    }))}
                />
                <div className="text_primary">Location of Incident</div>
            </div>

            {reportAnswers?.locationOfIncident?.isIncluded &&
                <div className="input__container_static" onClick={() => setShowMap(!showMap)}>
                    <div className="location-control__icon" />
                    <div className="text_default">{reportAnswers?.locationOfIncident.name}</div>
                </div>
            }

            {showMap &&
                <div className="location-of-incident__selection">
                    <div className="input__container">
                        <div onClick={() => setShowMap(false)} className="location-of-incident-autocomplete__icon" />
                        <Autocomplete
                            onLoad={value => setAutocomplete(value)}
                            className="location-of-incident-autocomplete"
                            onPlaceChanged={(e) => onAutocompleteFinished(e)}
                        >
                            <input
                                onFocus={() => setIsAutocompleteShown(true)}
                                onInput={e => onAutocompleteInputed(e)}
                                value={searchQuery}
                                placeholder="Neighborhood, city, state or postal code..."
                                className="input"
                            />
                        </Autocomplete>
                        {isAutocompleteShown
                            && <div
                                onClick={() => {
                                    setSearchQuery("");
                                    setIsAutocompleteShown(false)
                                }}
                                className="location-of-incident-autocomplete__icon_close"
                            />}
                    </div>

                    {isAutocompleteShown
                        ? <div className="section__header">Search results</div>
                        : <>
                            <MapComponent
                                markersList={[{
                                    position: {
                                        lat: locationOfIncident.latitude,
                                        lng: locationOfIncident.longitude
                                    },
                                    icon: locationOfIncidentMarker,
                                    key: v4()
                                }]}
                                currentLocation={{
                                    coords: {
                                        latitude: currentUserPosition?.latitude,
                                        longitude: currentUserPosition?.longitude
                                    }
                                }}
                                nextSiblingTop={answerOffsetTop}
                            />

                            <div id="selected-location__container">
                                <div className="text_secondary">location selected</div>
                                <div className="selected-location__answer">
                                    <div className="selected-location__icon" />
                                    <div className="text_primary">{locationOfIncident.name}</div>
                                </div>
                                <div
                                    onClick={() => {
                                        setShowMap(false);
                                        dispatch(updateReportAnswers({
                                            ...reportAnswers,
                                            locationOfIncident: { ...reportAnswers.locationOfIncident, ...locationOfIncident }
                                        }));
                                    }}
                                    className="button text_default">
                                    Apply
                                </div>
                            </div>
                        </>
                    }
                </div>}
        </div >
    )
}