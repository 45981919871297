import { mediaContentTypes } from "../consts";
import exifr from "exifr";

export const formatFileSize = size => {
    const maxCount = 1024;
    if (size < maxCount) {
        return `${~~size} B`;
    }

    const sizeInKb = size / maxCount;
    if (sizeInKb < maxCount) {
        return `${~~sizeInKb} KB`;
    }

    const sizeInMb = sizeInKb / maxCount;
    return `${~~sizeInMb} MB`;
}

export const getMediaContentType = mediaType =>
    mediaType.includes("image")
        ? mediaContentTypes.photo
        : (mediaType.includes("video")
            ? mediaContentTypes.video
            : (mediaType.includes("audio")
                ? mediaContentTypes.audio
                : mediaContentTypes.docs));

export const getGpsDataAsync = async (file) => {
    try {
        const gps = await exifr.gps(file);
        return (gps?.latitude && gps?.longitude) ? { latitude: gps.latitude, longitude: gps.longitude } : null;
    } catch (e) {
        return null;
    }
}

export const getFormData = async (item) => {
    const file = await fetch(item.src)
        .then(r => r.blob())
        .then(blob => new File([blob], "name", { type: blob.type }))
    const formData = new FormData();
    formData.append("AttachedFile1", file);
    return formData;
}

export const storeFile = file => {
    const key = file.name + Date.now();

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        sessionStorage[key] = reader.result;
    }
    return key;
}