import React from 'react'
import { languages } from '../../../consts'
import Header from '../../common/Header'
import { changeLanguage } from '../../../helpers/languageHelper'
import { useLocation, useNavigate } from 'react-router-dom'
import { routes } from '../../../routing/routeConsts'

export default function LanguagesPage() {
    const navigate = useNavigate();
    const location = useLocation();

    const handleLanguageUrlClick = (language) => {
        navigate(location?.state?.referrer?.includes(routes.report) 
        ? location.state.referrer.split('/').length > 3 
            ? location.state.referrer.split('/').slice(0,-1).join('/')+`/${language.name}`
            : location.state.referrer 
        : routes.home);
        changeLanguage(language.code);
    }

    return (
        <>
            <Header title="Switch Language" showBackArrow={true} />

            <div className="container">
                {languages.map(language => (
                    <div
                        className="languages__item"
                        key={language.name}
                        onClick={() => handleLanguageUrlClick(language)}
                    >
                        <img className="languages__item__image" src={language.b64String} alt={language.name} />
                        <div className="languages__item__name">{language.name}</div>
                    </div>
                ))}
            </div>
        </>
    )
}