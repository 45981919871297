import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { reportAnswers as reportAnswersSelector, updateReportAnswers } from '../../../../../reducers/reportSlice';
import { storageHelper } from '../../../../../helpers/storageHelper';
import { phoneNumberRegex } from '../../../../../consts';
import ToggleSwitch from '../../../../common/ToggleSwitch';
import PhoneInputWithCountryCode from '../../../../common/PhoneInputWithCountryCode'

export default function ContactInfoControl({ control, 
                                             shouldStateBeUpdated, 
                                             stateUpdateOptions, 
                                             isContactInfoRequiredByRadioGroupOptions = false,
                                             isShortFormSelected }) {
    const reportAnswers = useSelector(reportAnswersSelector);
    const storedContactInfo = storageHelper.getStoredContactInfo();
    const [isUserContactInfoIncluded, setIsUserContactInfoIncluded] = useState(true);
    const [contactInfo, setContactInfo] = useState(reportAnswers?.contactInfo
        ? reportAnswers?.contactInfo
        : (control.UseStoredContactInfo && storedContactInfo)
            ? { ...storedContactInfo, 
                isIncluded: (control.IsRequired || isContactInfoRequiredByRadioGroupOptions),
                isUserContactInfoIncluded 
            }
            : {
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                isIncluded: (control.IsRequired || isContactInfoRequiredByRadioGroupOptions),
                isUserContactInfoIncluded
            });
    const dispatch = useDispatch();

    useEffect(() => {
        if (control.IsRequired || isContactInfoRequiredByRadioGroupOptions) {
            setContactInfo({ ...contactInfo, isIncluded: true })
        }
    }, [isContactInfoRequiredByRadioGroupOptions])

    useEffect(() => {
        if ((shouldStateBeUpdated && contactInfo.isIncluded) || 
            (contactInfo.isIncluded && isShortFormSelected)) {
            dispatch(updateReportAnswers({
                ...reportAnswers,
                contactInfo
            }));
        }
    }, [shouldStateBeUpdated, stateUpdateOptions, contactInfo])

    return (
        <div className="contact-info-control">
            <div className={isShortFormSelected ? "section__header short-form" : "section__header"}>{(control.IsRequired || isContactInfoRequiredByRadioGroupOptions) ? "Include your contact info (REQUIRED)" : "Include your contact info? (OPTIONAL)"}</div>
            <div className="contact-info-control__selector" style={{display: (control.IsRequired || isContactInfoRequiredByRadioGroupOptions) ? "none" : ""}}>
                <div
                    className={contactInfo.isIncluded
                        ? "contact-info-control__selector__item_active"
                        : "contact-info-control__selector__item"}
                    onClick={() => setContactInfo({ ...contactInfo, isIncluded: true })}>Yes</div>
                <div
                    className={!contactInfo.isIncluded
                        ? "contact-info-control__selector__item_active"
                        : "contact-info-control__selector__item"}
                    onClick={() => (!isContactInfoRequiredByRadioGroupOptions && !control.IsRequired) && setContactInfo({ ...contactInfo, isIncluded: false })}>No</div>
            </div>

            {contactInfo.isIncluded &&
                <>
                    <input
                        id="contact-info-first-name"
                        key="contact-info-first-name"
                        className="input__container input text_default"
                        placeholder="First Name"
                        onChange={e => setContactInfo({ ...contactInfo, firstName: e.target.value })}
                        value={contactInfo.firstName}
                    />
                    <input
                        id="contact-info-last-name"
                        key="contact-info-last-name"
                        className="input__container input text_default"
                        placeholder="Last Name"
                        onChange={e => setContactInfo({ ...contactInfo, lastName: e.target.value })}
                        value={contactInfo.lastName}
                    />
                    <input
                        key="contact-info-email"
                        className="input__container input text_default"
                        placeholder="Email (optional)"
                        onChange={e => setContactInfo({ ...contactInfo, email: e.target.value })}
                        value={contactInfo.email}
                    />
                    <PhoneInputWithCountryCode
                        key="contact-info-phone-number"
                        placeholder="Phone"
                        enableSearch={true}
                        country={"us"}
                        onChange={value => setContactInfo({ ...contactInfo, phoneNumber: `+${value}` })}
                        value={contactInfo.phoneNumber}
                        isValid={(value) => phoneNumberRegex.test(value) ? true : false}
                    />
                </>}
                <div className="toggle-switch__section" style={isShortFormSelected ? {display: 'none'} : {}}>
                    <ToggleSwitch
                        isChecked={isUserContactInfoIncluded}
                        onChange={value => {
                            setIsUserContactInfoIncluded(value);
                            setContactInfo({ ...contactInfo, isUserContactInfoIncluded: value });
                        }}
                    />
                    <div className="text_primary">Save/Update Contact information in settings</div>
                </div>
        </div>
    )
}