import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: {
        totalMediaSending: 0,
        mediaSended: 0,
        isLoading: false,
        isSending: false,
        error: null,
        redirectAfterError: true,
        cancelText: "Close"
    },
    reducers: {
        setTotalMediaSending: (state, action) => {
            state.totalMediaSending = state.error ? 0 : action.payload;
        },
        setMediaSended: (state, action) => {
            state.mediaSended = state.error ? 0 : action.payload;
        },
        changeSendingStatus: (state, action) => {
            state.isSending = state.error ? false : action.payload;
            if (state.isSending === false) {
                state.totalMediaSending = 0;
                state.mediaSended = 0;
            }
        },
        changeLoadingStatus: (state, action) => {
            state.isLoading = state.error ? false : action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
            state.isSending = false;
            state.totalMediaSending = 0;
            state.mediaSended = 0;
        },
        setErrorWithoutRedirect: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
            state.isSending = false;
            state.totalMediaSending = 0;
            state.mediaSended = 0;
            state.redirectAfterError = false;
        },
        toggle: (state, action) => {
            state.isLoading = !state.isLoading;
        }
    }
})

export const {
    setTotalMediaSending,
    setMediaSended,
    changeSendingStatus,
    changeLoadingStatus,
    toggle,
    setError,
    setErrorWithoutRedirect
} = loadingSlice.actions

export default loadingSlice.reducer