import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import TabBar from '../../common/TabBar'
import Header from '../../common/Header'
import { Autocomplete } from '@react-google-maps/api';
import { setSearchAreaCoords } from '../../../reducers/locationSlice';
import { storageHelper } from '../../../helpers/storageHelper';
import { getCurrentPositionAsync, getFormatedAddress } from '../../../helpers/geoHelper';
import { maxStoredSearchies } from '../../../consts';
import { useLocation, useNavigate } from 'react-router-dom';
import { goToPreviousLocation } from '../../../routing/routeConsts';

export default function ChangeAreaPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [autocomplete, setAutocomplete] = useState(null);
    const [isAutocompleteShown, setIsAutocompleteShown] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [previousResults, setPreviousResults] = useState([]);
    const dispatch = useDispatch();

    const updateStoredLocationSearchQueries = placeResult => {
        let queryArray = storageHelper.getStoredLocationSearchQueries();

        if (queryArray.length > maxStoredSearchies) {
            queryArray = queryArray.slice(queryArray.length - 5 + 1, queryArray.length - 1)
        }
        const newItem = {
            date: Date.now(),
            searchQuery: placeResult.formatted_address,
            htmlElement: placeResult.adr_address,
            latitude: placeResult.geometry.location.lat(),
            longitude: placeResult.geometry.location.lng()
        };

        queryArray.push(newItem);
        queryArray = queryArray.sort((a, b) => b.date - a.date);

        storageHelper.setStoredLocationSearchQueries(queryArray);
        setPreviousResults(queryArray);

        dispatch(setSearchAreaCoords({
            latitude: placeResult.geometry.location.lat(),
            longitude: placeResult.geometry.location.lng(),
            name: placeResult.formatted_address
        }));
    }

    const onAutocompleteFinished = () => {
        const result = autocomplete.getPlace();
        if (!result) {
            return;
        }

        updateStoredLocationSearchQueries(result);
        setIsAutocompleteShown(false);
        goToPreviousLocation(navigate, location);
    }

    const onUseCurrentLocationClickAsync = async () => {
        await getCurrentPositionAsync(position => {
            getFormatedAddress(position.coords.latitude, position.coords.longitude,
                address => {
                    dispatch(setSearchAreaCoords(address));
                    setSearchQuery(address.name);
                    goToPreviousLocation(navigate, location)
                });
        });
    }

    useEffect(() => {
        if (isAutocompleteShown) {
            const items = document.getElementsByClassName("pac-container");
            items[items.length - 1].classList.add("change-area");
        }
    }, [isAutocompleteShown])

    useEffect(() => {
        setPreviousResults(storageHelper.getStoredLocationSearchQueries().sort((a, b) => b.date - a.date));
    }, [autocomplete])

    return (
        <>
            <Header title="Change Area" showBackArrow={true} />

            <div className="container">
                <div className="input__container">
                    <div className="input__icon_search"></div>
                    <Autocomplete
                        className="input"
                        onLoad={value => setAutocomplete(value)}
                        onPlaceChanged={(e) => onAutocompleteFinished(e)}
                    >
                        <input
                            onFocus={() => setIsAutocompleteShown(true)}
                            onInput={e => {
                                setSearchQuery(e.target.value);
                                setIsAutocompleteShown(true);
                            }}
                            value={searchQuery}
                            placeholder="Neighborhood, city, state or postal code..."
                            className="input text_default"
                        />
                    </Autocomplete>
                </div>

                {!isAutocompleteShown &&
                    <div className="use-current-location__container" onClick={() => onUseCurrentLocationClickAsync()}>
                        <div className="use-current-location__icon" />
                        <div className="text_default use-current-location__text" >Use My Current Location</div>
                    </div>}

                <div className="section__header">{isAutocompleteShown ? "Search results" : "Used before"}</div>

                {!isAutocompleteShown && (
                    previousResults.map(previousResult => (
                        <div
                            className="previous-results__container"
                            key={previousResult.date}
                            onClick={() => {
                                dispatch(setSearchAreaCoords({
                                    latitude: previousResult.latitude,
                                    longitude: previousResult.longitude, name: previousResult.searchQuery
                                }));
                                setSearchQuery(previousResult.searchQuery);
                                goToPreviousLocation(navigate, location);
                            }}
                        >
                            <div className="previous-results__icon" />
                            <div className="text_default previous-results__text" dangerouslySetInnerHTML={{ __html: previousResult.htmlElement }} />
                        </div>
                    ))
                )}
            </div>

            <TabBar />
        </>
    )
}