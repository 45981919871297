import React from 'react';
import { useDispatch } from 'react-redux';
import humandIdLogo from "../../../static/images/anonymous-login-white-icon.svg";
import { sendRequestAsync } from "../../../api/requestService";
import { httpMethod, humanIdLoginUrl } from "../../../api/apiConsts";
import { changeLoadingStatus } from '../../../reducers/loadingSlice';

export default function HumanIdAuthorizationButton() {
    const dispatch = useDispatch();
    
    const authorizeWithHumanIdAsync = async () => {
        dispatch(changeLoadingStatus(true));
        const response = await sendRequestAsync(httpMethod.get, humanIdLoginUrl);
        window.location = response?.data?.data?.webLoginUrl;
    }

    return (
        <div onClick={() => authorizeWithHumanIdAsync()} className="human-id__button">
            <img src={humandIdLogo} alt="Anonymous Login with humanID" className="human-id__button__icon"/>
            <p className="human-id__button__text">Anonymous Login with humanID</p>
        </div>
    )
}