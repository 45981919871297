import React, { useEffect, useState } from 'react';
import Header from '../../common/Header';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage, clearState, fetchMessagesList, submitAnswer } from '../../../reducers/messageSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { baseUrl, requestStatus, httpMethod, markAnswerAsReadUrl } from '../../../api/apiConsts';
import { sendRequestAsync } from "../../../api/requestService";
import { changeLoadingStatus } from '../../../reducers/loadingSlice';
import Image from '../../common/Image';
import { goToPreviousLocation, routes } from '../../../routing/routeConsts';
import messageAudioNotification from '../../../static/sounds/pop-ding-notification-effect-4.mp3';

let signalRConnection = null;

export default function ChatPage() {
    const credentials = useSelector(state => state.message.credentials);
    const chat = useSelector(state => state.message.chat);
    const fetchStatus = useSelector(state => state.message.status);
    const isLoading = useSelector(state => state.loading.isLoading);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [newAnswer, setNewAnswer] = useState("");

    const onSendClick = () => {
        if (!newAnswer) {
            return;
        }
        dispatch(submitAnswer({ answerText: newAnswer }));
        setNewAnswer("");
    }

    const cleanup = () => {
        signalRConnection?.stop();
        signalRConnection = null;
        dispatch(clearState());
    }

    const onChatClose = async () => {
        goToPreviousLocation(navigate, location);
        await sendRequestAsync(httpMethod.post, markAnswerAsReadUrl, { reportId: chat?.ReportId });
    }

    useEffect(() => {
        if (!credentials) {
            navigate(routes.messages);
            return;
        }
        if (credentials?.number && (!chat || credentials?.number !== `${chat?.ReportId}`)) {
            dispatch(fetchMessagesList(credentials));
        }

        return () => cleanup()
    }, [])

    useEffect(() => {
        if (chat && !signalRConnection && window.$ && window.$.hubConnection) {
            signalRConnection = window.$.hubConnection(baseUrl);
            signalRConnection.qs = { 'id': chat?.ReportId || credentials?.number};

            const reportAnswersHubProxy = signalRConnection.createHubProxy('reportAnswersHub');
            reportAnswersHubProxy.on('addMessage',
                function (answerString) {
                    if (answerString) {
                        dispatch(addMessage(JSON.parse(answerString)))
                        dispatch(fetchMessagesList(credentials))
                        document.getElementById('message-audio-notification').play()
                    }
                });

            signalRConnection?.start();
        }

        if (chat?.Answers?.length > 0) {
            const lastAnswerElement = document.getElementById(`message__item_${chat.Answers[chat.Answers.length - 1].AnswerId}`);
            if (lastAnswerElement) {
                lastAnswerElement.scrollIntoView()
            }
        }
    }, [chat])

    useEffect(() => {
        const isFetching = fetchStatus === requestStatus.loading;
        if (isLoading !== isFetching) {
            dispatch(changeLoadingStatus(isFetching));
        }
    }, [fetchStatus])

    return chat && (
        <>
            <Header
                title={`${chat?.LocationName} ${chat?.LocationAddress}\nreport No. ${chat?.ReportId}`}
                showBackArrow={true}
                showHomeIcon={true}
                onCancel={onChatClose}
            />

            <audio id="message-audio-notification" controls="controls" autostart="false" style={{display: "none"}}>
                <source src={messageAudioNotification} type="audio/mpeg" />
            </audio>

            <div className="container chat__container">
                {chat?.Answers?.length
                    ? chat.Answers.map(answer => (
                        <div className="message__item" id={`message__item_${answer.AnswerId}`} key={answer.AnswerId}>
                            <div className="message__time">{answer.AnsweredAt}</div>
                            <div className={answer.AnswerSource === 0 ? "message__wrapper_location" : "message__wrapper_user"}>
                                {answer.AnswerSource === 0 && chat.LocationLogo
                                    && <Image className="message__location-logo" src={chat.LocationLogo} />}
                                <div className="text_default message__text">
                                    {answer.AnswerText} 
                                    {answer.IsLinkIncluded && <div><a href={answer.Link} className="message__link">{answer.LinkText}</a></div>}
                                </div>
                            </div>
                        </div>
                    ))
                    : <div className="section__header">No messages yet</div>}
            </div>

            {chat?.AllowAnswerFromClient &&
                <div className="input__container chat__input__container">
                    <input
                        value={newAnswer}
                        onInput={e => setNewAnswer(e.target.value)}
                        className="text_default chat__input"
                        placeholder="Your reply..."
                    />
                    <div className="chat__button" onClick={() => onSendClick()}>Send</div>
                </div>
            }
        </>
    )
}