import React, { useEffect, useState } from 'react';
import { fetchUserReportsByHumanId, clearState } from '../../../reducers/messageSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { requestStatus, baseUrl } from '../../../api/apiConsts';
import { changeLoadingStatus } from '../../../reducers/loadingSlice';
import { routes } from '../../../routing/routeConsts';
import ChatListItem from '../ChatListItem';
import messageAudioNotification from '../../../static/sounds/pop-ding-notification-effect-4.mp3';

let signalRConnection = null;

export default function ChatList({ humanId }) {
    const dispatch = useDispatch();
    const chat = useSelector(state => state.message.chat);
    const fetchStatus = useSelector(state => state.message.status);
    const isLoading = useSelector(state => state.loading.isLoading);
    const [isArchivedReportsSelected, setIsArchivedReportsSelected] = useState(false);
    const navigate = useNavigate();

    const cleanup = () => {
        signalRConnection?.stop();
        signalRConnection = null;
        dispatch(clearState());
    }

    useEffect(() => {
        if (!humanId) {
            navigate(routes.messages);
            return;
        }
        dispatch(fetchUserReportsByHumanId(humanId));

        return () => cleanup()
    }, [])

    useEffect(() => {
        if (chat && !signalRConnection && window.$ && window.$.hubConnection) {
            signalRConnection = window.$.hubConnection(baseUrl);
            signalRConnection.qs = {
                'id': humanId
            };

            const reportAnswersHubProxy = signalRConnection.createHubProxy('reportAnswersHub');
            reportAnswersHubProxy.on('addMessageByHumanId',
                function (answerString) {
                    if (answerString) {
                        dispatch(fetchUserReportsByHumanId(humanId))
                        document.getElementById('message-audio-notification').play()
                    }
                });

            signalRConnection?.start();
        }
    }, [chat])

    useEffect(() => {
        const isFetching = fetchStatus === requestStatus.loading;
        if (isLoading !== isFetching) {
            dispatch(changeLoadingStatus(isFetching));
        }
    }, [fetchStatus])

    return chat && (
        <>
            <audio id="message-audio-notification" controls="controls" autostart="false" style={{display: "none"}}>
                <source src={messageAudioNotification} type="audio/mpeg" />
            </audio>

            <div className="chat-list">
                <div className="chat-list-control__selector">
                    <div
                        className={isArchivedReportsSelected
                            ? "chat-list-control__selector__item_active"
                            : "chat-list-control__selector__item"}
                        onClick={() => setIsArchivedReportsSelected(false)}>Current</div>
                    <div
                        className={!isArchivedReportsSelected
                            ? "chat-list-control__selector__item_active"
                            : "chat-list-control__selector__item"}
                        onClick={() => setIsArchivedReportsSelected(true)}>Archived</div>
                </div>
                <div className="chat-list__container text_primary">
                    <div>
                        {chat?.Reports?.length > 0
                            ? chat?.Reports?.map(report => 
                                    (isArchivedReportsSelected ? report.IsArchivedByUser : !report.IsArchivedByUser) 
                                        && <ChatListItem report={report} isArchivedReportsSelected={isArchivedReportsSelected} humanId={humanId} />
                                )
                            : <div className="text_primary">Reports sent from your device are not found</div> 
                        }
                    </div>
                </div>
            </div>
        </>
    )
}