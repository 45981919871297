import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { sendRequestAsync } from "../api/requestService"
import { httpMethod, getCategoryListUrl, requestStatus } from "../api/apiConsts"

export const fetchCategoryList = createAsyncThunk("categories/fetchCategoryList", async coords => {
    const url = `${getCategoryListUrl}?Latitude=${coords.latitude}&Longitude=${coords.longitude}`;
    const response = await sendRequestAsync(httpMethod.get, url);
    return response.data;
})

export const categorySlice = createSlice({
    name: "categories",
    initialState: {
        list: null,
        status: requestStatus.idle,
        enableGooglePlacesCategory: false,
    },
    reducers: {
        clearState: (state, action) => {
            state.list = null;
            state.status = requestStatus.idle;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchCategoryList.fulfilled, (state, action) => {
            state.list = action.payload?.categories;
            state.enableGooglePlacesCategory = action.payload?.enableGooglePlacesCategory;
            state.status = requestStatus.succeeded;
        })
        builder.addCase(fetchCategoryList.pending, (state, action) => {
            state.status = requestStatus.loading
        })
        builder.addCase(fetchCategoryList.rejected, (state, action) => {
            state.status = requestStatus.failed;
            state.list = [];
        })
    }
})

export const { clearState } = categorySlice.actions;

export default categorySlice.reducer;