import React, { useState, useEffect } from "react";
import DefaultLocationLogo from "../../../../static/images/report-icon.svg";
import Image from "../../../common/Image";
import StarLinear from "../../../../static/images/star-linear.png";
import StarGrey from "../../../../static/images/star-grey.svg";
import Modal from '../../../common/modals/Modal';
import { addLocationToFavoritesAsync } from "../../../../reducers/locationSlice";
import { removeLocationFromFavoritesAsync } from "../../../../reducers/locationSlice";
import { authHelper } from "../../../../api/authHelper";

export default function LocationItem({
    location,
    showDistance = true,
    showLink = false,
    onReportLinkClick,
    onClick,
    isFavoriteLocation,
    onFavoriteLocationUpdate
}) {
    const [showWarning, setShowWarning] = useState(false);
    const userHumanId = authHelper.getUserHumanId();

    return (
        <>
            {showWarning &&
                <Modal
                    onCancelClick={() => {
                        setShowWarning(false);
                    }}
                    onNextClick={async () => {
                        isFavoriteLocation
                            ? await removeLocationFromFavoritesAsync([`${location.Code}`])
                            : await addLocationToFavoritesAsync([`${location.Code}`]);
                        onFavoriteLocationUpdate();
                        setShowWarning(false);
                    }}
                    text={isFavoriteLocation ? "Remove location from favorites?" : "Add location to favorites?"}
                    cancelText="Cancel"
                    nextText="Okay"
                />}

            <div className="location-item" onClick={() => onClick()}>
                <Image
                    src={location.LocationLogoPath || DefaultLocationLogo}
                    className="location-item__icon"
                    defaultSrc={DefaultLocationLogo}
                />
                <div className="location-item__text">
                    <div className="text_default">{location.Name}</div>
                    <div className="text_secondary">{location.Address}</div>
                </div>
                {showDistance &&
                    <div className="location-item__distance">{location.DistanceFromUserInMiles} mi away</div>
                }
                {userHumanId && 
                    <Image src={isFavoriteLocation ? StarLinear : StarGrey} className="location-item__favorite-icon" onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setShowWarning(true);
                    }}
                />}
                {showLink &&
                    <div
                        className="text_secondary link"
                        onClick={() => onReportLinkClick && onReportLinkClick()}>
                        {location.IsNonReportingLocation ? "Contact details" : "Report It Now"}
                    </div>
                }
            </div>
        </>
    )
}