import { configureStore } from '@reduxjs/toolkit'
import categoryReducer from './reducers/categorySlice'
import locationReducer from './reducers/locationSlice'
import reportReducer from './reducers/reportSlice';
import loadingReducer from './reducers/loadingSlice';
import messageReducer from './reducers/messageSlice';

export default configureStore({
    reducer: {
        category: categoryReducer,
        location: locationReducer,
        report: reportReducer,
        loading: loadingReducer,
        message: messageReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.REACT_APP_PRODUCTION === "false"
});