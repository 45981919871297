import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { defaultRating, ratingItems } from '../../../../../consts';
import { reportAnswers as reportAnswersSelector, updateReportAnswers } from '../../../../../reducers/reportSlice';

export default function RatingControl() {
    const reportAnswers = useSelector(reportAnswersSelector);
    const dispatch = useDispatch();
    const ratingClassName = (!reportAnswers?.rating
        ? 'rating-bar_empty'
        : reportAnswers?.rating < 5
            ? "rating-bar_small"
            : (reportAnswers?.rating < 8
                ? "rating-bar_middle"
                : "rating-bar_high"));
    const ratingText = (!reportAnswers?.rating
        ? ''
        : reportAnswers?.rating < 5
            ? "Not likely: "
            : (reportAnswers?.rating < 8
                ? "Medium: "
                : "Very likely: "));

    useEffect(() => {
        if (!reportAnswers?.rating) {
            dispatch(updateReportAnswers({ ...reportAnswers, rating: defaultRating }))
        }
    }, [])

    return (
        <div className="rating-control">
            <div className="section__header">How likely are you to recommend us?</div>
            {ratingText && <div className="text_default_gray">{ratingText}{reportAnswers?.rating}</div>}
            <div className="rating-bar">
                {ratingItems.map(item => (
                    <div
                        key={"rating-" + item}
                        className={item <= reportAnswers?.rating ? ratingClassName : 'rating-bar_empty'}
                        onClick={() => dispatch(updateReportAnswers({ ...reportAnswers, rating: item }))}>
                        {item === reportAnswers?.rating && <div className="rating-bar__point">
                            <div className="rating-bar__point__icon" />
                            <div className="rating-bar__count">{reportAnswers?.rating}</div>
                        </div>}
                    </div>
                ))}
            </div>
        </div>
    )
}