import { monthShortenings } from "../consts"
import moment from 'moment-timezone'

export const getFormatedDate = (year, month, day) => {
    return `${monthShortenings[month - 1]} ${day}, ${year}`;
}
export const getFormatedTime = (hour, minute, isBeforeNoon) => {
    return `${hour % 12 || 12} : ${minute < 10 ? `0${minute}` : minute} ${isBeforeNoon ? 'AM' : 'PM'}`;
}
export const getFormatedDateTimeString = date => {
    date = new Date(moment(date).tz("America/New_York").format("YYYY-MM-DDTHH:mm:ss.sss"));
    const today = new Date(moment().tz("America/New_York").format("YYYY-MM-DDTHH:mm:ss.sss"));

    let yesterday = new Date(moment().tz("America/New_York").format("YYYY-MM-DDTHH:mm:ss.sss"));
    yesterday.setDate(today.getDate() - 1);

    const isToday = date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();

    const isYesterday = date.getDate() === yesterday.getDate() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getFullYear() === yesterday.getFullYear();
    return `${isToday ? 'today' : (isYesterday ? 'yesterday' : getFormatedDate(date.getFullYear(), date.getMonth() + 1, date.getDate()))} at ${getFormatedTime(date.getHours(), date.getMinutes(), date.getHours() < 12 || date.getHours() > 23)}`;
}

export const formatTimeFromSeconds = (time, includeHours = false) => {
    return new Date(time * 1000).toISOString().substr(includeHours ? 11 : 14, includeHours ? 8 : 5);
}