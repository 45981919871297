import { LoadScript } from "@react-google-maps/api";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Loader from "./components/common/Loader";
import { googleMapsApiKey } from "./consts";
import "./scss/index.scss"
import AppRouter from "./routing/AppRouter";
import store from "./store";
import { loadGoogleCaptchaScript, loadGoogleAnalyticsScript} from "./helpers/scriptHelper";

loadGoogleCaptchaScript();
loadGoogleAnalyticsScript();

ReactDOM.render(
  <Provider store={store}>
    <Loader />
    <LoadScript googleMapsApiKey={googleMapsApiKey} loadingElement={null} libraries={["places"]}>
      <AppRouter />
    </LoadScript>
  </Provider>,
  document.getElementById('root')
);