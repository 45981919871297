import React from 'react'
import DefaultLocationLogo from "../../../../static/images/report-icon.svg"
import Image from "../../../common/Image"

export default function LocationInfo({
    location,
    onMapLinkClick,
    showMapLink = true,
    locationAddress
}) {
    return (
        <div id="location-info" className="location-info">
            <Image
                className={showMapLink ? "location-info__icon" : "location-info__icon_big"}
                src={location.LocationLogoPath || DefaultLocationLogo}
                defaultSrc={DefaultLocationLogo}
            />
            <div>
                <div className="text_primary">{location.Name}</div>
                <div className="text_secondary">{location.Address}</div>

                {locationAddress && !showMapLink &&
                    <div className="text_default">{locationAddress.name}</div>
                }
            </div>

            {showMapLink &&
                <div className="location-info__icon_info" onClick={() => onMapLinkClick()} />
            }
        </div>
    )
}