import React from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../../../routing/routeConsts'

export default function Include911Popup({ onStartClick }) {
    return (
        <>
            <div className="include-911-popup__title text_bold">Choose an option below</div>
            <div className="include-911-popup__link_report" onClick={() => onStartClick()}>Start Reporting</div>
            <Link to={routes.callEmergency} className="include-911-popup__link_call-911">Call 911</Link>
        </>
    )
}