export const routes = {
    home: "/",
    locations: "/locations",
    search: "/search",
    chat: "/chat",
    settings: "/settings",
    termsOfUse: "/termsOfUse",
    aboutUs: "/aboutUs",
    aboutHumanId: "/aboutHumanId",
    messages: "/messages",
    messagesAlert: "/messagesAlert",
    callEmergency: "/callEmergency",
    accept: "/accept",
    languages: '/languages',
    options: "/options",
    changeArea: "/changeArea",
    reportSucceeded: "/reportSucceeded",
    report: "/report",
    category: "/category",
    login: "/login",
    favoriteLocations: "/favoriteLocations",
    goLiveChat: "/goLiveChat"
}

export const goToPreviousLocation = (navigate, location, addReferrer, path) => {
    window.history.state && window.history.state.idx > 0
        ? (addReferrer && path ? navigate(path) : navigate(-1)) // navigate(path, { referrer: location.pathname })
        : navigate(routes.home);
}