import React from 'react'
import TabBar from '../../common/TabBar'
import Header from '../../common/Header';
import AboutHumanIdText from '../../common/AboutHumanIdText';

export default function AboutHumanIdPage() {

    return (
        <div className="tab-bar-predecessor">
            <Header title="About humanID" showBackArrow={true}/>
            <AboutHumanIdText/>
            <TabBar />
        </div>
    )
}