import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceSettingsAsync, handleUserCaptchaTokenAsync } from '../../../../api/requestService';
import { submitReport as submitReportThunk, updateReportWarning, reportAnswers as reportAnswersSelector } from "../../../../reducers/reportSlice"
import { changeLoadingStatus, changeSendingStatus, setError, setTotalMediaSending, setMediaSended } from '../../../../reducers/loadingSlice';
import { controlTypes } from '../../../../consts';
import RadioGroupControl from '../controls/RadioGroupControl';
import TextAreaControl from '../controls/TextAreaControl';
import MediaControl from '../controls/MediaControl';
import RatingControl from '../controls/RatingControl';
import DateTimeControl from '../controls/DateTimeControl';
import LocationControl from '../controls/LocationControl';
import ContactInfoControl from '../controls/ContactInfoControl';
import SubLocationControl from '../controls/SubLocationControl';
import { getLanguage } from '../../../../helpers/languageHelper';
import Modal from '../../../common/modals/Modal';
import StepsNavigation from '../StepsNavigation';
import { routes } from '../../../../routing/routeConsts';
import { getFormData } from '../../../../helpers/fileHelper';
import { storageHelper } from '../../../../helpers/storageHelper';
import { urlHelper } from '../../../../helpers/urlHelper';
import { phoneNumberRegex } from '../../../../consts';

export default function Report({
    steps,
    currentStep,
    report,
    currentUserPosition,
    onStepsChange,
    onCurrentStepChange
}) {
    const dispatch = useDispatch();
    const { locationCode } = useParams();
    const warning = useSelector(state => state.report.reportWarning);
    const navigate = useNavigate();
    const reportAnswers = useSelector(reportAnswersSelector) || [];
    const [stateUpdateOptions, setStateUpdateOptions] = useState(null);
    let mediaData = null;

    const tabCloseWarning = (e) => {
        e.preventDefault();
        e.returnValue = 'The report is still being sent. Are you sure you want to quit?';
    }

    const validateStep = (isNext, isSubmit) => {
        if (!steps?.length) {
            return
        }

        const step = steps[currentStep - 1];

        if (isNext || isSubmit) {
            const invalidControls = step.controls.filter(x => !x.validation.method(reportAnswers));
            if (invalidControls?.length) {
                dispatch(updateReportWarning(invalidControls.map(x => x.validation.message).join("\n")));
                return false;
            }
        }

        let stepsCopy = steps;
        stepsCopy.splice(step.number - 1, 1, { ...step, isFinished: true });
        onStepsChange(stepsCopy);
        return true;
    }

    const getAnswersAsync = async () => {
        return Promise.all(report.Form.Controls.map(async (formControl) => {
            if (formControl.Type === controlTypes.radioGroup) {
                return {
                    controlId: formControl.Id,
                    answer: reportAnswers[formControl.Id],
                    type: formControl.Type
                };
            }

            if (formControl.Type === controlTypes.textArea) {
                return {
                    controlId: formControl.Id,
                    answer: reportAnswers.comment,
                    type: formControl.Type
                };
            }

            if (formControl.Type === controlTypes.rating) {
                return {
                    controlId: formControl.Id,
                    answer: reportAnswers.rating,
                    type: formControl.Type
                };
            }

            if (formControl.Type === controlTypes.dateTime) {
                return {
                    controlId: formControl.Id,
                    answer: reportAnswers.dateTime?.isIncluded
                        ? new Date(
                            reportAnswers.dateTime.date.year,
                            reportAnswers.dateTime.date.month - 1,
                            reportAnswers.dateTime.date.day,
                            reportAnswers.dateTime.time.isBeforeNoon
                                ? reportAnswers.dateTime.time.hour
                                : reportAnswers.dateTime.time.hour + 12,
                            reportAnswers.dateTime.time.minute)
                        : null,
                    type: formControl.Type
                };
            }

            if (formControl.Type === controlTypes.location) {
                return {
                    controlId: formControl.Id,
                    answer: reportAnswers.locationOfIncident?.isIncluded
                        ? {
                            address: reportAnswers.locationOfIncident.name,
                            userLatitude: reportAnswers.locationOfIncident.latitude,
                            userLongitude: reportAnswers.locationOfIncident.longitude
                        }
                        : (stateUpdateOptions?.isPanic && !report.Form.HideLocationOfIncident)
                            ? {
                                address: currentUserPosition?.name,
                                userLatitude: currentUserPosition?.latitude,
                                userLongitude: currentUserPosition?.longitude
                            }
                            : null,
                    type: formControl.Type
                };
            }

            if (formControl.Type === controlTypes.media) {
                if (reportAnswers.files?.length) {
                    mediaData = await Promise.all(reportAnswers.files.map(async (file) => {
                        return {
                            formData: await getFormData(file),
                            extension: file.extension
                        }
                    }))
                }

                return {
                    controlId: formControl.Id,
                    answer: mediaData
                        ? reportAnswers.files.map(file => ({
                            mimeType: file.mimeType,
                            type: file.type,
                            latitude: file.gps?.latitude,
                            longitude: file.gps?.longitude,
                            size: file.fileSize
                        }))
                        : null,
                    type: formControl.Type
                };
            }

            if (formControl.Type === controlTypes.contactInfo) {
                const storedContactInfo = storageHelper.getStoredContactInfo();

                return {
                    controlId: formControl.Id,
                    answer: reportAnswers.contactInfo?.isIncluded && (reportAnswers.contactInfo?.firstName || 
                            reportAnswers.contactInfo?.lastName || 
                            reportAnswers.contactInfo?.email || reportAnswers.contactInfo?.phoneNumber)
                    ? (reportAnswers.contactInfo)
                    : ((stateUpdateOptions?.isPanic && formControl.UseStoredContactInfo) 
                        ? (storedContactInfo?.firstName || storedContactInfo?.lastName || 
                           storedContactInfo?.email || storedContactInfo?.phoneNumber)
                            ? storedContactInfo
                            : null
                        : null),
                    type: formControl.Type
                };
            }
        }));
    }

    const submitReportAsync = async (isUserPassed) => {
        if (isUserPassed) {
            if (reportAnswers.contactInfo && reportAnswers.contactInfo?.isUserContactInfoIncluded) {
                storageHelper.setStoredContactInfo({ 
                    firstName: (reportAnswers?.contactInfo?.firstName || ""), 
                    lastName: (reportAnswers?.contactInfo?.lastName || ""), 
                    email: (reportAnswers?.contactInfo?.email || ""), 
                    phoneNumber: (reportAnswers?.contactInfo?.phoneNumber || "") });
            }
            
            await getAnswersAsync()
            .then(async (answers) => {
                const response = await getDeviceSettingsAsync();
                const data = {
                    answers,
                    locationCode,
                    language: getLanguage().name,
                    selectedSubLocation: reportAnswers.subLocation?.Id,
                    formId: report.Form.Id,
                    contactInfoFirstName: reportAnswers.contactInfo?.firstName,
                    contactInfoLastName: reportAnswers.contactInfo?.lastName,
                    contactInfoEmail: reportAnswers.contactInfo?.email,
                    contactInfoPhone: reportAnswers.contactInfo?.phoneNumber,
                    isPanicAlarmIncluded: stateUpdateOptions?.isPanic,
                    userLatitude: (response?.AllowLocationTracking && report?.Form?.CaptureLocationData) 
                        ? currentUserPosition?.latitude 
                        : null,
                    userLongitude: (response?.AllowLocationTracking && report?.Form?.CaptureLocationData) 
                        ? currentUserPosition?.longitude 
                        : null,
                    userAddress: (response?.AllowLocationTracking && report?.Form?.CaptureLocationData) 
                        ? currentUserPosition?.name 
                        : null
                };
                return dispatch(submitReportThunk({ data, mediaData }));
            }).then(() => {
                storageHelper.setTranslation(false);
                dispatch(changeSendingStatus(false));
                window.removeEventListener('beforeunload', tabCloseWarning);
                navigate(routes.reportSucceeded);
            });
        } 
        if (isUserPassed === false) {
            dispatch(setError("Google reCaptcha check failed, no report has been sent."));
        }
    }

    useEffect(() => {
        document.onvisibilitychange = function () {
            urlHelper.redirectOnScreenLeft();
        };

        window.onpagehide = function () {
            urlHelper.redirectOnScreenLeft();
        }

        return () => {
            document.onvisibilitychange = null;
            window.onpagehide = null;
            storageHelper.removeMediaUploadStatus();
        }
    }, [])

    useEffect(() => {
        dispatch(changeLoadingStatus(true));
        if (!report) {
            return;
        }

        let stepsList = [];
        let stepNumber = 0;

        report.Form.Controls
            .filter(control => control.Type === controlTypes.radioGroup)
            .sort((a, b) => a.Order - b.Order)
            .forEach(control => {
                stepsList.push({
                    number: ++stepNumber,
                    controls: [{
                        Component: RadioGroupControl,
                        validation: {
                            method: reportAnswers => !control.IsRequired 
                                    || (reportAnswers && reportAnswers[control.Id]),
                            message: "Question is required. Please answer before moving forward"
                        },
                        props: {
                            control,
                            goToNextStep: () => setStateUpdateOptions({ isNext: true })
                        }
                    }],
                    isFinished: !control.IsRequired
                });
            });

        const feedbackControls = report.Form.Controls
            .filter(control => control.Type === controlTypes.textArea
                || control.Type === controlTypes.media
                || control.Type === controlTypes.rating);
        if (feedbackControls?.length) {
            stepsList.push({
                number: ++stepNumber,
                controls: feedbackControls.map(control => {
                    if (control.Type === controlTypes.textArea) {
                        return {
                            Component: TextAreaControl,
                            validation: {
                                method: reportAnswers => !control.IsRequired || reportAnswers?.comment,
                                message: "COMMENTS AND DETAILS is a required section. Please answer before moving forward"
                            },
                            props: {
                                control
                            }
                        }
                    }

                    if (control.Type === controlTypes.media) {
                        return {
                            Component: MediaControl,
                            validation: {
                                method: reportAnswers => !control.IsRequired || reportAnswers?.files?.length,
                                message: "Attachment is required. Please attach file before moving forward"
                            },
                            props: {
                                control
                            }
                        }
                    }

                    if (control.Type === controlTypes.rating) {
                        return {
                            Component: RatingControl,
                            validation: {
                                method: reportAnswers => !control.IsRequired || reportAnswers?.rating,
                                message: "Please leave your rating before moving forward"
                            },
                            props: {
                                control
                            }
                        }
                    }
                }),
                isFinished: !report.Form.IsRatingRequired
            });
        }
        let locationControls = report.Form.Controls
            .filter(control => control.Type === controlTypes.dateTime
                || control.Type === controlTypes.location);
        if (report.SubLocation) {
            locationControls.push({
                ...report.SubLocation,
                Type: controlTypes.subLocation
            });
        }
        if (locationControls?.length) {
            stepsList.push({
                number: ++stepNumber,
                controls: locationControls.map(control => {
                    if (control.Type === controlTypes.dateTime) {
                        return {
                            Component: DateTimeControl,
                            validation: {
                                method: reportAnswers => !control.IsRequired || reportAnswers?.dateTime,
                                message: "DATE & TIME OF INCIDENT is a required section. Please answer before moving forward"
                            },
                            props: {}
                        }
                    }

                    if (control.Type === controlTypes.location) {
                        return {
                            Component: LocationControl,
                            validation: {
                                method: reportAnswers => !control.IsRequired || reportAnswers?.locationOfIncident,
                                message: "LOCATION OF INCIDENT is a required section. Please answer before moving forward"
                            },
                            props: {
                                currentUserPosition,
                                hideLocationOfIncident: report.Form.HideLocationOfIncident
                            }
                        }
                    }

                    if (control.Type === controlTypes.subLocation) {
                        return {
                            Component: SubLocationControl,
                            validation: {
                                method: reportAnswers => !control.IsRequired || reportAnswers?.subLocation,
                                message: `${control.Question.toUpperCase()} is a required section. Please answer before moving forward`
                            },
                            props: {
                                control
                            }
                        }
                    }
                }),
                isFinished: report?.SubLocation?.IsRequired ? false : true
            });
        }

        const contactInfoControl = report.Form.Controls
            .filter(control => control.Type === controlTypes.contactInfo)?.[0];
        if (contactInfoControl) {
            stepsList.push({
                number: ++stepNumber,
                controls: [
                    {
                        Component: ContactInfoControl,
                        validation: {
                            method: reportAnswers => !report.Form.IsContactInfoRequired ||
                                (reportAnswers?.contactInfo
                                    && (reportAnswers.contactInfo?.firstName 
                                        && reportAnswers.contactInfo?.lastName
                                        && reportAnswers.contactInfo?.phoneNumber
                                        && phoneNumberRegex.test(reportAnswers.contactInfo?.phoneNumber.replace('+', '')))),
                            message: "CONTACT INFO is a required section. Please fill in all fields before moving forward"
                        },
                        props: {
                            control: contactInfoControl
                        }
                    }],
                isFinished: !report.Form.IsContactInfoRequired
            });
        }

        onStepsChange(stepsList);
        dispatch(changeLoadingStatus(false));
    }, [report]);

    useEffect(() => {
        if (stateUpdateOptions) {
            const step = steps[currentStep - 1];

            if (stateUpdateOptions.isNext && step.number === steps.length) {
                setStateUpdateOptions({ isSubmit: true, isPanic: stateUpdateOptions.isPanic });
                return;
            }
            if (!validateStep(stateUpdateOptions.isNext, stateUpdateOptions.isSubmit)) {
                return;
            }
            if (stateUpdateOptions.isSubmit) {
                dispatch(changeSendingStatus(true));
                window.addEventListener('beforeunload', tabCloseWarning);
                dispatch(changeLoadingStatus(true));
                if (!report?.DisableGoogleCaptcha && window?.grecaptcha != null) {
                    window.grecaptcha.ready(async () => {
                        await window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_CAPTCHA_CLIENT_ACCESS_KEY, {action: 'submit'})
                        .then(async (token) => {
                            const isPassed = await handleUserCaptchaTokenAsync(token, report?.Code);
                            await submitReportAsync(isPassed);
                        });
                    });
                } else {
                    submitReportAsync(true);
                }
            } else {
                if (stateUpdateOptions.isPanic) {
                    const notFinishedStep = steps.find(step => !step.isFinished);

                    if (notFinishedStep) {
                        const contactInfoControl = notFinishedStep.controls.find(control => control?.props?.control?.Type === controlTypes.contactInfo)?.props?.control;
                        const storedContactInfo = storageHelper.getStoredContactInfo();

                        if (contactInfoControl?.UseStoredContactInfo && (storedContactInfo?.firstName 
                            && storedContactInfo?.lastName && storedContactInfo?.phoneNumber)) {
                            setStateUpdateOptions({ isSubmit: true, isPanic: true });
                            return;
                        }

                        onCurrentStepChange(notFinishedStep.number);
                    } else {
                        setStateUpdateOptions({ isSubmit: true, isPanic: true });
                        return;
                    }
                } else {
                    if (stateUpdateOptions.isNext) {
                        onCurrentStepChange(currentStep + 1);
                    }
                    if (!stateUpdateOptions.isNext && step.number > 1) {
                        onCurrentStepChange(currentStep - 1);
                    }
                }
            }

            setStateUpdateOptions(null);
        }
    }, [stateUpdateOptions])

    useEffect(() => {
        const finishedRadioGroupSteps = steps?.filter(step => step?.isFinished && step?.controls?.find(control => control?.props?.control?.Type === controlTypes.radioGroup));
        const contactInfoStep = steps?.find(step => step?.controls?.find(control => control?.props?.control?.Type === controlTypes.contactInfo));

        const firstRadioGroupControlId = finishedRadioGroupSteps[0]?.controls[0]?.props?.control?.Id;
        const secondRadioGroupControlId = finishedRadioGroupSteps[1]?.controls[0]?.props?.control?.Id;

        const firstRadioGroupControlResponseId = reportAnswers[`${firstRadioGroupControlId}`];
        const secondRadioGroupControlResponseId = reportAnswers[`${secondRadioGroupControlId}`];

        const firstRadioGroupSelectedOption = finishedRadioGroupSteps[0]?.controls[0]?.props?.control?.Options
                                            ?.find(option => option.Id === firstRadioGroupControlResponseId);
        const secondRadioGroupSelectedOption = finishedRadioGroupSteps[1]?.controls[0]?.props?.control.Options
                                            ?.find(option => option.Id === secondRadioGroupControlResponseId);

        const isContactInfoRequiredByRadioGroupOptions = !!firstRadioGroupSelectedOption?.IsContactRequired || 
                                                         !!secondRadioGroupSelectedOption?.IsContactRequired;

        if (finishedRadioGroupSteps?.length > 1) {
            const contactInfoControl = report.Form.Controls
                                            .filter(control => control.Type === controlTypes.contactInfo)?.[0];
            let stepsList = steps;

            stepsList[contactInfoStep?.number - 1] = {
                number: contactInfoStep?.number,
                controls: [
                    {
                        Component: ContactInfoControl,
                        validation: {
                            method: reportAnswers => (!isContactInfoRequiredByRadioGroupOptions &&
                                                    !report.Form.IsContactInfoRequired) ||
                                (reportAnswers?.contactInfo
                                    && (reportAnswers.contactInfo?.firstName 
                                        && reportAnswers.contactInfo?.lastName
                                        && reportAnswers.contactInfo?.phoneNumber
                                        && phoneNumberRegex.test(reportAnswers.contactInfo?.phoneNumber.replace('+', '')))),
                            message: "CONTACT INFO is a required section. Please fill in all fields before moving forward"
                        },
                        props: {
                            control: contactInfoControl,
                            isContactInfoRequiredByRadioGroupOptions: isContactInfoRequiredByRadioGroupOptions
                        }
                    }],
                isFinished: !isContactInfoRequiredByRadioGroupOptions && !report.Form.IsContactInfoRequired
            };

            onStepsChange(stepsList);
        }
    }, [report, reportAnswers, stateUpdateOptions])

    return (
        <>
            {warning &&
                <Modal text={warning} onCancelClick={() => dispatch(updateReportWarning(null))} />
            }

            {steps.map(step => step.number === currentStep &&
                <>
                    {(!report?.DisableGoogleCaptcha &&
                     ((step.number === steps.length) || (step.number === 1 && report.Form.IsPanicAlarmEnabled))) && 
                        (<div className="google-captcha__container">
                            This location is protected by reCAPTCHA and the Google 
                            <a className="google-captcha__link" href="https://policies.google.com/privacy"> Privacy Policy</a> and 
                            <a className="google-captcha__link" href="https://policies.google.com/terms"> Terms of Service</a> apply.
                        </div>)}
                        
                    <div>
                        {step.controls.map(control => (
                            <control.Component
                                {...control.props}
                                shouldStateBeUpdated={!!stateUpdateOptions}
                                stateUpdateOptions={stateUpdateOptions}
                            />
                        ))}
                    </div>
                    <StepsNavigation
                        step={step}
                        max={steps.length}
                        onStepChange={(isNext, isPanic) => setStateUpdateOptions({ step, isNext, isPanic })}
                        isPanicAlarmEnabled={report.Form.IsPanicAlarmEnabled}
                    />
                </>
            )}
        </>
    )
}