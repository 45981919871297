import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { sendRequestAsync } from "../api/requestService"
import { httpMethod, 
         getReportUrl, 
         requestStatus, 
         submitReportUrl, 
         uploadMediaUrl, 
         getGooglePlaceDetailsUrl } from "../api/apiConsts"
import { addLocationToFavoritesAsync } from "./locationSlice";
import { setTotalMediaSending, setMediaSended } from './loadingSlice';
import store from '../store';

const uploadMediaAnswers = async (response, mediaData) => {
    let mediaSended = 0;
    store.dispatch(setTotalMediaSending(response.data.MediaAnswers.length));
    await Promise.allSettled(response.data.MediaAnswers.map(async (answer, index) => {
            const url = `${uploadMediaUrl}?fileExtension=${mediaData[index].extension}&mediaId=${answer.Id}`;
            await sendRequestAsync(httpMethod.post, url, mediaData[index].formData);
            mediaSended += 1;
            store.dispatch(setMediaSended(mediaSended));
    }));
}

export const fetchReport = createAsyncThunk("reports/fetchReport", async ({ coords, code }) => {
    const url = `${getReportUrl}?code=${code}&${coords ? "latitude=" + coords.latitude + "&longitude=" + coords.longitude : ""}`;
    const response = await sendRequestAsync(httpMethod.get, url);
    return response.data;
})

export const fetchGooglePlaceDetails = createAsyncThunk("reports/fetchGooglePlaceDetails", async (placeId) => {
    const url = `${getGooglePlaceDetailsUrl}?placeId=${placeId}`;
    const response = await sendRequestAsync(httpMethod.get, url);
    return response.data;
})

export const submitReport = createAsyncThunk("reports/submitReport", async ({ data, mediaData }) => {
    const response = await sendRequestAsync(httpMethod.post, submitReportUrl, data);
    await addLocationToFavoritesAsync([`${data.locationCode}`]);
    await uploadMediaAnswers(response, mediaData);
    return response.data;
})

export const reportSlice = createSlice({
    name: "reports",
    initialState: {
        report: null,
        status: requestStatus.idle,
        succeededResponse: null,
        reportAnswers: null,
        reportWarning: null,
        googlePlaceDetails: null
    },
    reducers: {
        clearState: (state, action) => {
            state.report = null;
            state.status = requestStatus.idle;
            state.reportWarning = null;
            state.reportAnswers = null;
            state.googlePlaceDetails = null;
        },
        clearSucceededResponse: (state, action) => {
            state.succeededResponse = null;
            state.status = requestStatus.idle;
        },
        updateReportAnswers: (state, action) => {
            state.reportAnswers = action.payload;
        },
        updateReportWarning: (state, action) => {
            state.reportWarning = action.payload;
        },
        updateGooglePlaceDetails: (state, action) => {
            state.googlePlaceDetails = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchReport.fulfilled, (state, action) => {
            state.report = action.payload;
            state.status = requestStatus.succeeded;
        })
        builder.addCase(fetchReport.pending, (state, action) => {
            state.status = requestStatus.loading;
        })
        builder.addCase(fetchReport.rejected, (state, action) => {
            state.status = requestStatus.failed;
        })
        builder.addCase(fetchGooglePlaceDetails.fulfilled, (state, action) => {
            state.report = { ...action.payload, ...state.googlePlaceDetails };
            state.googlePlaceDetails = null;
            state.status = requestStatus.succeeded;
        })
        builder.addCase(fetchGooglePlaceDetails.pending, (state, action) => {
            state.status = requestStatus.loading;
        })
        builder.addCase(fetchGooglePlaceDetails.rejected, (state, action) => {
            state.status = requestStatus.failed;
        })
        builder.addCase(submitReport.fulfilled, (state, action) => {
            state.status = requestStatus.idle;
            state.succeededResponse = action.payload;
            state.report = null;
            state.reportAnswers = null;
        })
        builder.addCase(submitReport.pending, (state, action) => {
            state.status = requestStatus.loading;
        })
        builder.addCase(submitReport.rejected, (state, action) => {
            state.status = requestStatus.failed;
        })
    }
})

export const reportAnswers = state => state.report.reportAnswers;

export const { clearState, updateReportAnswers, updateReportWarning, updateGooglePlaceDetails, clearSucceededResponse } = reportSlice.actions;

export default reportSlice.reducer;