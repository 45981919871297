import React, { useState } from 'react'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';

export default function DatePicker({ date, onClose, currentDateTime }) {
    const [selectedDate, setSelectedDate] = useState(date);

    return (
        <div className="backdrop">
            <div className="date-picker__container">
                <Calendar
                    value={selectedDate}
                    maximumDate={currentDateTime.date}
                    onChange={value => setSelectedDate(value)}
                    shouldHighlightWeekends={false}
                    calendarSelectedDayClassName={"calendarSelectedDayClassName"}
                    calendarClassName={"calendarClassName"}
                />
                <div className="picker__button__container date-picker__button__container">
                    <div
                        onClick={() =>  onClose && onClose() }
                        className="picker__button text_default_gray">Cancel</div>
                    <div
                        onClick={() => onClose && onClose(selectedDate)}
                        className="picker__button text_default_gray">Okay</div>
                </div>
            </div >
        </div>
    );
}
