export const storageHelper = {
    setEmergencyNumber: value => localStorage.setItem("emergencyNumber", value),
    getEmergencyNumber: () => JSON.parse(localStorage.getItem("emergencyNumber")),
    setStoredContactInfo: contactInfo => localStorage.setItem("storedContactInfo", JSON.stringify(contactInfo)),
    getStoredContactInfo: () => JSON.parse(localStorage.getItem("storedContactInfo") || null),
    getStoredLocationSearchQueries: () => JSON.parse(localStorage.getItem("storedLocationSearchQueries" || null)) || [],
    setStoredLocationSearchQueries: array => localStorage.setItem("storedLocationSearchQueries", JSON.stringify(array)),
    setTranslation: value => sessionStorage.setItem("translateOnUnmount", value),
    getTranslation: () => JSON.parse(sessionStorage.getItem("translateOnUnmount")),
    removeTranslation: () => sessionStorage.removeItem("translateOnUnmount"),
    setMessageCredentials: value => sessionStorage.setItem("messageCredentials", JSON.stringify(value)),
    getMessageCredentials: () => JSON.parse(sessionStorage.getItem("messageCredentials")),
    removeMessageCredentials: () => sessionStorage.removeItem("messageCredentials"),
    setMediaUploadStatus: () => sessionStorage.setItem("mediaUploadStatus", JSON.stringify(true)),
    getMediaUploadStatus: () => JSON.parse(sessionStorage.getItem("mediaUploadStatus")),
    removeMediaUploadStatus: () => sessionStorage.removeItem("mediaUploadStatus")
}
