import Cookies from 'js-cookie';
import { googleTranslateCookieName, languages } from '../consts'

export const changeLanguage = code => {
    const current = Cookies.get(googleTranslateCookieName);

    if (code !== current) {
        //using js-cookie lib not work. only using document. 
        //Browser unexpectedly create 2-3 cookies with same name but different domains
        if (process.env.REACT_APP_PRODUCTION === "true") {
            document.cookie = `${googleTranslateCookieName}=;domain=.mobile.reportit.com;path=/;max-age=-1`;
            document.cookie = `${googleTranslateCookieName}=;domain=mobile.reportit.com;path=/;max-age=-1`;
            document.cookie = `${googleTranslateCookieName}=;max-age=-1`;

            document.cookie = `${googleTranslateCookieName}=${code};domain=.reportit.com;path=/`;
        } else {
            document.cookie = `${googleTranslateCookieName}=;domain=.mobile.reportitdev.com;path=/;max-age=-1`;
            document.cookie = `${googleTranslateCookieName}=;domain=mobile.reportitdev.com;path=/;max-age=-1`;
            document.cookie = `${googleTranslateCookieName}=;max-age=-1`;

            document.cookie = `${googleTranslateCookieName}=${code};domain=.reportitdev.com;path=/`;
        }

        Cookies.set(googleTranslateCookieName, code);

        window.location.replace(window.location.href);
        // window.location.reload();
    }
}

export const getLanguage = () => {
    const current = Cookies.get(googleTranslateCookieName) || languages[0].code;
    return languages.find(x => x.code === current);
}