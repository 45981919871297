import { storageHelper } from './storageHelper';
import { routes } from '../routing/routeConsts'

export const urlHelper = {
    redirectOnScreenLeft: () => {
        const upload = storageHelper.getMediaUploadStatus();

        if (upload) {
            return false;
        }
        window.location.replace(routes.home);
    }
}