import React from 'react'

export default function IosAppLink({ className }) {
    return (
        <a
            className={className || "app-store_link"}
            href="https://itunes.apple.com/us/app/reportit.com/id1055365473?ls=1&mt=8">
            iOS app
        </a>

    )
}