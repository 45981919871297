import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { requestStatus } from '../../../api/apiConsts';
import { fetchLocationList, setSearchAreaCoords, clearState } from '../../../reducers/locationSlice';
import TabBar from '../../common/TabBar';
import blueMarker from "../../../static/images/markers/small-blue.svg"
import greenMarker from "../../../static/images/markers/small-green.svg"
import LocationItem from './LocationItem';
import MapComponent from '../../common/MapComponent';
import { changeLoadingStatus } from '../../../reducers/loadingSlice';
import { getFormatedAddress, getCurrentPositionAsync } from '../../../helpers/geoHelper';
import { routes } from '../../../routing/routeConsts';
import ChangeAreaLink from '../../common/ChangeAreaLink';
import { updateGooglePlaceDetails } from '../../../reducers/reportSlice';
import { fetchUserFavoriteLocationsListAsync } from "../../../reducers/locationSlice";

export default function LocationsPage() {
    const { categoryId, findInGooglePlaces } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const searchQuery = useSelector(state => state.location.searchQuery);
    const locationList = useSelector(state => state.location.list);
    const categoryName = useSelector(state => state.location.categoryName);
    const fetchStatus = useSelector(state => state.location.status);
    const searchAreaCoords = useSelector(state => state.location.searchAreaCoords);
    const [nonReportingLocations, setNonReportingLocations] = useState();
    const [reportingLocations, setReportingLocations] = useState();
    const [isMapView, setIsMapView] = useState(false);
    const [selectedLocationCode, setSelectedLocationCode] = useState(null);
    const [isMapViewOpened, setIsMapViewOpened] = useState(false);
    const [mapViewOffsetTop, setMapViewOffsetTop] = useState();
    const [userFavoriteLocations, setUserFavoriteLocations] = useState([]);

    const fetchUserFavoriteLocationsAsync = async () => {
        let favoriteLocations = await fetchUserFavoriteLocationsListAsync();
        setUserFavoriteLocations(favoriteLocations?.Locations);
    }

    const onLocationItemClick = (item) => {
        if (item.GooglePlaceId) {
            dispatch(updateGooglePlaceDetails({ Name: item.Name, Latitude: item.Latitude, Longitude: item.Longitude }));
        }
        navigate(`${routes.report}${item.GooglePlaceId ? `/googlePlace/${item.GooglePlaceId}` : `/${item.Code}`}`);
        // addReferrer ? { referrer: location.pathname } : null
    }

    const changeViewMode = value => {
        setIsMapView(value);
        setSelectedLocationCode(null);
        setIsMapViewOpened(false);
    }

    useEffect(() => {
        fetchUserFavoriteLocationsAsync();
        
        return () =>
            dispatch(clearState());
    }, [])

    useEffect(() => {
        if (locationList) {
            if (locationList.length === 1) {
                onLocationItemClick(locationList[0]);
            } else {
                setNonReportingLocations(locationList.filter(item => item.IsNonReportingLocation));
                setReportingLocations(locationList.filter(item => !item.IsNonReportingLocation));
            }
        }
        dispatch(changeLoadingStatus(fetchStatus === requestStatus.loading));
    }, [locationList, fetchStatus])

    useEffect(() => {
        if (!searchAreaCoords) {
            getCurrentPositionAsync(position => {
                getFormatedAddress(position.coords.latitude, position.coords.longitude,
                    address => dispatch(setSearchAreaCoords(address)));
            })
        }
        dispatch(fetchLocationList({ searchAreaCoords, categoryId, searchQuery, findInGooglePlaces }));
    }, [categoryId, searchQuery, searchAreaCoords, findInGooglePlaces])

    useEffect(() => {
        if (isMapView) {
            setMapViewOffsetTop(document.getElementById("map-view")?.offsetTop);
        }
    }, [])

    return (
        <>
            <div className="container tab-bar-predecessor">
                <div className="category-header">
                    <Link to={routes.search} className="category-header__icon" />
                    <div className="text_default">{categoryName || "All categories"}</div>
                </div>
                <div className="view-selector text_default">
                    <div
                        key="list-view"
                        className={!isMapView ? "view-selector__item_active" : "view-selector__item"}
                        onClick={() => changeViewMode(false)}>List View</div>
                    <div
                        key="map-view"
                        className={isMapView ? "view-selector__item_active" : "view-selector__item"}
                        onClick={() => changeViewMode(true)}>Map View</div>
                </div>

                {isMapView && searchAreaCoords &&
                    <MapComponent
                        markersList={locationList.map(item => ({
                            key: item.Code || item.GooglePlaceId,
                            position: { lat: item.Latitude, lng: item.Longitude },
                            icon: item.IsNonReportingLocation ? greenMarker : blueMarker,
                            onClick: () => {
                                setSelectedLocationCode(item.Code);
                                setIsMapViewOpened(true);
                            },
                        }))}
                        currentLocation={{ coords: { latitude: searchAreaCoords.latitude, longitude: searchAreaCoords.longitude } }}
                        nextSiblingTop={mapViewOffsetTop}
                    />
                }

                <div id="map-view" className={isMapView ? `${isMapViewOpened ? "map-view_opened" : "map-view"} container` : ""}>
                    {isMapView &&
                        <div
                            className="button_toggle"
                            onClick={() => setIsMapViewOpened(!isMapViewOpened)}
                        />
                    }

                    <ChangeAreaLink />
                    {selectedLocationCode && isMapView
                        ? (locationList.filter(item => item.Code === selectedLocationCode || item.GooglePlaceId === selectedLocationCode)
                            .map(item => (
                                <LocationItem
                                    key={item.Code || item.GooglePlaceId}
                                    location={item}
                                    showDistance={!isMapView}
                                    showLink={isMapView}
                                    onClick={() => onLocationItemClick(item)}
                                    isFavoriteLocation={userFavoriteLocations?.some(location => location.Code === item.Code)}
                                    onFavoriteLocationUpdate={() => fetchUserFavoriteLocationsAsync()}
                                />
                            )))
                        : (<div className="location-list__container">
                            {reportingLocations?.length > 0 && (
                                <>
                                    <div className="location-list__header">
                                        <div className="location-list__header__icon" />
                                        <div className="text_default text_bold" >report it now to these organizations</div>
                                    </div>

                                    {reportingLocations.map(item => (
                                        <LocationItem
                                            key={item.Code || item.GooglePlaceId}
                                            location={item}
                                            isDistanceShown={!isMapView}
                                            onClick={() => onLocationItemClick(item)}
                                            onFavoriteLocationUpdate={() => fetchUserFavoriteLocationsAsync()}
                                            isFavoriteLocation={userFavoriteLocations?.some(location => location.Code === item.Code)}
                                        />
                                    ))}
                                </>
                            )}

                            {nonReportingLocations?.length > 0 && (
                                <>
                                    <div className="location-list__header">
                                        <div className="location-list__header__icon_non-reporting" />
                                        <div className="text_default text_bold" >Contact details only</div>
                                    </div>
                                    {nonReportingLocations.map(item => (
                                        <LocationItem
                                            key={item.Code}
                                            location={item}
                                            isDistanceShown={!isMapView}
                                            onClick={() => onLocationItemClick(item)}
                                            onFavoriteLocationUpdate={() => fetchUserFavoriteLocationsAsync()}
                                            isFavoriteLocation={userFavoriteLocations?.some(location => location.Code === item.Code)}
                                        />
                                    ))}
                                </>
                            )}

                        </div>)
                    }

                </div>

                {!locationList?.length &&
                    <div className="section__header">No locations have been found</div>
                }
            </div>

            <TabBar />
        </>
    )
}