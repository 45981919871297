export const authHelper = {
    setUserToken: data => sessionStorage.setItem("token", JSON.stringify(data)),
    clearUserCredentials: () => sessionStorage.removeItem("token"),
    getUserToken: () => JSON.parse(sessionStorage.getItem("token")) || null,
    setUserVideoChatToken: data => sessionStorage.setItem("videoChatToken", JSON.stringify(data)),
    clearUserVideoChatCredentials: () => sessionStorage.removeItem("videoChatToken"),
    getUserVideoChatToken: () => JSON.parse(sessionStorage.getItem("videoChatToken")) || null,
    getDeviceId: () => localStorage.getItem("deviceId") || null,
    setDeviceId: data => localStorage.setItem("deviceId", data),
    isTermsAccepted: () => !!localStorage.getItem("deviceId"),
    setUserHumanId: value => localStorage.setItem("humanId", value),
    clearUserHumanId: () => localStorage.removeItem("humanId"),
    getUserHumanId: () => localStorage.getItem("humanId") || null
}