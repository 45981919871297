import React from 'react'

export default function NonReportingLocation({ location }) {
    return (
        <div className="non-reporting-location">
            <div className="non-reporting-location__text">
                <div className="text_default">
                    <span className="text_bold">{location.Name}</span> is not currently a member of <span className="text_bold">Report It®</span>. Contact information is provided as a public service.
                    </div>
                <div className="text_default">
                    <span className="text_bold">Report It®</span> assumes no responsibility for your anonymity or any information you may provide.
                    </div>
            </div>
            <div className="non-reporting-location__divider" />

            {location.WebContactInfo.LocationPhone1 &&
                <div className="contact-details">
                    <div className="contact-details__icon_non-emergency" />
                    <a className="contact-details__text" href={`tel:${location.WebContactInfo.LocationPhone1}`}>
                        <div className="text_primary">Call (Non-Emergency)</div>
                        <div className="text_default">{location.WebContactInfo.LocationPhone1}</div>
                    </a>
                </div>
            }
            {location.WebContactInfo.LocationPhone2 &&
                <div className="contact-details">
                    <div className="contact-details__icon_tip-line" />
                    <a className="contact-details__text" href={`tel:${location.WebContactInfo.LocationPhone2}`}>
                        <div className="text_primary">Call Tip Line</div>
                        <div className="text_default">{location.WebContactInfo.LocationPhone2}</div>
                    </a>
                </div>
            }
            {location.WebContactInfo.LocationEmail &&
                <div className="contact-details">
                    <div className="contact-details__icon_email" />
                    <a className="contact-details__text" href={`mailto:${location.WebContactInfo.LocationEmail}`}>
                        <div className="text_primary">Send Email</div>
                        <div className="text_default">{location.WebContactInfo.LocationEmail}</div>
                    </a>
                </div>
            }
            {location.WebContactInfo.Website &&
                <div className="contact-details">
                    <div className="contact-details__icon_website" />
                    <a className="contact-details__text text_primary" href={location.WebContactInfo.Website}>Visit website</a>
                </div>
            }
            {location.WebContactInfo.Website2 &&
                <div className="contact-details">
                    <div className="contact-details__icon_tip" />
                    <a className="contact-details__text text_primary" href={location.WebContactInfo.Website2}>Submit a Tip</a>
                </div>
            }
            {location.WebContactInfo.GooglePlaceUrl &&
                <div className="contact-details">
                    <div className="contact-details__icon_website" />
                    <div className="contact-details__text">
                        <a className="text_primary" href={location.WebContactInfo.GooglePlaceUrl}>Location Details</a>
                    </div>
                </div>
            }

            <div className="non-reporting-location__divider" />
            <div className="contact-details__social-networks">
                {location.WebContactInfo.LinkedIn &&
                    <a className="contact-details__icon_linkedin" href={location.WebContactInfo.LinkedIn} />
                }
                {location.WebContactInfo.Twitter &&
                    <a className="contact-details__icon_twitter" href={location.WebContactInfo.Twitter} />
                }
                {location.WebContactInfo.Facebook &&
                    <a className="contact-details__icon_facebook" href={location.WebContactInfo.Facebook} />
                }
            </div>
            <div className="text_secondary" >
                <a href="https://reportit.com/support/" className="text_secondary link" target="_blank" rel='noreferrer'>Contact us </a> to claim this page
                    </div>
        </div>
    )
}