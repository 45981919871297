import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { authHelper } from "../../../api/authHelper";
import { routes } from "../../../routing/routeConsts";
import { fetchUserFavoriteLocationsListAsync, fetchUserNearbyLocationsListAsync, checkIsPanicOrShortFormLocation } from "../../../reducers/locationSlice";
import { maxLocationsOnHomeScreen } from "../../../consts";
import InteractiveInput from "../../common/InteractiveInput";
import TabBar from "../../common/TabBar";
import Header from "../../common/Header";
import IosAppLink from "../../common/IosAppLink";
import AndroidAppLink from "../../common/AndroidAppLink";
import Image from "../../common/Image";
import favoritesIcon from "../../../static/images/favorites-icon.svg"
import locationIcon from "../../../static/images/nearby-location-icon.svg"
import reportItIcon from "../../../static/images/report-it-icon.svg";

export default function HomePage() {
    const navigate = useNavigate();
    const [reportItCode, setReportItCode] = useState(Array.from({ length: 5 }, () => ""));
    const [userFavoriteLocations, setUserFavoriteLocations] = useState([]);
    const [nearbyLocations, setNearbyLocations] = useState([]);
    const [isLocationLimitExceeded, setIsLocationLimitExceeded] = useState(false);
    const userHumanId = authHelper.getUserHumanId();
    const query = new URLSearchParams(useLocation().search);

    const onReportCodeEnter = () => {
        navigate(`${routes.report}/${reportItCode.join("").trim()}`);
    }

    const fetchUserFavoriteLocationsAsync = async () => {
        let favoriteLocations = await fetchUserFavoriteLocationsListAsync();
        if (favoriteLocations?.Locations?.length > maxLocationsOnHomeScreen) {
            favoriteLocations.Locations = favoriteLocations?.Locations?.slice(0, maxLocationsOnHomeScreen);
            setIsLocationLimitExceeded(true);
        }
        setUserFavoriteLocations(favoriteLocations?.Locations);
    }

    const fetchNearbyLocationsAsync = async () => {
        let nearbyLocations = await fetchUserNearbyLocationsListAsync();
        if (nearbyLocations?.Locations?.length > maxLocationsOnHomeScreen) {
            nearbyLocations.Locations = nearbyLocations?.Locations?.slice(0, maxLocationsOnHomeScreen);
        }
        setNearbyLocations(nearbyLocations?.Locations);
    }

    useEffect(() => {
        if (userHumanId) {
            fetchUserFavoriteLocationsAsync();
        }
    }, [userHumanId])

    useEffect(() => {
        const locationCode = query?.get("code");
        const rapidSOSUserId = query?.get("rapidSOSUserId");

        if (locationCode != null) {
            async function checkIsPanicAlarmEnabledAsync() {
                return await checkIsPanicOrShortFormLocation(locationCode);
            }
            
            checkIsPanicAlarmEnabledAsync().then((isPanicAlarmEnabled) => {
                if (isPanicAlarmEnabled) {
                    navigate(`${routes.settings}?isRedirectedUsingDeepLink=${isPanicAlarmEnabled}&deepLinkLocationCode=${locationCode}&rapidSOSUserId=${rapidSOSUserId}`);
                } else {
                    navigate(`${routes.report}/${locationCode}`);
                }
            });
        }

        const invitationToken = query?.get("invitationToken");
        if (invitationToken != null) {
            navigate(`${routes.goLiveChat}?invitationToken=${invitationToken}`);
        }

        fetchNearbyLocationsAsync();
    }, [])

    return (
        <>
            <Header useLogo={true} showLanguage={true} />

            <div className="container tab-bar-predecessor">
                <div className="text_default title_first">Choose an option below to create a new report</div>
                <div className="text_default text_bold title_second">Enter a reportit® code</div>
                <InteractiveInput
                    inputValue={reportItCode}
                    onInputValueChange={value => setReportItCode(value)}
                    uniquePrefix="code"
                    onSubmit={() => onReportCodeEnter()}
                />

                {reportItCode?.filter(x => x !== "").length > 0 &&
                    <div className="button report-it-now__button" onClick={() => onReportCodeEnter()}>
                        <div className="report-it-now__button__icon" />
                        <div className="text_default">Report It Now</div>
                    </div>
                }

                <div className="text_primary title_third">or</div>

                {!(nearbyLocations?.length > 0) && (<Link to={routes.search} className="button_bordered">
                    <div className="search-nearby__icon" />
                    <div>Search Nearby Organizations</div>
                </Link>)}
                {nearbyLocations?.length > 0 && (<div className="home-locations__container">
                    <div className="home-locations__header">
                        <div className="home-locations__title">
                            <Image className="home-locations__title__icon" src={locationIcon} />
                            <p className="home-locations__title__text">Nearby Locations</p>
                        </div>
                    </div>
                    <div className="home-locations__items">
                        {nearbyLocations?.map(location => (
                                <div className="home-locations__item" onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        navigate(`${routes.report}/${location.Code}`);
                                    }}
                                >
                                    <Image className="home-locations__item__logo" src={location.LocationLogoPath || reportItIcon} />
                                    <div className="home-locations__item__text">
                                        <div className="home-locations__item__title text_default">
                                            {location.Name}
                                        </div>
                                        <div className="home-locations__item__subtitle text_secondary">
                                            {location.Address}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <Link to={routes.search} className="home-locations__search-nearby button_bordered">
                        <div className="search-nearby__icon" />
                        <div className="search-nearby__text" >Search All Nearby Locations</div>
                    </Link>
                </div>)}

                {userHumanId && userFavoriteLocations?.length > 0 && (<div className="home-locations__container">
                    <div className="home-locations__header">
                        <div className="home-locations__title">
                            <Image className="home-locations__title__icon" src={favoritesIcon} />
                            <p className="home-locations__title__text">My Saved Locations</p>
                        </div>
                        <div className="home-locations__title__more-button" 
                             onClick={() => navigate(routes.favoriteLocations)}>More locations</div>
                    </div>
                    <div className="home-locations__items">
                        {userFavoriteLocations?.map(location => (
                                <div className="home-locations__item" onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        navigate(`${routes.report}/${location.Code}`);
                                    }}
                                >
                                    <Image className="home-locations__item__logo" src={location.LocationLogoPath || reportItIcon} />
                                    <div className="home-locations__item__text">
                                        <div className="home-locations__item__title text_default">{location.Name}</div>
                                        <div className="home-locations__item__subtitle text_secondary">{location.Address}</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>)}

                <AndroidAppLink />
                <IosAppLink />
            </div>

            <TabBar />
        </>
    )
}