import React from 'react'
import { useSelector } from 'react-redux';
import LoaderGif from "../../../static/images/loader.gif"
import ProgressBar from '../ProgressBar';

export default function Loader() {
    const isLoading = useSelector(state => state.loading.isLoading);
    const isSending = useSelector(state => state.loading.isSending);
    const totalMediaSending = useSelector(state => state.loading.totalMediaSending);
    const mediaSended = useSelector(state => state.loading.mediaSended);

    return (
        <div className={isLoading ? "loader" : "no-loader"} >
            <img src={LoaderGif} alt="loader" />
            {isSending && <div className='loader__sending-warning'>Do not close this page until your report has been successfully submitted</div>}
            {isSending && totalMediaSending > 0 && 
                <>
                    <div className='loader__progress-bar'>
                        <ProgressBar completed={(mediaSended / totalMediaSending) * 100}></ProgressBar>
                    </div>
                    <div className='loader__sending-warning'>Sending attached media files: {mediaSended} / {totalMediaSending}</div>
                </>
            }
        </div>
    )
}