import React, { useEffect } from 'react';
import Header from '../../common/Header';
import TermsOfUseText from '../../common/TermsOfUseText';
import { createUserAsync, updateTokenAsync } from '../../../api/requestService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeLoadingStatus } from '../../../reducers/loadingSlice';
import { routes, goToPreviousLocation } from '../../../routing/routeConsts';
import { authHelper } from '../../../api/authHelper';
import { getCurrentPositionAsync } from '../../../helpers/geoHelper';
import { languages } from '../../../consts';
import { changeLanguage, getLanguage } from '../../../helpers/languageHelper';

export default function AcceptTermsPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loading.isLoading);

    const askingLocationCallBackAsync = async (position) => {
        await updateTokenAsync(position);

        dispatch(changeLoadingStatus(false));
        navigate(location.state.location.pathname || routes.home);
    }

    const onTermsAcceptAsync = async () => {
        dispatch(changeLoadingStatus(true));

        //requirement: ask user location access beforehand to not interrupt further requests
        await getCurrentPositionAsync(async (position) => askingLocationCallBackAsync(position),
            async () => askingLocationCallBackAsync());
    }

    useEffect(() => {
        if (authHelper.getDeviceId()) {
            goToPreviousLocation(navigate, location);
        }

        const browserLanguageCode = navigator.language.split('-')[0];
        if (browserLanguageCode !== languages[0].code.split('/')?.pop()) {
            const browserLanguage = languages.find(item => item.code.split('/')?.pop() === browserLanguageCode);
            if (getLanguage().code !== browserLanguage.code) {
                changeLanguage(getLanguage().code);
            }
        }
    }, [])

    return !isLoading && (
        <div className="tab-bar-predecessor accept__container">
            <Header useLogo={true} showBackArrow={true} isLogoCentered={true} />
            <TermsOfUseText />

            <div className="text_primary accept-terms-button" onClick={() => onTermsAcceptAsync()}>Accept and Continue</div>
        </div>
    )
}