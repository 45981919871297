import React from 'react'

export default function AndroidAppLink({ className }) {
    return (
        <a
            className={className || "google-play_link"}
            href="https://market.android.com/details?id=com.reportit.reportit">
            Android app
        </a>
    )
}