import React from 'react'
import TabBar from '../../common/TabBar'
import Header from '../../common/Header';
import TermsOfUseText from '../../common/TermsOfUseText';

export default function TermsOfUsePage() {

    return (
        <div className="tab-bar-predecessor">
            <Header title="Terms of Use" showBackArrow={true}/>
            <TermsOfUseText/>
            <TabBar />
        </div>
    )
}