import React from "react"

export default function Image({ className, errorClassName, defaultSrc, src, altText, ...rest }) {
    return (
        <img
            className={className}
            src={src ?? defaultSrc}
            onError={e => defaultSrc ? e.target.src = defaultSrc : e.target.className = errorClassName}
            alt={altText || ""}
            {...rest}
        />
    )
}