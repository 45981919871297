import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { requestStatus } from '../../../api/apiConsts';
import { fetchCategoryList, clearState } from '../../../reducers/categorySlice';
import { setLocationSearchQuery, setSearchAreaCoords } from '../../../reducers/locationSlice';
import { changeLoadingStatus } from '../../../reducers/loadingSlice';
import TabBar from '../../common/TabBar';
import Image from '../../common/Image';
import DefaultCategoryIcon from "../../../static/images/i-sign-dark-blue.svg"
import ServiceCategoryIcon from "../../../static/images/more-dots.svg"
import CommunityResoursesIcon from "../../../static/images/community-resourses.svg"
import AllCategoriesIcon from "../../../static/images/all-categories.svg"
import { routes } from '../../../routing/routeConsts';
import { googlePlacesCategoryName, maxCategoriesAmountWithHeader, maxCategoriesAmountWithoutHeader, maxCategoriesAmountToShowHeader } from '../../../consts';
import { getCurrentPositionAsync, getFormatedAddress } from '../../../helpers/geoHelper';
import ChangeAreaLink from '../../common/ChangeAreaLink';

export default function CategoriesPage() {
    const categoryList = useSelector(state => state.category.list);
    const fetchStatus = useSelector(state => state.category.status);
    const enableGooglePlacesCategory = useSelector(state => state.category.enableGooglePlacesCategory);
    const isLoading = useSelector(state => state.loading.isLoading);
    const searchAreaCoords = useSelector(state => state.location.searchAreaCoords);
    const [showExtraCategories, setShowExtraCategories] = useState(false);
    const searchQuery = useSelector(state => state.location.searchQuery);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        return () =>
            dispatch(clearState())
    }, [])

    useEffect(() => {
        const isFetching = fetchStatus === requestStatus.loading;
        if (isLoading !== isFetching) {
            dispatch(changeLoadingStatus(isFetching));
        }
        if (fetchStatus === requestStatus.idle) {
            if (!searchAreaCoords) {
                getCurrentPositionAsync(position => {
                    getFormatedAddress(position.coords.latitude, position.coords.longitude,
                        address => dispatch(setSearchAreaCoords(address)));
                })
            } else {
                dispatch(fetchCategoryList(searchAreaCoords));
            }
        }
    }, [searchAreaCoords, fetchStatus])

    useEffect(() => {
        if (fetchStatus === requestStatus.idle) {
            if (!searchAreaCoords) {
                getCurrentPositionAsync(
                    position => dispatch(fetchCategoryList({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    })));
            }
        }
    }, [fetchStatus])

    return (
        <>
            {categoryList?.length < maxCategoriesAmountToShowHeader &&
                <div className="category__header" />}

            <div className="container">
                <div className="input__container">
                    <div className="input__icon_search"></div>
                    <input
                        placeholder="Search by name or concern"
                        className="input text_default"
                        value={searchQuery}
                        onInput={e => dispatch(setLocationSearchQuery(e.target.value))}
                        onKeyDown={e => {
                            if (e.which !== 13) {
                                return false;
                            }
                            navigate(routes.locations);
                        }} />
                </div>
                <ChangeAreaLink />

                {categoryList?.length > 0
                    ? <>
                        <div className="section__header">Select a Category</div>

                        <div className="tab-bar-predecessor">
                            <Link to={routes.locations} className="category__item text_default" key="All">
                                <Image className="category__icon" src={AllCategoriesIcon} />
                                <div className="text_default">All categories</div>
                            </Link>

                            {enableGooglePlacesCategory &&
                                !categoryList.some(x => x.Name.toLowerCase() === googlePlacesCategoryName.toLowerCase()) &&
                                (<Link
                                    to={`${routes.locations}/findInGooglePlaces`}
                                    className="category__item text_default"
                                    key="CommunityResources">
                                    <Image className="category__icon" src={CommunityResoursesIcon} />
                                    <div className="text_default">{googlePlacesCategoryName}</div>
                                </Link>)}

                            {categoryList.map((item, index) => (
                                <Link
                                    to={`${routes.category}/${item.Id}`}
                                    className={index + 1 > (categoryList.length < maxCategoriesAmountToShowHeader
                                        ? maxCategoriesAmountWithHeader
                                        : maxCategoriesAmountWithoutHeader)
                                        && !showExtraCategories
                                        ? "category_hidden"
                                        : "category__item"}
                                    key={item.Id}>
                                    <Image className="category__icon" src={item.IconPath || DefaultCategoryIcon} defaultSrc={DefaultCategoryIcon} />
                                    <div className="text_default">{item.Name}</div>
                                </Link>
                            ))}

                            {((categoryList.length < maxCategoriesAmountToShowHeader && categoryList.length > maxCategoriesAmountWithHeader) ||
                                (categoryList.length > maxCategoriesAmountWithoutHeader)) &&
                                (<div className="category__item" key="More"
                                    onClick={() => setShowExtraCategories(!showExtraCategories)}>
                                    <Image className="category__icon" src={ServiceCategoryIcon} />
                                    <div className="text_default">{showExtraCategories ? "Hide" : "More"}</div>
                                </div>)}
                        </div>
                    </>
                    : <div className="section__header">No categories have been found</div>
                }
            </div>

            <TabBar />
        </>
    )
}