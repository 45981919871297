import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { reportAnswers as reportAnswersSelector, updateReportAnswers } from '../../../../../reducers/reportSlice';
import { isMobile } from 'react-device-detect';
import Microphone from '../../../../common/Microphone';

export default function TextAreaControl({ control, shouldStateBeUpdated, isShortFormSelected }) {
    const reportAnswers = useSelector(reportAnswersSelector);
    const [comment, setComment] = useState(reportAnswers?.comment || "");
    const dispatch = useDispatch();

    const toggleStepsNavigation = toShow => {
        if (!isMobile) {
            return;
        }

        let stepsbar = document.getElementById("report-navigation");
        if (!stepsbar) {
            return
        }
        stepsbar.style.display = toShow ? "flex" : "none";
    }

    useEffect(() => {
        if (shouldStateBeUpdated || isShortFormSelected) {
            dispatch(updateReportAnswers({
                ...reportAnswers,
                comment
            }));
        }
    }, [shouldStateBeUpdated, comment])

    return (
        <div className={`text-area-control ${isShortFormSelected ? "text-area-control__short-form" : ""}`}>
            {!isShortFormSelected && (<div className={isShortFormSelected ? "section__header short-form" : "section__header"}>ADD COMMENTS, PHOTOS OR VIDEO (OPTIONAL)</div>)}
            <textarea
                key={control.Id}
                id="text-area"
                className="input__container input text_default textarea"
                value={comment}
                onInput={e => setComment(e.target.value)}
                onKeyDown={e => e.which === 13 && e.target.value && dispatch(updateReportAnswers({ ...reportAnswers, comment: e.target.value }))}
                placeholder={control.Placeholder || "Type comment here..."}
                onFocus={e => toggleStepsNavigation(false)}
                onBlur={e => toggleStepsNavigation(true)}
            />
            <Microphone onChange={value => setComment(value)} />
        </div>
    )
}