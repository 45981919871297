import { setErrorWithoutRedirect } from '../reducers/loadingSlice';
import store from '../store'

export const getFormatedAddress = (latitude, longitude, callBack, returnFullResponse = false) => {
    if (!window.google.maps) {
        return null;
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({
        location: {
            lat: latitude,
            lng: longitude
        }
    }, (results, status) => {
        if (status === "OK" && results[0]) {
            callBack(returnFullResponse
                ? results
                : {
                    latitude: results[0].geometry.location.lat(),
                    longitude: results[0].geometry.location.lng(),
                    name: results[0].formatted_address
                });
        }
    });
}

export const getCurrentPositionAsync = async (callBack, errorCallBack) => {
    if (!navigator?.geolocation) {
        store.dispatch(setErrorWithoutRedirect("Your browser doesn't support Geolocation API"))
    }
    navigator.geolocation.getCurrentPosition(position => {
        callBack(position);
    }, error => {
        if (errorCallBack) {
            errorCallBack(console.error())
        } else {
            console.error(JSON.stringify(error));
            store.dispatch(setErrorWithoutRedirect(error.code === 1
                ? "Please allow location access to see appropriate results"
                : "Error occured accessing location. Please try again later"));
        }
    },
    { enableHighAccuracy: true })
}