import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { sendRequestAsync } from "../api/requestService"
import { 
    httpMethod, 
    getLocationListUrl, 
    getFavoriteLocationsListUrl, 
    getNearbyLocationsListUrl,
    addLocationToFavoritesUrl, 
    removeLocationFromFavoritesUrl, 
    getReportUrl,
    requestStatus } from "../api/apiConsts"
import { getDeviceSettingsAsync } from "../api/requestService";
import { getCurrentPositionAsync } from "../helpers/geoHelper";
import { authHelper } from "../api/authHelper";
import { formTypes } from '../consts';

export const checkIsPanicOrShortFormLocation = async (locationCode) => {
    let url = `${getReportUrl}?code=${locationCode}`;

    let result = await sendRequestAsync(httpMethod.get, url);

    let location = result?.data;

    let isPanicAlarmEnabled = location?.Form?.IsPanicAlarmEnabled;
    let isShortForm = location?.Form?.FormType === formTypes.Short;

    return isPanicAlarmEnabled || isShortForm;
}

export const addLocationToFavoritesAsync = async (locationCodes) => {
    const userHumanId = authHelper.getUserHumanId();

    await sendRequestAsync(httpMethod.post, addLocationToFavoritesUrl, {
        locationCodes: locationCodes,
        userHumanId: userHumanId
    });
}

export const removeLocationFromFavoritesAsync = async (locationCodes) => {
    const userHumanId = authHelper.getUserHumanId();

    await sendRequestAsync(httpMethod.post, removeLocationFromFavoritesUrl, {
        locationCodes: locationCodes,
        userHumanId: userHumanId
    });
}

export const fetchUserFavoriteLocationsListAsync = async () => {
    const userHumanId = authHelper.getUserHumanId();
    
    const currentUserPosition = await new Promise((resolve, reject) => 
        getCurrentPositionAsync(resolve, reject)
    );

    const deviceSettings = await getDeviceSettingsAsync();
    const userLatitude = deviceSettings?.AllowLocationTracking ? currentUserPosition?.coords?.latitude : null;
    const userLongitude = deviceSettings?.AllowLocationTracking ? currentUserPosition?.coords?.longitude : null;

    let url = `${getFavoriteLocationsListUrl}?latitude=${userLatitude}
                                    &longitude=${userLongitude}
                                    &userLatitude=${userLatitude}
                                    &userLongitude=${userLongitude}
                                    &userHumanId=${userHumanId}`;

    const response = await sendRequestAsync(httpMethod.get, url);
    return response?.data;
}

export const fetchUserNearbyLocationsListAsync = async () => {
    const userHumanId = authHelper.getUserHumanId();
    
    const currentUserPosition = await new Promise((resolve, reject) => 
        getCurrentPositionAsync(resolve, reject)
    );

    const deviceSettings = await getDeviceSettingsAsync();
    const userLatitude = deviceSettings?.AllowLocationTracking ? currentUserPosition?.coords?.latitude : null;
    const userLongitude = deviceSettings?.AllowLocationTracking ? currentUserPosition?.coords?.longitude : null;

    let url = `${getNearbyLocationsListUrl}?userLatitude=${userLatitude}
                                    &userLongitude=${userLongitude}
                                    &latitude=${userLatitude}
                                    &longitude=${userLongitude}` +
                                    `${(userHumanId ? `&userHumanId=${userHumanId}` : '')}`;

    const response = await sendRequestAsync(httpMethod.get, url);
    return response?.data;
}

export const fetchLocationList = createAsyncThunk("locations/fetchLocationList",
    async ({ searchAreaCoords, categoryId, searchQuery, findInGooglePlaces }) => {
        if (!searchAreaCoords) {
            return;
        }

        let url = `${getLocationListUrl}?Latitude=${searchAreaCoords.latitude}
                                          &Longitude=${searchAreaCoords.longitude}
                                          &UserLatitude=${searchAreaCoords.latitude}
                                          &UserLongitude=${searchAreaCoords.longitude}
                                          ${categoryId ? '&categoryId=' + categoryId : ''}
                                          ${searchQuery ? '&searchQuery=' + searchQuery : ''}
                                          ${findInGooglePlaces ? '&findInGooglePlaces=' + true : ''}`;

        const response = await sendRequestAsync(httpMethod.get, url.replace(/[ ]+|\n+/g, ""));
        return response.data;
    },
    {
        condition: ({ searchAreaCoords }) => {
            return !!searchAreaCoords;
        }
    });

export const locationSlice = createSlice({
    name: "locations",
    initialState: {
        list: null,
        searchAreaCoords: null,
        status: requestStatus.idle,
        searchQuery: "",
        categoryName: null
    },
    reducers: {
        setSearchAreaCoords: (state, action) => {
            state.searchAreaCoords = action.payload;
        },
        setLocationSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        clearState: (state, action) => {
            state.list = null;
            state.status = requestStatus.idle;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchLocationList.fulfilled, (state, action) => {
            state.list = action.payload.respObj.Locations;
            state.categoryName = action.payload.categoryName;
            state.status = requestStatus.succeeded;
        })
        builder.addCase(fetchLocationList.pending, (state, action) => {
            state.status = requestStatus.loading
        })
        builder.addCase(fetchLocationList.rejected, (state, action) => {
            state.status = requestStatus.failed;
            state.list = [];
        })
    }
})

export const { setSearchAreaCoords, setLocationSearchQuery, clearState } = locationSlice.actions;

export default locationSlice.reducer;