import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchUserReportsByHumanId, setCredentials, changeReportStatus } from "../../../reducers/messageSlice";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routing/routeConsts";
import Image from "../Image";
import archiveReportIcon from "../../../static/images/archive-report-icon.svg";
import unarchiveReportIcon from "../../../static/images/unarchive-report-icon.svg";
import reportItIcon from "../../../static/images/report-it-icon.svg";
import { answerSource } from "../../../consts";

let swipeStartPoint = 0, mouseSwipeStartPoint = 0;
let item = null;

export default function ChatListItem({ report, isArchivedReportsSelected, humanId }) {
    const dispatch = useDispatch();
    const [isReportMoved, setIsReportMoved] = useState(false);
    const navigate = useNavigate();

    const archiveReportAsync = async (reportId) => {
        await dispatch(changeReportStatus(reportId));
        await dispatch(fetchUserReportsByHumanId(humanId));
    }

    const handleReportSwipeStart = event => {
        let touch = event.touches[0];
        swipeStartPoint = touch.pageX;
    }

    const handleReportSwipe = (event, reportId) => {
        let touch = event.touches[0];
        let px = touch.pageX;
        let point = Math.floor(item.clientWidth / 5);

        event.stopPropagation();

        item = document.getElementById(`report__item_${reportId}`);

        if ((px - swipeStartPoint) * -1 > point) {
            item.style.left ='-25%';
        } else {
            item.style.left ='0px';
        }
    }

    const handleReportMouseSwipeStart = (event, reportId) => {
        setIsReportMoved(false);
        
        item = document.getElementById(`report__item_${reportId}`);
        mouseSwipeStartPoint = event.clientX;

        item.addEventListener('mousemove', handleReportMouseSwipe);
        item.addEventListener('mouseup', handleReportMouseSwipeEnd);   
    }

    const handleReportMouseSwipe = event => {
        let point = Math.floor(item.clientWidth / 5);

        setIsReportMoved(true);
        event.preventDefault();

        if ((event.clientX - mouseSwipeStartPoint) *-1 > point) {
            item.style.left ='-25%';
        } else {
            item.style.left ='0px';
        }
    }

    const handleReportMouseSwipeEnd = () => {
        item.removeEventListener('mousemove', handleReportMouseSwipe);
        item.removeEventListener('mouseup', handleReportMouseSwipeEnd);
    }

    const splitFormattedDateTimeString = formattedDateTimeString => {
        const dateTimeArray = formattedDateTimeString?.split(" at ");
        if (dateTimeArray) {
            if (dateTimeArray[0] === "today") {
                return dateTimeArray[1];
            }
            return dateTimeArray[0].split(",")[0];
        }
        return "";
    }

    const enterChat = reportId => {
        dispatch(setCredentials({ number: `${reportId}`, pin: "" }));
        navigate(routes.chat);
    }

    return (
        <div className="report__item-container" key={report?.ReportId}>
            <div className="report__archive-button" onClick={async () => await archiveReportAsync(report?.ReportId)}>
                {isArchivedReportsSelected
                    ? <Image className="report__unarchive-button__logo" src={unarchiveReportIcon} />
                    : <Image className="report__archive-button__logo" src={archiveReportIcon} />
                }
            </div>
            <div className="report__item" 
                id={`report__item_${report?.ReportId}`} 
                key={report?.ReportId} 
                onMouseDown={event => handleReportMouseSwipeStart(event, report?.ReportId)} 
                onTouchMove={event => handleReportSwipe(event, report?.ReportId)} 
                onTouchStart={event => handleReportSwipeStart(event)} 
                onClick={event => isReportMoved ? event.preventDefault() : enterChat(report?.ReportId)}>
                <div className="report__location-logo">
                    <Image className="report__location-logo__icon" src={report?.LocationLogo || reportItIcon} />
                </div>
                <div className="report__wrapper__title">
                    <div className="text_default report__title">[{report?.ReportId}] {report?.LocationName}</div>
                    <div className="text_default report__last-answer">{report?.Answers[0]?.AnswerText}</div>
                </div>
                {report?.Answers?.filter(a => !a.IsAnswerRead && a.AnswerSource === answerSource.backend)?.length > 0 
                    ? <div className="report__wrapper__unreaded-messages-count">{report.Answers.filter(a => !a.IsAnswerRead && a.AnswerSource === answerSource.backend).length}</div>
                    : <div className="text_default report__time">
                        {
                            splitFormattedDateTimeString(report?.Answers[0]?.AnsweredAt) 
                            || splitFormattedDateTimeString(report?.ReportSubmittedAt)
                        }
                    </div>
                }                                  
            </div>
        </div>
    )
}