import React, { useEffect, useState } from 'react'
import { getRecongnition } from '../../../helpers/voiceInputHelper';

export default function Microphone({ onChange }) {
    const [recognition, setRecognition] = useState(null);
    const [isStarted, setIsStarted] = useState(false);

    useEffect(() => {
        setRecognition(getRecongnition(onChange, "text-area"));
    }, [])

    useEffect(() => {
        return () => {
            if (isStarted) {
                recognition?.stop()
            }
        };
    }, [isStarted])

    return recognition
        ? (
            <div className={isStarted ? 'microphone__recording' : 'microphone'}
                onClick={() => {
                    setIsStarted(!isStarted);

                    isStarted
                        ? recognition.stop()
                        : recognition.start();
                }}
            />
        )
        : null
}