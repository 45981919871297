import React from "react"
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"
import { routes } from "../../../routing/routeConsts"

export default function ChangeAreaLink() {
    const searchAreaCoords = useSelector(state => state.location.searchAreaCoords);

    return searchAreaCoords
        ? (<div className="change-area__container">
            <div className="change-area__icon" />
            <div>
                <div className="text_secondary" >{searchAreaCoords.name}</div>
                <Link to={routes.changeArea} className="text_secondary link">Change area</Link>
            </div>
        </div>)
        : null
}